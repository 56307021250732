import Stomp from 'stompjs'
import axios from 'axios';
import store from './index.js';//store import //coconut補充
import Vue from 'vue';
import Vuex from 'vuex';

// import '../assets/css/telerik/default-main-dark.css'

var monitorin_client = null;
var user_client = null;
var OnClient = null;
var realtime_subscribe = null
var klineday_subscribe = null
var klinemin_subscribe = null
var tick_subscribe = null
var subscribe_productname = null
var monitorin_headers = null
var user_headers = null
var user_now_status = null
//對外產品 訂閱顯示 //cocount補充
export function Monitorin_MQ_Initialization_Connect() {
    monitorin_client = Stomp.client(Vue.prototype.$httpPath_ws);
    monitorin_client.debug = null;
    monitorin_headers = {
        login:"test",
        passcode:"000000",
    };
    console.log("訂閱連線初始化");
    monitorin_client.connect(monitorin_headers, OnConnected, OnFailed);
};
export function User_MQ_Initialization_Connect(User_Name, User_Password) {
    user_client = Stomp.client(Vue.prototype.$httpPath_ws);
    user_client.debug = null;
    user_client.onerror = function(debugmessage)
    {
        console.log(debugmessage)
    };
    
    user_headers = {
        login:User_Name,
        passcode:User_Password,
    };
    user_client.connect(user_headers, OnConnected, OnFailed);
    console.log("使用者連線初始化");
    //client.connect(headers, OnConnected, OnFailed);
};
//對外連線訂閱 //coconut補充
export function Monitorin_MQ_Subscribe(productname) {
    var topic = "";
    if(subscribe_productname != productname){
        //console.log(productname);
        subscribe_productname = productname
        if(klineday_subscribe != null) {
            klineday_subscribe.unsubscribe();
            klineday_subscribe = null
        }
        
        if(klinemin_subscribe != null) {
            klinemin_subscribe.unsubscribe();
            klinemin_subscribe = null
        }
        
        if(tick_subscribe != null) {
            tick_subscribe.unsubscribe();
            tick_subscribe = null
        }
        //console.log(subscribe_productname);
        topic = "/exchange/KLineDay_Exchange/KLineDay." + subscribe_productname;
        klineday_subscribe = monitorin_client.subscribe(topic, ResponseCallback, { id: 'klineday' },OnFailed)
        //console.log(klineday_subscribe)
        topic = "/exchange/KLineMin_Exchange/KLineMin." + subscribe_productname;
        klinemin_subscribe = monitorin_client.subscribe(topic, ResponseCallback, { id: 'klinemin' },OnFailed)
        //console.log(klinemin_subscribe)
        //topic = "/exchange/Tick_Exchange/Tick." + subscribe_productname;
        //tick_subscribe = client.subscribe(topic, ResponseCallback, { id: 'tick' },OnFailed)
    }
};
//內部連線訂閱 //coconut補充
function OnConnected(frame) {
    /*
    Topic格式 : /exchange/交換機名稱/路由名稱.關鍵字名稱
    訂閱柱列名稱不重複 避免排隊獲取訊息
    訂閱需要 包含 headers id 避免重複及明確知道取消訂閱的id
    */
    if(store.getters.loggedInUser !== ""){
    /*宣告使用者登入JSON*/
    user_now_status = {
        User_Interface : "Management",
        User_Name : store.getters.loggedInUser,
        Action_Status : "Login",
    };
    /*丟出使用者登入JSON*/
    var send_login_topic = "/exchange/"+ store.getters.loggedInUser + "/Send.Action"
    console.log(send_login_topic)
    User_Send(send_login_topic, JSON.stringify(user_now_status))
    /*用訂閱頻道方式使使用者 需要獲取的其他訂閱Topic*/
    var get_topic_list_topic = "/exchange/" + store.getters.loggedInUser + "/Subscribe.Topic";
    console.log(get_topic_list_topic)
    user_client.subscribe(get_topic_list_topic, ResponseTopicCallback, { id: "Login" }, OnFailed)
    }
    else{
        console.log("login  time faill")
    }
    
};
//錯誤回報 //coconut補充
function OnFailed(e) {
    console.log("Failed: " + e);
};
//接收Topic內容 並訂閱
async function ResponseTopicCallback(frame){
    try{
    /*訂閱需要之Topic*/
    var topic_list = JSON.parse(frame.body);
    console.log(topic_list)
    topic_list.forEach(function(element, index, array){
        var topic = "/exchange/" + element.Exchange_Name + "/" + element.Route_Name + "." + element.Key_Name;
        console.log(topic)
        var ids  = element.Route_Name + "." + element.Key_Name
        user_client.subscribe(topic, ResponseCallback,{ id: ids  }, OnFailed)
    });
    /*再向主機端要求資料*/
    user_now_status = {
        User_Interface : "Management",
        User_Name : store.getters.loggedInUser,
        Action_Status : "Get_Login_Initialize_Data",
    };
    var send_login_topic = "/exchange/" + store.getters.loggedInUser + "/Send.Action"
    User_Send(send_login_topic, JSON.stringify(user_now_status))
    }
    catch(e){
        console.log(e)
    }
    
};
//接收資料 //cocount補充
function ResponseCallback(frame) {
    var products = JSON.parse(frame.body);
    //console.log("得到的消息 msg=>" + frame.body);
    
    if (frame.headers.destination.indexOf('AllStockPrice.') >= 0) {
        store.dispatch("ReloadProductsData", products);
        //console.log(products)
    }
    if (frame.headers.destination.indexOf('Agent.Tree') >= 0) {
        store.dispatch("AgentTreeDataActions", products);
        //console.log(products);
    }
    if (frame.headers.destination.indexOf('Agent.List') >= 0) {
        store.dispatch("AgentListDataActions", products);
        //console.log(products);
    }
    if (frame.headers.destination.indexOf('Member.List') >= 0) {
        store.dispatch("MemberListDataActions", products);
        //console.log(products);
    }
    /*
    if (frame.headers.destination.indexOf('Tick.') >= 0) {
        console.log(frame.headers.destination)
        store.dispatch("ReloadTickData", products);
    }
    */
    //reloadProductsData(products);
    //console.log("products=>" + products);
};
export function User_Send(topic, message){
    console.log(message)
    user_client.send(topic, {priority: 9}/*如果你想发送一个有消息体（body）的信息，也必须传递headers参数。如果没有headers需要传递，可以用{}来表示*/, message);
}

//對外關閉網頁中連線
export function MQ_DisConnect(){			//關閉連線
    if(user_client && user_client.connected) {
        user_client.disconnect()
    }
};

