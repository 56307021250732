<template>
    <body id =Group_Statistic class= "body">
        <div class="group_statistic">
        <Grid 
            ref="grid"
            :style="{height:gridhight+'px', width:gridwidth +'px', border:'0px'}"
            :data-items="agentData"
            :column-virtualization="true"
            :reorderable="true"
            :columns = "rent_columns"
            >
            <GridToolbar class="gridtoolbar">
                <div class="myfloatleft ">
                    <div>
                    <Input :style="{ margin:'1px',padding:'0px' }" :size="'small'" :icon-name="'k-icon k-i-zoom'" v-model="search_word"></Input>
                    <Button :style="{ margin:'0px 20px 0px 0px' }" :rounded="'medium'" :size="'small'" v-text="'搜尋'" @click="Search"></Button>
                    <Button
                        :rounded="'medium'"
                        :size="'small'"
                        v-for="button in l_buttons"
                        v-text="button.name"
                        :togglable="true"
                        :key="button.id"
                        @click="GroupVueChange(button.id)"
                        :selected="select_button.id === button.id"
                        >
                    </Button> 
                    <span v-text="search_amount"></span>
                    </div>
                </div>
                <div class="myfloatright ">
                    <div>
                        <Button :rounded="'medium'" :size="'small'" v-text="'EXCEL'" @click="Excel"></Button>
                    </div>
                </div>
            </GridToolbar>
        </Grid>
        </div>
    </body>
</template>
<script>
import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
import { Button, ButtonGroup } from '@progress/kendo-vue-buttons';
import { Input } from '@progress/kendo-vue-inputs';
import { saveExcel } from '@progress/kendo-vue-excel-export';
import axios from "axios";
export default {
    name: 'Group_Statistic', 
    components: {
        Grid,
        GridToolbar,
        Button,
        Input,
    },   
    mounted(){
        this.select_button=this.l_buttons[0]
        const myObserver = new ResizeObserver(entries => {
        this.gridhight=document.getElementById('Group_Statistic').offsetHeight-1;
        this.gridwidth=document.getElementById('Group_Statistic').clientWidth;
        this.getAgentData(0);
        //console.log(this.gridhight)
        });
        const group_statistic = document.getElementById('Group_Statistic');
        myObserver.observe(group_statistic, {
            box: "content-box",
        });
    },
    data: function () {
        return {
            search_word:"",
            search_amount:"篩選出N筆資料,這是要篩選後再填入的資料",
            gridhight:"",
            gridwidth:"",

            select_button:{},
            l_buttons:[
                {
                    id: "all",
                    name: "所有下線",
                },
                {
                    id: "current",
                    name: "當前下線",
                },
                {
                    id: "extend",
                    name: "延伸下線",
                },
                {
                    id: "active",
                    name: "活躍代理",
                },
            ],

            rent_columns:[
                { field: 'zero', title: ' ', width: '40px', locked: true},
                { field: 'agent', title: '代理', width: '100%', locked: true},
                { field: 'account', title: '帳號', width: '100%', locked: true},
                { field: 'membersNum', title: '會員數', width: '100%'},
                { field: 'type', title: '類別', width: '100%'},
                { field: 'ratio', title: '佔成', width: '100%'},
                { field: 'rent', title: '租金', width: '100%'},
                { field: 'totalBuyNum', title: '總多', width: '100%'},
                { field: 'totalSellNum', title: '總空', width: '100%'},
                { field: 'noBuyNum', title: '未平多', width: '100%'},
                { field: 'noSellNum', title: '未平空', width: '100%'},
                { field: 'noProfit', title: '未平損益', width: '100%'},
                { field: 'todayProfit', title: '今日損益', width: '100%'},
                { field: 'withhold', title: '留倉預扣', width: '100%'},
                { field: 'commission', title: '手續費', width: '100%'},
                { field: 'outputMoney', title: '出金', width: '100%'},
                { field: 'inputMoney', title: '入金', width: '100%'},
                { field: 'cost', title: '費用', width: '100%'},
                { field: 'superBoom', title: '超爆', width: '100%'},
            ],
            agentData:[],
            gains_and_losses_example_data:[{
                "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
                "m":"範例",
                "n":"範例",
                "o":"範例",
                "p":"範例",
                "q":"範例",
                "r":"範例",
            },{
                 "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
                "m":"範例",
                "n":"範例",
                "o":"範例",
                "p":"範例",
                "q":"範例",
                "r":"範例",
            },{
                 "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
                "m":"範例",
                "n":"範例",
                "o":"範例",
                "p":"範例",
                "q":"範例",
                "r":"範例",
            },{
                 "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
                "m":"範例",
                "n":"範例",
                "o":"範例",
                "p":"範例",
                "q":"範例",
                "r":"範例",
            },{
                 "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
                "m":"範例",
                "n":"範例",
                "o":"範例",
                "p":"範例",
                "q":"範例",
                "r":"範例",
            },{
                 "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
                "m":"範例",
                "n":"範例",
                "o":"範例",
                "p":"範例",
                "q":"範例",
                "r":"範例",
            },{
                 "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
                "m":"範例",
                "n":"範例",
                "o":"範例",
                "p":"範例",
                "q":"範例",
                "r":"範例",
            },{
                 "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
                "m":"範例",
                "n":"範例",
                "o":"範例",
                "p":"範例",
                "q":"範例",
                "r":"範例",
            },{
                 "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
                "m":"範例",
                "n":"範例",
                "o":"範例",
                "p":"範例",
                "q":"範例",
                "r":"範例",
            },{
                 "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
                "m":"範例",
                "n":"範例",
                "o":"範例",
                "p":"範例",
                "q":"範例",
                "r":"範例",
            },{
                 "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
                "m":"範例",
                "n":"範例",
                "o":"範例",
                "p":"範例",
                "q":"範例",
                "r":"範例",
            },{
                 "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
                "m":"範例",
                "n":"範例",
                "o":"範例",
                "p":"範例",
                "q":"範例",
                "r":"範例",
            },{
                 "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
                "m":"範例",
                "n":"範例",
                "o":"範例",
                "p":"範例",
                "q":"範例",
                "r":"範例",
            },{
                 "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
                "m":"範例",
                "n":"範例",
                "o":"範例",
                "p":"範例",
                "q":"範例",
                "r":"範例",
            },{
                 "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
                "m":"範例",
                "n":"範例",
                "o":"範例",
                "p":"範例",
                "q":"範例",
                "r":"範例",
            },{
                 "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
                "m":"範例",
                "n":"範例",
                "o":"範例",
                "p":"範例",
                "q":"範例",
                "r":"範例",
            },{
                 "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
                "m":"範例",
                "n":"範例",
                "o":"範例",
                "p":"範例",
                "q":"範例",
                "r":"範例",
            },{
                 "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
                "m":"範例",
                "n":"範例",
                "o":"範例",
                "p":"範例",
                "q":"範例",
                "r":"範例",
            },{
                 "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
                "m":"範例",
                "n":"範例",
                "o":"範例",
                "p":"範例",
                "q":"範例",
                "r":"範例",
            },{
                 "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
                "m":"範例",
                "n":"範例",
                "o":"範例",
                "p":"範例",
                "q":"範例",
                "r":"範例",
            },{
                 "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
                "m":"範例",
                "n":"範例",
                "o":"範例",
                "p":"範例",
                "q":"範例",
                "r":"範例",
            },{
                 "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
                "m":"範例",
                "n":"範例",
                "o":"範例",
                "p":"範例",
                "q":"範例",
                "r":"範例",
            },{
                 "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
                "m":"範例",
                "n":"範例",
                "o":"範例",
                "p":"範例",
                "q":"範例",
                "r":"範例",
            },
            ],
        }
    },
    methods: {
        Search(){

            /* this.search_word*/
            /*Do some things*/ 
        },
        Excel(){
            saveExcel({
            data: this.agentData,
            fileName: "下線資訊",
            columns: this.rent_columns,
            });
        },
        getAgentData(type) {
            axios.get(this.$httpPath + '/AgentManager/getMyAgentInfo?type=' + type)
                .then((response) => {
                    if (response.status === 200) {
                        this.agentData = response.data;
                    }
                    else {
                        console.error("Response ERR:", response.status);
                    }
                })
                .catch((error) => {
                    console.error("Request failed:", error);
                });
        },
        GroupVueChange(vue_id) {
            this.select_button = this.l_buttons.find(b => b.id == vue_id);
            console.log(this.select_button);
            switch(vue_id){
                case 'all' :{
                    this.getAgentData(0);
                    break;
                }
                case 'current' :{
                    this.getAgentData(1);
                    break;
                }
                case 'extend' :{
                    this.getAgentData(2);
                    break;
                }
                case 'active' :{
                    this.getAgentData(3);
                    break;
                }
                default: {
                    break;
                }
            }
        },
    }
};
</script>
<style scoped>
.body{
    height:100%;
    width:100%;
    display: inline-flex;
    color:#fff;
    font-family:"微軟正黑體";
    flex-direction: row;
    flex-wrap: nowrap;
}
.group_statistic{
    height:100%; 
    margin: 0px;
    display: inline-flex;
}
.myfloatleft{
    flex:1;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
}
.myfloatright{
    width:auto;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    white-space: nowrap;
}

.gridtoolbar{
    display: inline-flex;
    flex-direction: row;

    height:33px;
    width: 100%;
    overflow:hidden;
    color: #fff;;
    padding: 1px 10px;
    background-color:#106184;
}
Button{
    height:100%;
    width:auto;
    color: #fff;
    background-color: #17a2b8;
    padding: 2px 5px;
    margin: 0px 2px;
}
</style>
