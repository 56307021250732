<template>
    <body id ="Overview" class="body">
        <Grid 
            ref="grid"
            :style="{height: gridhight+'px', width: gridwidth+'px'}"
            :data-items="this.$store.state.allProducts"
            :column-virtualization="true"
            :columns = "columns"
            >
            
        </Grid>
    </body>
</template>
<script>
import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
import { Button, ButtonGroup } from '@progress/kendo-vue-buttons';
export default {
    name: 'Overview', /*市場總覽 */
      components: {
        Grid,

    },   
    data: function () {
        return {
            gridhight:"",
            gridwidth:"",

            columnss: [
                { field: 'bstrStockNo', title: '商品代號', editable: false,locked: true,selectable: true },
                { field: 'bstrStock', title: '商品', editable: false, width: '120px',locked: true,selectable: true},
                { field: '倉位多', title: '倉位多', editable: false, width: '120px'  },
                { field: '倉位空', title: '倉位空', editable: false, width: '120px' , format: '{0:d}' },
                { field: 'nBid', title: '買進價',  editable: false, cell: 'pricechangeTemplate', width: '150px'},
                { field: 'nAsk', title: '賣出價',  editable: false, editor: 'boolean' },
                { field: 'nClose', title: '成交價',  editable: false, width: '150px'},
                { field: 'amplitude', title: '漲跌',width: '150px',cell:"myCellTemplate"},
                { field: 'nAmplitude', title: '漲幅%',  editable: false, width: '150px'},
                { field: '單量', title: '單量',  editable: false, width: '150px'},
                { field: '總量', title: '總量',  editable: false, width: '150px'},
                { field: '昨收價', title: '昨收價',  editable: false, width: '150px'},
                { field: '開盤價', title: '開盤價',  editable: false, width: '150px'},
                { field: '最高價', title: '最高價',  editable: false, width: '150px'},
                { field: '最低價', title: '最低價',  editable: false, width: '150px'},
                { field: '時間', title: '時間',  editable: false, width: '150px'},
                { field: '交易', title: '交易',  editable: false, width: '150px'},
                { field: '最後成交價', title: '最後成交價',  editable: false, width: '150px'},
                { field: '最後交易日', title: '最後交易日',  editable: false, width: '150px'},
                { field: '說明', title: '說明',  editable: false, width: '150px'},
                { field: '商品類別', title: '商品類別',  editable: false, width: '150px'}                             
            ],
            columns:[
                { field: 'zero', title: ' ', width: '40px', locked: true},
                { field: 'bstrStock', title: '商品名稱', width: '100%', locked: true},
                { field: '倉位多', title: '倉位多', width: '100%'},
                { field: '倉位空', title: '倉位空', width: '100%'},
                { field: 'nBid', title: '買進價', width: '100%'},
                { field: 'nAsk', title: '賣出價', width: '100%'},
                { field: 'nAsk', title: '成交價', width: '100%'},
                { field: 'Amplitude', title: '漲跌', width: '100%'},
                { field: 'nAmplitude', title: '漲幅%', width: '100%'},
                { field: '單量', title: '單量', width: '100%'},
                { field: '總量', title: '總量', width: '100%'},
                { field: '時間', title: '時間', width: '100%'},
                { field: '交易', title: '交易', width: '100%'},
                { field: '最後交易日', title: '最後交易日', width: '100%'},
                { field: '最後成交價', title: '最後成交價', width: '100%'},
                { field: '昨收價', title: '昨日價', width: '100%'},
                { field: '開盤價', title: '開盤價', width: '100%'},
                { field: '最高價r', title: '最高價', width: '100%'},
                { field: '最低價', title: '最低價', width: '100%'},
                { field: '商品類別', title: '商品類別', width: '100%'},
                { field: 'u', title: '操作狀態', width: '100%'},
            ],
            /*
            example_data:[{
                "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
                "m":"範例",
                "n":"範例",
                "o":"範例",
                "p":"範例",
                "q":"範例",
                "r":"範例",
                "s":"範例",
                "t":"範例",
                "u":"範例",
            },
            ],*/
        }
    },
    mounted(){
        const myObserver = new ResizeObserver(entries => {
            this.gridhight=document.getElementById('TabStrip').getElementsByTagName('div')[1].clientHeight-40;
            this.gridwidth=document.getElementById('TabStrip').getElementsByTagName('div')[1].clientWidth-33;
            console.log(document.getElementById('TabStrip').getElementsByTagName('div')[1].offsetHeight)
            console.log(this.gridwidth)
        });
        const TabStrip = document.getElementById('TabStrip');
        myObserver.observe(TabStrip, {
            box: "content-box",
        });
    },
    methods: {

    }
};
</script>
<style scoped>
.body{
    height:100%;
    width:100%;
    display: inline-flex;
    color:#fff;
    font-family:"微軟正黑體";
    flex-direction: row;
    flex-wrap: nowrap;
}
.gridtoolbar{
    height: auto;
    padding: 0px 5px;
    background-color: #0c4d70;
    color: #fff;
    display: inline-flex;
    flex-direction:row  
}
.myfloatleft{
    flex:1;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
}
.myfloatright{
    width:auto;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
}
.borader{
    display: inline-flex;
    background-color: #198754;
    border-radius:4px;
    overflow: hidden;
    justify-content: center;
    color: #fff;
    margin: 3px;
    border: 1px;
    padding: 3px 6px;
    vertical-align: middle;
}
Button{
    height:100%;
    width:auto;
    color: #fff;
    background-color: #17a2b8;
    padding: 2px 5px;
    margin: 3px;
}
</style>
