<template>
    <body id = 'CreditsAmount_Dialog'>
        <Grid
            ref="grid"
            :style="{height:'100%', width:'cale(100%-0px)', border:'0px'}"
            :data-items="data"
            :column-virtualization="false"
            :columns = "columns"

            :enableRtl="true"
            :resizable="false" 
            :selectable="true"
            >
            <GridToolbar class="gridtoolbar">
                <div class="bar">
                    <div class="center" >
                        <div>
                            <span style="margin:0px 5px;font-size:16px; vertical-align: middle;" v-text="'信用額度'"></span>
                            <Input :style="{ margin:'0px 5px', overflow: 'hidden',flex: 'nowrap' }" :size="'small'" :icon-name="'k-icon k-i-zoom'" v-model="search_word"></Input>
                            <Button :rounded="'medium'" :size="'medium'" v-text="'搜索'" ></Button>                            
                        </div>
                        <div style="margin:0px 5px; font-size:13px;">
                            <div>
                                <span v-text="'信用額度 '"></span>
                                <span style="color:rgb(252, 237, 161); font-size:16px; font-weight:bold;" v-text="credit_limit"></span>
                            </div>
                            <div>
                                <span v-text="'帳戶餘額 '"></span>
                                <span style="color:rgb(252, 237, 161); font-size:16px; font-weight:bold;" v-text="account_balance"></span>
                            </div>
                        </div>
                        <div style="margin:0px 5px; font-size:13px;">
                            <div>
                                <span v-text="'上層餘額[額度] '"></span>
                                <span style="color:rgb(252, 237, 161); font-size:16px; font-weight:bold;" v-text="remaining"></span>
                            </div>
                            <div>
                                <span v-text="'用戶可用[額度] '"></span>
                                <span style="color:rgb(252, 237, 161); font-size:16px; font-weight:bold;" v-text="available"></span>
                            </div>
                        </div>
                    </div>
                    <div class="center">
                        <span style="margin:0px 5px; font-size:16px; vertical-align: middle;" v-text="'儲值紀錄'"></span>
                        <div>
                            <DatePicker
                                class = "DatePicker"
                                :size="'small'"
                                :rounded="'large'"
                                :default-value="new Date()"
                                :max="new Date()"
                                :value="datepicker_start"
                                @change="OnChange_Date($event,'start')"
                                :format="'yyyy/MM/dd'"
                                >
                            </DatePicker>
                        </div>
                        <span style="vertical-align: middle;" v-text="'~'"></span>
                        <div>
                            <DatePicker
                                class = "DatePicker"
                                :size="'small'"
                                :rounded="'large'"
                                :default-value="new Date()"
                                :max="new Date()"
                                :value="datepicker_end"
                                @change="OnChange_Date($event,'end')"
                                :format="'yyyy/MM/dd'"
                                >
                            </DatePicker>
                        </div>
                        <div style="padding-right:20px">
                            <Button :rounded="'medium'" size="medium" v-text="'搜尋'" @click="Date_Date_Search('search')"></Button>
                        </div>
                        <div>
                            <Button :rounded="'medium'" size="medium" v-text="'今日'" @click="Date_Search('today')"></Button>
                        </div>
                        <div>
                            <Button :rounded="'medium'" size="medium" v-text="'昨日'" @click="Date_Search('yesterday')"></Button>
                        </div>
                        <div>
                            <Button :rounded="'medium'" size="medium" v-text="'本周'" @click="Date_Search('current_week')"></Button>
                        </div>
                        <div>
                            <Button :rounded="'medium'" size="medium" v-text="'本月'" @click="Date_Search('current_month')"></Button>
                        </div>
                        <div>
                            <Button :rounded="'medium'" size="medium" v-text="'上月'" @click="Date_Search('one_months_ago')"></Button>
                        </div>
                    </div>
                </div>
            </GridToolbar>
        </Grid>
    </body>
</template>
<script>
import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
import { DatePicker } from '@progress/kendo-vue-dateinputs';
import { Checkbox, Input, RadioGroup, TextArea } from '@progress/kendo-vue-inputs';
import { Button, ButtonGroup } from '@progress/kendo-vue-buttons';
import dateFormat, { masks } from "dateformat";
import { isEqualDate, Day, prevDayOfWeek, nextDayOfWeek, firstDayOfMonth, lastDayOfMonth, addMonths, addWeeks, addDays } from '@progress/kendo-date-math';
export default {
    name: 'CreditsAmount_Dialog', 
      components: {
        Grid, 
        GridToolbar,
        Button,
        DatePicker,
        Input,

    },   
    props: {
        select_row_data:{},
    },
    data: function () {
        return {
            search_word:"",
            search_date_text:"",
            datepicker_start:new Date(),
            datepicker_end:new Date(),
            date_start:dateFormat(new Date(), "yyyy/mm/dd"),
            date_end:dateFormat(new Date(), "yyyy/mm/dd"),
            current_month:dateFormat(new Date(), "m"),
            one_months_ago:dateFormat(addMonths(new Date(), -1), "m"),
            two_months_ago:dateFormat(addMonths(new Date(), -2), "m"),

            credit_limit:0,//信用額度
            account_balance: 0,//帳戶餘額
            remaining: 0,//上層剩餘[額度]
            available: 0,//用戶可用[額度]
            data:[],/*資料連接*/
            columns:[
                { field: 'a', title: '操作日期', width: '170px'},
                { field: 'b', title: '帳號', width: '125px'},
                { field: 'c', title: '類型', width: '80px'},
                { field: 'd', title: '金額', width: '100px'},
                { field: 'e', title: '操作人員', width: '125px'},
                { field: 'f', title: '備註', width: '120px'},
            ],
        }
    },
    mounted(){
        
    },
    methods: {
        Filter_Change(){

        },
         OnChange_Date(event, key) {
            console.log(event.value)
            //補一段開始時間大於結束時間       修正為當天日期
            //補一段結束時間小於開始時間       修正為當天日期
            //補一段開始,結束時間大於當天日期  修正為當天日期
            switch (key) {
                case 'start': {
                    console.log("start")
                    this.date_start = dateFormat(event.value, "yyyy/mm/dd");
                    this.datepicker_start = event.value;
                    break; 
                }
                case 'end': {
                    console.log("end")
                    this.date_end = dateFormat(event.value, "yyyy/mm/dd")
                    this.datepicker_end = event.value;
                    break; 
                }
                default: {
                    break;
                }
            }
            console.log(this.date_start)
            console.log(this.date_end)
        },
        Date_Search(event){
            switch (event) {
                case 'search': {
                    console.log("search")
                    break; 
                }
                case 'today': {
                    this.datepicker_start = new Date(),
                    this.datepicker_end = new Date(),
                    this.date_start = dateFormat(new Date(), "yyyy/mm/dd")
                    this.date_end = dateFormat(new Date(), "yyyy/mm/dd")
                    break;
                }
                case 'yesterday': {
                    this.datepicker_start = addDays(new Date(), -1),
                    this.datepicker_end = addDays(new Date(), -1),
                    this.date_start = dateFormat(addDays(new Date(), -1), "yyyy/mm/dd")
                    this.date_end = dateFormat(addDays(new Date(), -1), "yyyy/mm/dd")
                    break;
                }
                case 'current_week': {
                    this.datepicker_start = nextDayOfWeek(new Date(), Day.Monday),
                    this.date_start = dateFormat(nextDayOfWeek(new Date(), Day.Monday), "yyyy/mm/dd")

                    if(isEqualDate(new Date(), nextDayOfWeek(new Date(), Day.Sunday)))
                    {
                        this.datepicker_end = nextDayOfWeek(new Date(), Day.Sunday),
                        this.date_end = dateFormat(nextDayOfWeek(new Date(), Day.Sunday), "yyyy/mm/dd")
                    }
                    else{
                        this.datepicker_end = new Date(),
                        this.date_end = dateFormat(new Date(), "yyyy/mm/dd")
                    };
                    break;
                }
                case 'current_month': {
                    this.datepicker_start = firstDayOfMonth(new Date()),
                    this.date_start = dateFormat(firstDayOfMonth(new Date()), "yyyy/mm/dd")
                    if(isEqualDate(new Date(), lastDayOfMonth(new Date())))
                    {
                        this.datepicker_end = lastDayOfMonth(new Date()),
                        this.date_end = dateFormat(lastDayOfMonth(new Date()), "yyyy/mm/dd")
                    }
                    else{
                        this.datepicker_end = new Date(),
                        this.date_end = dateFormat(new Date(), "yyyy/mm/dd")
                    };
                    break;
                }
                case 'one_months_ago': {
                    this.datepicker_start = firstDayOfMonth(addMonths(new Date(), -1)),
                    this.datepicker_end = lastDayOfMonth(addMonths(new Date(), -1)),
                    this.date_start = dateFormat(firstDayOfMonth(addMonths(new Date(), -1)), "yyyy/mm/dd")
                    this.date_end = dateFormat(lastDayOfMonth(addMonths(new Date(), -1)), "yyyy/mm/dd")
                    break;
                }
                default: {
                    break;
                }
            }
            this.search_date_text= "日期:"+this.date_start+"~"+this.date_end;
        },
    },
};
</script>
<style scoped>
body{
    height:100%; 
    margin : -16px;
    display: flex;
    flex-direction: column;
    font-size: 15px;
}
.gridtoolbar{
    height: 80px;
    width:100%;
    padding: 0px 0px;
    border: 0px;
    background-color: #0c4d70;
    color: #fff;
    display: inline-flex;
    
}
.bar{
    flex :1;
    width:100%;
    display: inline-flex;
    flex-direction: column;
}
.center{
    flex :1;
    width:100%;
    padding: 0px 0px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
}
.DatePicker
{
    margin:3px; 
    font-size:12px; 
    width:120px;
}
.DatePicker > span
{
    margin:0px; 
    
}
Button{
    height:100%;
    width:auto;
    color: #fff;
    background-color: #17a2b8;
    padding: 2px 5px;
    margin: 3px;
}
</style>
