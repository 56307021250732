<template>
    <body id = 'History_Status_Dialog'>
        <Grid
            ref="grid"
            :style="{height:'100%', width:'cale(100%-0px)', border:'0px'}"
            :data-items="data"
            :column-virtualization="false"
            :columns = "columns"

            :enableRtl="true"
            :resizable="false" 
            :selectable="true"
            >
        </Grid>
    </body>
</template>
<script>
import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
import { Button, ButtonGroup } from '@progress/kendo-vue-buttons';
export default {
    name: 'History_Status_Dialog', 
      components: {
        Grid,
    },   
    props: {
        select_row_data:{},
    }, 
    data: function () {
        return {

            data:[],/*資料連接*/
            columns:[
                { field: 'a', title: '代理', width: '125px'},
                { field: 'b', title: '原狀態', width: '80px'},
                { field: 'c', title: '新狀態', width: '80px'},
                { field: 'd', title: '時間', width: '170px'},
            ],
        }
    },
    mounted(){
        
    },
    methods: {
        
    },
};
</script>
<style scoped>
body{
    height:100%; 
    margin : -16px;
    display: flex;
    flex-direction: column;
    font-size: 15px;
}
.gridtoolbar{
    height: 40px;
    width:100%;
    padding: 0px 0px;
    border: 0px;
    background-color: #0c4d70;
    color: #fff;
    display: inline-flex;
    
}
.bar{
    flex :1;
    width:100%;
    display: inline-flex;
    flex-direction: column;
}
.center{
    flex :1;
    width:100%;
    padding: 0px 0px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
}
.DatePicker
{
    margin:3px; 
    font-size:12px; 
    width:120px;
}
.DatePicker > span
{
    margin:0px; 
    
}
Button{
    height:100%;
    width:auto;
    color: #fff;
    background-color: #17a2b8;
    padding: 2px 5px;
    margin: 3px;
}
</style>
