<template>
    <body id = 'Add_Post_Dialog'>
        <div style="display:flex;height:35px; width:100%; margin:5px 0px; align-items:center; justify-content:flex-start;">
                <span v-text="'設定代理'"/>
                <div
                    style="display:flex; padding:0px 10px; margin-left:7px; background-color:#e9ecef; align-items:center; font-weight:bold; font-size:16px; border-radius:4px; border:1px solid #ced4da;"
                    :style="{ width: '205px', height:'33px'}"
                    v-text="select_row_data['mAccountName']"/>
            </div>
            <div style="display:flex;height:35px; width:100%; margin:5px 0px; align-items:center; justify-content:flex-start;">
                <span v-text="'公告設定'"/>
                <RadioGroup 
                style="margin-left:7px"
                :data-items="post_dataItems"
                :layout="'horizontal'"
                :default-value="post_dataItems[0].value"/>
            </div>
            <div style="display:flex;height:35px; width:100%; margin:5px 0px;align-items:center; justify-content:flex-start;">
                <span v-text="'開始日期'"/>
                <DatePicker
                    style="margin:3px; margin-left:7px"
                    :style="{ width: 'auto' }"
                    :size="'small'"
                    :rounded="'large'"
                    :default-value="new Date()"
                    :max="new Date()"
                    :value="datepicker_start"
                    @change="OnChange_Date($event,'start')"
                    :format="'yyyy/MM/dd'"
                    >
                </DatePicker>
            </div>
            <div style="display:flex;height:35px; width:100%; margin:5px 0px;align-items:center; justify-content:flex-start;">
                <span v-text="'結束日期'"/>
                <DatePicker
                    style="margin:3px; margin-left:7px"
                    :style="{ width: 'auto' }"
                    :size="'small'"
                    :rounded="'large'"
                    :default-value="new Date()"
                    :max="new Date()"
                    :value="datepicker_start"
                    @change="OnChange_Date($event,'start')"
                    :format="'yyyy/MM/dd'"
                    >
                </DatePicker>
            </div>
            <div style="display:flex;height:auto; width:100%; margin:5px 0px;align-items:flex-start; justify-content:flex-start;">
                <span v-text="'公告內容'"/>
                <TextArea 
                    style="margin:3px; margin-left:7px" 
                    :size="'large'" 
                    :style="{ width: '203px' }" />
            </div>
    </body>
</template>
<script>
import { DatePicker } from '@progress/kendo-vue-dateinputs';
import dateFormat, { masks } from "dateformat";
import { Checkbox, Input, RadioGroup, TextArea } from '@progress/kendo-vue-inputs';
import { isEqualDate, Day, prevDayOfWeek, nextDayOfWeek, firstDayOfMonth, lastDayOfMonth, addMonths, addWeeks, addDays } from '@progress/kendo-date-math';
export default {
    name: 'Add_Post_Dialog', 
      components: {

        RadioGroup,
        DatePicker,
        TextArea
    },   
    props: {
        select_row_data:{},
    }, 
    data: function () {
        return {
            post_dataItems: [
            {
                label: "公告",
                value: "公告",
            },
            {
                label: "內部公告",
                value: "內部公告",
            },
            ],

            datepicker_start:new Date(),
            datepicker_end:new Date(),
            date_start:dateFormat(new Date(), "yyyy/mm/dd"),
            date_end:dateFormat(new Date(), "yyyy/mm/dd"),
            current_month:dateFormat(new Date(), "m"),
            one_months_ago:dateFormat(addMonths(new Date(), -1), "m"),
            two_months_ago:dateFormat(addMonths(new Date(), -2), "m"),

            data:[],/*資料連接*/
            columns:[
                { field: 'a', title: '操作日期', width: '170px'},
                { field: 'b', title: '帳號', width: '125px'},
                { field: 'c', title: '類型', width: '80px'},
                { field: 'd', title: '金額', width: '100px'},
                { field: 'e', title: '操作人員', width: '125px'},
                { field: 'f', title: '備註', width: '120px'},
            ],
        }
    },
    mounted(){
        
    },
    methods: {
        Filter_Change(){

        },
         OnChange_Date(event, key) {
            console.log(event.value)
            //補一段開始時間大於結束時間       修正為當天日期
            //補一段結束時間小於開始時間       修正為當天日期
            //補一段開始,結束時間大於當天日期  修正為當天日期
            switch (key) {
                case 'start': {
                    console.log("start")
                    this.date_start = dateFormat(event.value, "yyyy/mm/dd");
                    this.datepicker_start = event.value;
                    break; 
                }
                case 'end': {
                    console.log("end")
                    this.date_end = dateFormat(event.value, "yyyy/mm/dd")
                    this.datepicker_end = event.value;
                    break; 
                }
                default: {
                    break;
                }
            }
            console.log(this.date_start)
            console.log(this.date_end)
        },
        
    },
};
</script>
<style scoped>
body{
    height:100%; 
    margin : -16px;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    padding:16px;
}
.gridtoolbar{
    height: 40px;
    width:100%;
    padding: 0px 0px;
    border: 0px;
    background-color: #0c4d70;
    color: #fff;
    display: inline-flex;
    
}
.bar{
    flex :1;
    width:100%;
    display: inline-flex;
    flex-direction: column;
}
.center{
    flex :1;
    width:100%;
    padding: 0px 0px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
}
.DatePicker
{
    margin:3px; 
    font-size:12px; 
    width:120px;
}
.DatePicker > span
{
    margin:0px; 
    
}
Button{
    height:100%;
    width:auto;
    color: #fff;
    background-color: #17a2b8;
    padding: 2px 5px;
    margin: 3px;
}
</style>
