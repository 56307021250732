<template>
    <body id ="Statistics" class="body">
        <Grid 
            ref="grid"
            :style="{height: gridhight+'px', width: gridwidth+'px'}"
            :data-items="example_data"
            :column-virtualization="true"
            :columns = "columns"
            >
            <GridToolbar class="gridtoolbar">
                <div class="myfloatleft">
                    <div>
                        <span v-text="'昨日權益數'"></span>
                        <span v-if="parseInt(yesterday_equity) == 0">
                            <span class="gridtoolbar_equal" v-text="yesterday_equity"></span>
                        </span>
                        <span v-else>
                            <span v-if="parseInt(yesterday_equity) > 0">
                                <span class="gridtoolbar_more" v-text="yesterday_equity"></span>
                            </span>
                            <span v-if="parseInt(yesterday_equity) < 0">
                                <span class="gridtoolbar_less" v-text="yesterday_equity"></span>
                            </span>
                        </span>
                    </div>
                    <div>
                        <span v-text="'今日損益'"></span>
                        <span v-if="parseInt(profit_and_loss_today) == 0">
                            <span class="gridtoolbar_equal" v-text="profit_and_loss_today"></span>
                        </span>
                        <span v-else>
                            <span v-if="parseInt(profit_and_loss_today) > 0">
                                <span class="gridtoolbar_more" v-text="profit_and_loss_today"></span>
                            </span>
                            <span v-if="parseInt(profit_and_loss_today) < 0">
                                <span class="gridtoolbar_less" v-text="profit_and_loss_today"></span>
                            </span>
                        </span>
                    </div>
                    <div>
                        <span v-text="'餘額'"></span>
                        <span v-if="parseInt(balance) == 0">
                            <span class="gridtoolbar_equal" v-text="balance"></span>
                        </span>
                        <span v-else>
                            <span v-if="parseInt(balance) > 0">
                                <span class="gridtoolbar_more" v-text="balance"></span>
                            </span>
                            <span v-else >
                                <span class="gridtoolbar_less" v-text="balance"></span>
                            </span>
                        </span>
                    </div>
                    <div>
                        <span v-text="'留倉預扣金'"></span>
                        <span v-if="withholding_money == 0">
                            <span class="gridtoolbar_equal" v-text="withholding_money"></span>
                        </span>
                        <span v-else>
                            <span v-if="withholding_money > 0">
                                <span class="gridtoolbar_more" v-text="withholding_money"></span>
                            </span>
                            <span v-if="withholding_money < 0">
                                <span class="gridtoolbar_less" v-text="withholding_money"></span>
                            </span>
                        </span>
                    </div>
                    <div>
                        <span v-text="'總權益數'"></span>
                        <span v-if="total_equity == 0">
                            <span class="gridtoolbar_equal" v-text="total_equity"></span>
                        </span>
                        <span v-else>
                            <span v-if="total_equity > 0">
                                <span class="gridtoolbar_more" v-text="total_equity"></span>
                            </span>
                            <span v-if="total_equity < 0">
                                <span class="gridtoolbar_less" v-text="total_equity"></span>
                            </span>
                        </span>
                    </div>
                </div>
                <div class="myfloatright">
                    <Button size="small" v-text="'EXCEL'"></Button>
                </div>
            </GridToolbar>
        </Grid>
    </body>
</template>
<script>
import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
import { Button, ButtonGroup } from '@progress/kendo-vue-buttons';
export default {
    name: 'Statistics', /*統計*/
      components: {
        Grid,
        GridToolbar,
        Button,
    },   
    data: function () {
        return {
            gridhight:"",
            gridwidth:"",
            datepicker_start:"",
            
            yesterday_equity:-9999,
            profit_and_loss_today:0,
            balance: 9999,
            withholding_money:0,
            total_equity:0,

            columns:[
                { field: 'a', title: '會員', width: '100%'},
                { field: 'b', title: '商品', width: '100%'},
                { field: 'c', title: '未平倉', width: '100%'},
                { field: 'd', title: '已平倉', width: '100%'},
                { field: 'e', title: '總口數', width: '100%'},
                { field: 'f', title: '手續費', width: '100%'},
                { field: 'g', title: '損益', width: '100%'},
            ],
            example_data:[{
                "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
                "m":"範例",
                "n":"範例",
            },
            ],
        }
    },
    mounted(){
        const myObserver = new ResizeObserver(entries => {
            this.gridhight=document.getElementById('TabStrip').getElementsByTagName('div')[1].clientHeight-40;
            this.gridwidth=document.getElementById('TabStrip').getElementsByTagName('div')[1].clientWidth-33;
            console.log(document.getElementById('TabStrip').getElementsByTagName('div')[1].offsetHeight)
            console.log(this.gridwidth)
        });
        const TabStrip = document.getElementById('TabStrip');
        myObserver.observe(TabStrip, {
            box: "content-box",
        });
    },
    methods: {

    }
};
</script>
<style scoped>
.body{
    height:100%;
    width:100%;
    display: inline-flex;
    color:#fff;
    font-family:"微軟正黑體";
    flex-direction: row;
    flex-wrap: nowrap;
}
.gridtoolbar{
    height: auto;
    padding: 0px 5px;
    background-color: #0c4d70;
    color: #fff;
    display: inline-flex;
    flex-direction:row;
    vertical-align: middle;
}
.gridtoolbar_more{
    font-size: 20px;
    height: auto;
    padding: 0px 5px;
    color: #009000;/*需要更換*/ 
    font-weight:bold;
}
.gridtoolbar_less{
    font-size: 20px;
    height: auto;
    padding: 0px 5px;
    color: rgb(255, 121, 133);
    font-weight:bold;
}
.gridtoolbar_equal{
    font-size: 20px;
    height: auto;
    padding: 0px 5px;
    color: rgb(252, 237, 161);
    font-weight:bold;
}
.myfloatleft{
    flex:1;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    vertical-align: middle;
}
.myfloatright{
    width:auto;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    vertical-align: middle;
}
.borader{
    display: inline-flex;
    background-color: #198754;
    border-radius:4px;
    overflow: hidden;
    justify-content: center;
    color: #fff;
    margin: 3px;
    border: 1px;
    padding: 3px 6px;
    vertical-align: middle;
}
Button{
    height:100%;
    width:auto;
    color: #fff;
    background-color: #17a2b8;
    padding: 2px 5px;
    margin: 3px;
}
</style>
