<template>
    <body id=Classification_Form class="body">
        <div class="classificationtoper">
            <div class="myfloatleft ">
                
                <span style="padding-top:2px;font-size:18px; vertical-align: middle;" v-text="tabstrip_selected_title[tabstrip_selected]"></span>
                <div>
                    <DatePicker
                        style="margin:3px"
                        :style="{ width: '120px' }"
                        :size="'small'"
                        :rounded="'large'"
                        :default-value="new Date()"
                        :max="new Date()"
                        :value="datepicker_start"
                        @change="OnChange_Date($event,'start')"
                        :format="'yyyy/MM/dd'"
                        >
                    </DatePicker>
                </div>
                <span style="vertical-align: middle;" v-text="'~'"></span>
                <div>
                    <DatePicker
                        style="margin:3px"
                        :style="{ width: '120px' }"
                        :size="'small'"
                        :rounded="'large'"
                        :default-value="new Date()"
                        :max="new Date()"
                        :value="datepicker_end"
                        @change="OnChange_Date($event,'end')"
                        :format="'yyyy/MM/dd'"
                        >
                    </DatePicker>
                </div>
                <div style="padding-right:20px">
                    <Button size="small" v-text="'搜尋'" @click="Date_Date_Search('search')"></Button>
                </div>
                <div>
                    <Button size="small" v-text="'今日'" @click="Date_Search('today')"></Button>
                </div>
                <div>
                    <Button  size="small" v-text="'昨日'" @click="Date_Search('yesterday')"></Button>
                </div>
                <div>
                    <Button  size="small" v-text="'本周'" @click="Date_Search('current_week')"></Button>
                </div>
                <div>
                    <Button  size="small" v-text="'上周'" @click="Date_Search('one_week_ago')"></Button>
                </div>
                <div>
                    <Button  size="small" v-text="current_month+'月'" @click="Date_Search('current_month')"></Button>
                </div>
                <div>
                    <Button size="small" v-text="one_months_ago+'月'" @click="Date_Search('one_months_ago')"></Button>
                </div>
                <div>
                    <Button  size="small" v-text="two_months_ago+'月'" @click="Date_Search('two_months_ago')"></Button>
                </div>
                <div>
                    <Button size="small" v-text="'EXCEL'"></Button>
                </div>
                <div>
                    <Button size="small" v-text="'下載'"></Button>
                </div>
            </div>
            <div class="myfloatright">
                <div>
                    <span v-text="search_date_text"></span>
                </div>
            </div>
        </div>
        <div class="classificationcenter">
            <TabStrip 
                id="TabStrip" 
                class="tabstrip" 
                :selected="tabstrip_selected" 
                @select="TabStrip_OnSelect"
                >
                <TabStripTab 
                    id="TabStripTab" 
                    class="tabstriptab" 
                    titleRender="title"
                    >
                    <template v-slot:title>
                        <span>
                            <span class="tabstriptab_font" v-text="tabstrip_selected_title[0]"></span>
                        </span>
                    </template>
                    <Grid  
                        ref="grid"
                        :style="{height:gridhight +'px', width:gridwidth +'px'}"
                        :data-items="gains_and_losses_example_data"
                        :column-virtualization="true"
                        :reorderable="true"
                        :columns = "path_income_columns"
                        >
                        <GridToolbar class="gridtoolbar">
                        </GridToolbar>
                    </Grid>
                </TabStripTab>
                <TabStripTab 
                    id="TabStripTab" 
                    class="tabstriptab" 
                    titleRender="title"
                    >
                    <template v-slot:title>
                        <span>
                            <span class="tabstriptab_font" v-text="tabstrip_selected_title[1]"></span>
                        </span>
                    </template>
                    <Grid 
                        ref="grid"
                        :style="{height:gridhight +'px', width:gridwidth +'px'}"
                        :data-items="gains_and_losses_example_data"
                        :column-virtualization="true"
                        :columns = "rent_columns"
                        >
                        <GridToolbar class="gridtoolbar">
                        </GridToolbar>
                    </Grid>
                </TabStripTab>
                <TabStripTab 
                    id="TabStripTab" 
                    class="tabstriptab" 
                    titleRender="title"
                    >
                    <template v-slot:title>
                        <span>
                            <span class="tabstriptab_font" v-text="tabstrip_selected_title[2]"></span>
                        </span>
                    </template>
                    <Grid 
                        ref="grid"
                        :style="{height:gridhight +'px', width:gridwidth +'px'}"
                        :data-items="gains_and_losses_example_data"
                        :column-virtualization="true"
                        :reorderable="true"
                        :columns = "member_income_columns"
                        >
                        <GridToolbar class="gridtoolbar">
                            <div>
                                <Button :rounded="'medium'" :size="'small'" v-text="'群組篩選'" @click="Table_3_Filter_1"></Button>
                                <Input :style="{ margin:'3px',padding:'0px' }" :size="'small'" :icon-name="'k-icon k-i-zoom'" v-model="table_3_search_word"></Input>
                                <Button style="margin-right:20px;" :rounded="'medium'" :size="'small'" v-text="'搜尋'" @click="Table_3_Filter_2"></Button>
                            </div>
                            <div>
                                <Button :rounded="'medium'" :size="'small'" v-text="'全部'" @click="Table_3_Filter_3"></Button>
                                <Checkbox style="overflow: hidden;white-space: nowrap;flex-wrap: nowrap;"  :style="{ margin:'2px', padding: '2px 4px' }" :default-checked="false" :label="'活躍玩家'" @click="Table_3_Filter_4"></Checkbox>
                                <div class="borader" v-text="'篩選出' + table_3_filter_calculation +'筆'"></div>
                            </div>
                        </GridToolbar>
                    </Grid>
                </TabStripTab>
                 <TabStripTab 
                    id="TabStripTab" 
                    class="tabstriptab" 
                    titleRender="title"
                    >
                    <template v-slot:title>
                        <span>
                            <span class="tabstriptab_font" v-text="tabstrip_selected_title[3]"></span>
                        </span>
                    </template>
                    <Grid 
                        ref="grid"
                        :style="{height:gridhight +'px', width:gridwidth +'px'}"
                        :data-items="gains_and_losses_example_data"
                        :column-virtualization="true"
                        :reorderable="true"
                        :columns = "all_reconciliation_columns"
                        >
                        <GridToolbar class="gridtoolbar">
                            <div>
                                <Button :rounded="'medium'" :size="'small'" v-text="'群組篩選'" @click="Table_4_Filter_1"></Button>
                                <Button :rounded="'medium'" :size="'small'" v-text="'產生報表'" @click="Table_4_Filter_2" :disabled="generate_report_button"></Button>
                                <Input :style="{ margin:'2px',padding:'0px', overflow: 'hidden',flex: 'nowrap' }" :size="'small'" :icon-name="'k-icon k-i-zoom'" v-model="table_4_search_word"></Input>
                                <Button :rounded="'medium'" :size="'small'" v-text="'搜尋'" @click="Table_4_Filter_3"></Button>
                                <Button :rounded="'medium'" :size="'small'" v-text="'全部'" @click="Table_4_Filter_4"></Button>
                            </div>
                            <div>
                                <Checkbox :style="{ margin:'2px', padding: '2px 4px' }" :default-checked="false" @click="Table_4_Filter_5"></Checkbox>
                                <DropDownList
                                    :style="{ width: 'auto', margin:'2px' }"
                                    :data-items="table_4_columns_dropdownlist"
                                    :text-field="'text'"
                                    :data-item-key="'id'"
                                    v-model="table_4_columns_dropdownlist_value"
                                >
                                </DropDownList>
                                <DropDownList
                                    :style="{ width: 'auto', margin:'2px' }"
                                    :data-items="table_4_inequality_dropdownlist"
                                    :text-field="'text'"
                                    :data-item-key="'id'"
                                    v-model="table_4_inequality_dropdownlist_value"
                                >
                                </DropDownList>
                                <Input :style="{ margin:'2px', padding:'0px' }" :size="'small'" v-model.number="table_4_filter_1_value"></Input>
                            </div>
                            <div>
                                <Checkbox :style="{ margin:'2px', padding: '2px 4px' }" :default-checked="false" @click="Table_4_Filter_6"></Checkbox>
                                <DropDownList
                                    :style="{ width: 'auto', margin:'2px' }"
                                    :data-items="table_4_sort_dropdownlist"
                                    :text-field="'text'"
                                    :data-item-key="'id'"
                                    v-model="table_4_sort_dropdownlist_value"
                                >
                                </DropDownList>
                                <Button :rounded="'medium'" :size="'small'" v-text="'更新'" @click="Table_4_Filter_7"></Button>
                            </div>
                            <div>
                                <div class="borader" v-text="'篩選出' + table_4_filter_calculation +'筆'"></div>
                                <Button :rounded="'medium'" :size="'small'" v-text="'不顯示設定'" @click="Table_4_Filter_8"></Button>
                            </div>
                            
                        </GridToolbar>
                    </Grid>
                </TabStripTab>
                 <TabStripTab 
                    id="TabStripTab" 
                    class="tabstriptab" 
                    titleRender="title"
                    >
                    <template v-slot:title>
                        <span>
                            <span class="tabstriptab_font" v-text="tabstrip_selected_title[4]"></span>
                        </span>
                    </template>
                    <Grid 
                        ref="grid"
                        :style="{height:gridhight +'px', width:gridwidth +'px'}"
                        :data-items="gains_and_losses_example_data"
                        :column-virtualization="true"
                        :reorderable="true"
                        :columns = "yl_report_columns"
                        >
                        <GridToolbar class="gridtoolbar">
                        </GridToolbar>
                    </Grid>
                </TabStripTab>
            </TabStrip>
        </div>
    </body>    
</template>
<script>
import Store from '@/store/index.js'
import { Button, ButtonGroup } from '@progress/kendo-vue-buttons';
import { DatePicker } from '@progress/kendo-vue-dateinputs';
import { isEqualDate, Day, prevDayOfWeek, nextDayOfWeek, firstDayOfMonth, lastDayOfMonth, addMonths, addWeeks, addDays } from '@progress/kendo-date-math';
import { TabStrip, TabStripTab } from '@progress/kendo-vue-layout';
import dateFormat, { masks } from "dateformat";
import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
import { Checkbox, Input } from '@progress/kendo-vue-inputs';
import { DropDownList } from '@progress/kendo-vue-dropdowns';
export default {
    name:"Classification_Form",
    components: {
        Grid,
        GridToolbar,
        Button,
        DatePicker,
        TabStrip,
        TabStripTab,
        Input,
        Checkbox,
        DropDownList,
    },
    watch: {

    },
    mounted(){

        const myObserver = new ResizeObserver(entries => {
            this.gridhight=document.getElementById('TabStrip').getElementsByTagName('div')[1].clientHeight-40;
            this.gridwidth=document.getElementById('TabStrip').getElementsByTagName('div')[1].clientWidth-34;
            //console.log(entries);
            //console.log(document.getElementById('TabStrip').getElementsByTagName('div')[1].offsetHeight)
            //console.log(document.getElementById('TabStrip').getElementsByTagName('div')[1].offsetHeight-34)
            //console.log(document.getElementById('TabStrip').clientWidth)
            //console.log(this.gridhight)
            //console.log(this.gridwidth)
        });
        const TabStrip = document.getElementById('TabStrip');
        myObserver.observe(TabStrip, {
            box: "content-box",
        });
        this.table_4_columns_dropdownlist_value = this.table_4_columns_dropdownlist[0];
        this.table_4_inequality_dropdownlist_value = this.table_4_inequality_dropdownlist[0]
        this.table_4_sort_dropdownlist_value = this.table_4_sort_dropdownlist[0]
    },
    data: function() {
        return {


            tabstrip_selected: 0,
            tabstrip_selected_title:["通路損益表","通貨租金表","期貨會員損益表","全部對帳表","YL報表"],
            datepicker_start:new Date(),
            datepicker_end:new Date(),
            date_start:dateFormat(new Date(), "yyyy/mm/dd"),
            date_end:dateFormat(new Date(), "yyyy/mm/dd"),
            current_month:dateFormat(new Date(), "m"),
            one_months_ago:dateFormat(addMonths(new Date(), -1), "m"),
            two_months_ago:dateFormat(addMonths(new Date(), -2), "m"),
            search_date_text:"",
           
            table_3_search_word:"",
            table_3_filter_calculation:0,
            table_3_filter_1_value:"",

            table_4_search_word:"",
            table_4_columns_dropdownlist_value:"",
            table_4_columns_dropdownlist:[
                { text: '帳戶餘額', id: 1 },
                { text: '今日損益', id: 2 },
                { text: '總口數', id: 3 },
                { text: '留倉預扣', id: 4 },
                { text: '對匯額度', id: 5 },
                { text: '交收', id: 6 },
            ],
            table_4_inequality_dropdownlist_value:"",
            table_4_inequality_dropdownlist:[
                { text: '>', id: 1 },
                { text: '=', id: 2 },
                { text: '<', id: 3 },
            ],
            table_4_sort_dropdownlist_value:"",
            table_4_sort_dropdownlist:[
                { text: '由大到小', id: 1 },
                { text: '由小到大', id: 2 },
            ],
            table_4_filter_1_value : 0,
            table_4_filter_calculation:0,


            generate_report_button:true,

            gridhight:"",
            gridwidth:"",
            path_income_columns:[
                { field: 'zero', title: ' ', width: '40px', locked: true},
                { field: 'a', title: '帳號', width: '100%', locked: true},
                { field: 'b', title: '代理', width: '100%', locked: true},
                { field: 'c', title: '類型', width: '55px', locked: true},
                { field: 'd', title: '佔成', width: '100%'},
                { field: 'e', title: '會員收益', width: '100%'},
                { field: 'f', title: '手續費', width: '100%'},
                { field: 'g', title: '費用', width: '100%'},
                { field: 'h', title: '總口數', width: '100%'},
                { field: 'i', title: '手續費口數', width: '100%'},
                { field: 'j', title: '下線交收', width: '100%'},
                { field: 'k', title: '上限交收', width: '100%'},
                { field: 'l', title: '淨利', width: '100%'},
            ],
            rent_columns:[
                { field: 'zero', title: ' ', width: '40px', locked: true},
                { field: 'a', title: '帳號', width: '100%', locked: true},
                { field: 'b', title: '代理', width: '100%', locked: true},
                { field: 'c', title: '類型', width: '55px', locked: true},
                { field: 'd', title: '小於1口數', width: '100%'},
                { field: 'e', title: '大於1口數', width: '100%'},
                { field: 'f', title: '租金', width: '100%'},
                { field: 'g', title: '租金(低於1口)', width: '100%'},
                { field: 'h', title: '上線收租', width: '100%'},
                { field: 'i', title: '下線交租', width: '100%'},
            ],
            member_income_columns:[
                { field: 'zero', title: ' ', width: '40px', locked: true},
                { field: 'a', title: '帳號', width: '100%', locked: true},
                { field: 'b', title: '代理', width: '100%', locked: true},
                { field: 'c', title: '類型', width: '55px', locked: true},
                { field: 'd', title: '會員總輸贏', width: '100%'},
                { field: 'e', title: '總多', width: '100%'},
                { field: 'f', title: '總空', width: '100%'},
                { field: 'g', title: '總口數', width: '100%'},
                { field: 'h', title: '手續費口數', width: '100%'},
                { field: 'i', title: '手續費', width: '100%'},
                { field: 'j', title: '費用', width: '100%'},
                { field: 'k', title: '上限交收', width: '100%'},
                { field: 'l', title: '淨利', width: '100%'},
            ],
            all_reconciliation_columns:[
                { field: 'zero', title: ' ', width: '40px', locked: true},
                { field: 'a', title: '操作日期', width: '100%', locked: true},
                { field: 'b', title: '帳號', width: '100%', locked: true},
                { field: 'c', title: '代理', width: '100%', locked: true},
                { field: 'd', title: '帳戶餘額', width: '100%'},
                { field: 'e', title: '今日損益', width: '100%'},
                { field: 'f', title: '總口數', width: '100%'},
                { field: 'g', title: '留倉預扣', width: '100%'},
                { field: 'h', title: '對匯額度', width: '100%'},
                { field: 'i', title: '交收', width: '100%'},
            ],
            yl_report_columns:[
                { field: 'zero', title: ' ', width: '40px', locked: true},
                { field: 'a', title: '帳號', width: '100%', locked: true},
                { field: 'b', title: '代理', width: '100%', locked: true},
                { field: 'c', title: '類型', width: '100%', locked: true},
                { field: 'd', title: '開戶數', width: '100%'},
                { field: 'e', title: '投注口數', width: '100%'},
                { field: 'f', title: '費用', width: '100%'},
                { field: 'g', title: '活躍數', width: '100%'},
                { field: 'h', title: 'YL', width: '100%'},
            ],
            gains_and_losses_example_data:[{
                "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
            },{
                "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
            },{
                "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
            },{
                "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
            },{
                "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
            },{
                "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
            },{
                "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
            },{
                "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
            },{
                "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
            },{
                "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
            },{
                "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
            },{
                "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
            },{
                "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
            },{
                "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
            },{
                "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
            },{
                "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
            },{
                "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
            },{
                "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
            },{
                "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
            },{
                "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
            },

            ],

        };
    },
    computed: {
       
    },
    methods: {
        Table_3_Search(){

        },
        Table_3_Filter_1(){
            /*this.active_player*/
            /*Do some things*/
        },
        Table_3_Filter_2(){

        },
        Table_3_Filter_3(){

        },
        Table_3_Filter_4(){

        },
        Table_4_Filter_1(){
            /*this.active_player*/
            /*Do some things*/
        },
        Table_4_Filter_2(){
            /*this.active_player*/
            /*Do some things*/
        },
        Table_4_Filter_3(){
            /*this.active_player*/
            /*Do some things*/
        },
        Table_4_Filter_4(){
            /*this.active_player*/
            /*Do some things*/
        },
        Table_4_Filter_5(){
            /*this.active_player*/
            /*Do some things*/
        },
        Table_4_Filter_6(){
            /*this.active_player*/
            /*Do some things*/
        },
        Table_4_Filter_7(){
            /*this.active_player*/
            /*Do some things*/
        },
        Table_4_Filter_8(){
            /*this.active_player*/
            /*Do some things*/
        },
        
        OnChange_Date(event, key) {
            console.log(event.value)
            //補一段開始時間大於結束時間       修正為當天日期
            //補一段結束時間小於開始時間       修正為當天日期
            //補一段開始,結束時間大於當天日期  修正為當天日期
            switch (key) {
                case 'start': {
                    console.log("start")
                    this.date_start = dateFormat(event.value, "yyyy/mm/dd");
                    this.datepicker_start = event.value;
                    break; 
                }
                case 'end': {
                    console.log("end")
                    this.date_end = dateFormat(event.value, "yyyy/mm/dd")
                    this.datepicker_end = event.value;
                    break; 
                }
                default: {
                    break;
                }
            }
            console.log(this.date_start)
            console.log(this.date_end)
        },
        TabStrip_OnSelect(e){
            //console.log(e)
            this.tabstrip_selected = e.selected;
        },
        Date_Search(event){
            switch (event) {
                case 'search': {
                    console.log("search")
                    break; 
                }
                case 'today': {
                    this.datepicker_start = new Date(),
                    this.datepicker_end = new Date(),
                    this.date_start = dateFormat(new Date(), "yyyy/mm/dd")
                    this.date_end = dateFormat(new Date(), "yyyy/mm/dd")
                    break;
                }
                case 'yesterday': {
                    this.datepicker_start = addDays(new Date(), -1),
                    this.datepicker_end = addDays(new Date(), -1),
                    this.date_start = dateFormat(addDays(new Date(), -1), "yyyy/mm/dd")
                    this.date_end = dateFormat(addDays(new Date(), -1), "yyyy/mm/dd")
                    break;
                }
                case 'current_week': {
                    this.datepicker_start = nextDayOfWeek(new Date(), Day.Monday),
                    this.date_start = dateFormat(nextDayOfWeek(new Date(), Day.Monday), "yyyy/mm/dd")

                    if(isEqualDate(new Date(), nextDayOfWeek(new Date(), Day.Sunday)))
                    {
                        this.datepicker_end = nextDayOfWeek(new Date(), Day.Sunday),
                        this.date_end = dateFormat(nextDayOfWeek(new Date(), Day.Sunday), "yyyy/mm/dd")
                    }
                    else{
                        this.datepicker_end = new Date(),
                        this.date_end = dateFormat(new Date(), "yyyy/mm/dd")
                    };
                    break;
                }
                case 'one_week_ago': {
                    console.log(event)
                    this.datepicker_start = addDays(prevDayOfWeek(new Date(), Day.Sunday), -6)
                    this.datepicker_end = prevDayOfWeek(new Date(), Day.Monday),
                    this.date_start = dateFormat(addDays(prevDayOfWeek(new Date(), Day.Sunday), -6), "yyyy/mm/dd")
                    this.date_end = dateFormat(prevDayOfWeek(new Date(), Day.Monday), "yyyy/mm/dd")
                    break;
                }
                case 'current_month': {
                    this.datepicker_start = firstDayOfMonth(new Date()),
                    this.date_start = dateFormat(firstDayOfMonth(new Date()), "yyyy/mm/dd")
                    if(isEqualDate(new Date(), lastDayOfMonth(new Date())))
                    {
                        this.datepicker_end = lastDayOfMonth(new Date()),
                        this.date_end = dateFormat(lastDayOfMonth(new Date()), "yyyy/mm/dd")
                    }
                    else{
                        this.datepicker_end = new Date(),
                        this.date_end = dateFormat(new Date(), "yyyy/mm/dd")
                    };
                    break;
                }
                case 'one_months_ago': {
                    this.datepicker_start = firstDayOfMonth(addMonths(new Date(), -1)),
                    this.datepicker_end = lastDayOfMonth(addMonths(new Date(), -1)),
                    this.date_start = dateFormat(firstDayOfMonth(addMonths(new Date(), -1)), "yyyy/mm/dd")
                    this.date_end = dateFormat(lastDayOfMonth(addMonths(new Date(), -1)), "yyyy/mm/dd")
                    break;
                }
                case 'two_months_ago': {
                    this.datepicker_start = firstDayOfMonth(addMonths(new Date(), -2)),
                    this.datepicker_end = lastDayOfMonth(addMonths(new Date(), -2)),
                    this.date_start = dateFormat(firstDayOfMonth(addMonths(new Date(), -2)), "yyyy/mm/dd")
                    this.date_end = dateFormat(lastDayOfMonth(addMonths(new Date(), -2)), "yyyy/mm/dd")
                    break;
                }
                default: {
                    break;
                }
            }
            this.search_date_text= "日期:"+this.date_start+"~"+this.date_end;
        },
    }
}
</script>
<style scoped>
.body{
    height:100%; 
    margin: 0px;
    font-size: 16px;
    display: inline-flex;
    flex-direction: column;
    font-family:"微軟正黑體";
    }
.classificationtoper{
    height: auto;
    width: 100%;

    display: inline-flex;
    overflow:hidden;
    color:#94dbff;
    padding: 0px 13px;
    color: #fff;
    background-color: #0c4d70;
    align-items: center;
    line-height: 40px;
    flex-wrap: nowrap;
    white-space: nowrap;
}
.classificationcenter{
    flex:1;
    border: 0px;
    background-color: rgb(0, 0, 0);
    display: flex;
    flex-direction: column;
    position: static;
}
.myfloatleft{
    width:100%;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
}
.myfloatleft div{
    margin-right: 3px;
}
.myfloatright{
    width:auto;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    white-space: nowrap;
}
.gridtoolbar{
    height: auto;
    padding: 0px 5px;
    background-color: #0c4d70;
    color: #fff;
    display: inline-flex;
    flex-direction:row;
    
}
.gridtoolbar div{
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.borader{
    background-color: #198754;
    border-radius:2px;
    padding: 2px 5px;
    margin: 3px;
    overflow: hidden;
    white-space: nowrap;
    flex-wrap: nowrap;
    vertical-align: middle;
}
.vrule{
    font-size:16px;
    margin:0 5px;
    border-left:2px ridge #298ab5
}
Button{
    height:100%;
    width:auto;
    color: #fff;
    background-color: #17a2b8;
    padding: 2px 5px;
    margin: 3px;
}
.tabstrip{
    display: inline-flex;
    flex:1;
    width:100%;
    padding: 10px 10px 10px 10px;
    background-color: #fff;
    color: #fff;
}
.tabstriptab{
    background-color: #17a2b8;
}
.tabstriptab_font{
    color: #008cba;
}

.glay{
    color: #a7a7a7;
    padding: 0px 4px;
}
.k-input {
    font-size: 10px;
}
.k-link {
    color:#fff;
}

.k-animation-container {min-height:100%;}
.k-animation-container-relative {min-height:100%;}
.k-fade-enter-active{min-height:100%;}
</style>