<template>
    <body id = 'Setting_Member_Status_Dialog'>
        <div style="display:flex; height:100%; flex-direction: column;">
            <div style="flex:1;">
                <RadioGroup style="font-size:16px;" :layout="'horizontal'" :data-items="status_setting_radiogroup" :default-value="select_row_data['正常']" />
                <Grid
                    :data-items="status_member_dataitem"
                    :column-virtualization="false"
                    :columns = "status_member_columns"
                    :enableRtl="false"
                    :resizable="false" 
                    >
                <template v-slot:head_mAccountName="{props}">
                    <div style="text-align: center; font-size:16px;" >
                        <Checkbox :label="props.title"/>
                    </div>
                </template>
                <template v-slot:mAccountName="{props}">
                    <td style="width:100%; padding:0px; text-align: center; flex-wrap: nowrap; position:sticky; z-index:2; background-color: #FFFF;">
                        <Checkbox :label="props.dataItem[props.field]"/>
                    </td>
                </template>

                <template v-slot:head_aStatus="{props}">
                    <div 
                        style="text-align: center; font-size:16px;" 
                        v-text="props.title">
                    </div>
                </template>
                <template v-slot:aStatus="{props}">
                    <td style="width:100%; padding:0px; text-align: center; flex-wrap: nowrap; position:sticky; z-index:2; background-color: #FFFF;">
                        <span style="color:#0053ff;" v-text="props.dataItem[props.field]"></span>
                    </td>
                </template>
                </Grid>
            </div>
            <div style="height:25px; justify-content:flex-end; align-items:flex-end; align-content:flex-end; margin:10px 0px;">
                <span style="text-align:left; float:left; width:100%; background-color: #fff3cd; border-radius:4px; color:#856404; padding:3px;">
                    *不刪除所有單子，不市價平倉所有單子
                </span>
            </div>
        </div>
    </body>
</template>
<script>
import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
import { Button, ButtonGroup } from '@progress/kendo-vue-buttons';
import { Checkbox, Input, RadioGroup, TextArea } from '@progress/kendo-vue-inputs';
export default {
    name: 'Setting_Member_Status_Dialog', 
      components: {
        Grid, 
        RadioGroup,
        Checkbox,

    },   
    props: {
        select_row_data:{},
    }, 
    data: function () {
        return {
            status_setting_radiogroup_checked:"",
            status_setting_radiogroup:[
                {
                    label: "正常",
                    value: "正常",
                },
                {
                    label: "禁新",
                    value: "禁新",
                },
                {
                    label: "凍結",
                    value: "凍結",
                },
                {
                    label: "停用",
                    value: "停用",
                },
            ],
            status_member_dataitem:[],
            status_member_columns:[
                { field: 'mAccountName', cell:'mAccountName', headerCell : 'head_mAccountName', title: '帳號', width: '200px'},
                { field: 'aStatus', cell:'aStatus', headerCell : 'head_aStatus', title: '狀態', width: '100px'},
            ],
            
        }
    },
    mounted(){
        
    },
    methods: {
       
    },
};
</script>
<style scoped>
body{
    height : 100%; 
    margin : -16px;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    padding:16px;
    padding-bottom: 0px;
}
.gridtoolbar{
    height: 80px;
    width:100%;
    padding: 0px 0px;
    border: 0px;
    background-color: #0c4d70;
    color: #fff;
    display: inline-flex;
    
}
.bar{
    flex :1;
    width:100%;
    display: inline-flex;
    flex-direction: column;
}
.center{
    flex :1;
    width:100%;
    padding: 0px 0px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
}
.DatePicker
{
    margin:3px; 
    font-size:12px; 
    width:120px;
}
.DatePicker > span
{
    margin:0px; 
    
}
Button{
    height:100%;
    width:auto;
    color: #fff;
    background-color: #17a2b8;
    padding: 2px 5px;
    margin: 3px;
}
</style>
