<template>
    <body id='Add_Agent_Dialog'>
        <div style="display:flex;height:35px; width:100%; margin:5px 0px;align-items:center; justify-content:flex-start;">
            <div style="justify-content:flex-end;" v-text="'姓名'"></div>
            <Input style="margin-left:34px; font-size:16px; border-radius:4px; border:1px solid #ced4da;"
                :style="{ width: '268px', height: '33px' }" v-model="Class_AccountManagement.nickName"
                :placeholder="'中英文皆可'" />
        </div>
        <div style="display:flex;height:35px; width:100%; margin:5px 0px;align-items:center; justify-content:flex-start;">
            <div style="justify-content:flex-end;white-space: nowrap;" v-text="'帳號'"></div>
            <div style="display:flex; justify-content:space-end; margin-left:34px;">
                <div style="display:flex; padding:0px 10px; background-color:#e9ecef; align-items:center; font-weight:bold; font-size:16px; border-radius:4px 0px 0px 4px; color:blue; border:1px solid #ced4da;"
                    :style="{ width: '108px', height: '33px' }" v-text="''" />
                <Input style="font-size:16px; border-radius:0px 4px 4px 0px; border:1px solid #ced4da;"
                    :style="{ width: '160px', height: '33px' }" v-model="Class_AccountManagement.aAccountName"
                    :placeholder="'請輸入數字或英文'" />
                <Button style="width:76px;height:30px;" :rounded="'medium'" v-text="'檢查帳號'" @click="check_user('1')"></Button>
            </div>
        </div>
        <div style="display:flex;height:35px; width:100%; margin:5px 0px;align-items:center; justify-content:flex-start;">
            <div style="justify-content:flex-end;" v-text="'Email'"></div>
            <Input style=" margin-left:34px; font-size:16px; border-radius:4px; border:1px solid #ced4da;"
                v-model="Class_AccountManagement.aEmail" :style="{ width: '268px', height: '33px' }" />
                <Button style="width:76px;height:30px;" :rounded="'medium'" v-text="'檢查Email'" @click="check_user('2')"></Button>
        </div>

        <Dialog v-if="message_visible" :width="'300px'" :height="'125px'" :title="'訊息'" @close="Message_Button(null)">
            <div style="height:100%; width:100%; display: inline-flex; align-items: center; justify-content: center;">
                <div style="font-size:16px; font-weight: bold;" v-text="message" />
            </div>
        </Dialog>
    </body>
</template>
<script>
import { Button, ButtonGroup } from '@progress/kendo-vue-buttons';
import { Checkbox, Input, RadioGroup, TextArea } from '@progress/kendo-vue-inputs';
import { Window, Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
import axios from 'axios';
export default {
    name: 'Add_This_Member_Dialog', 
      components: {
        Button,
        Input,
        Dialog,
    },   
    props: {
        select_row_data:{},
    }, 
    watch:{
        Class_AccountManagement: {
            handler(val, oldVal) {
                // console.log(this.Class_AccountManagement)
                this.$emit('AccountManagement', this.Class_AccountManagement)
            },
            deep: true
        },
    },
    data: function () {
        return {
            message:'',
            message_visible:false,
            Class_AccountManagement: {
                aAgentGuid: '',
                aAccountName: '',
                aEmail: "",
                isChild: "",
                nickName: "",
                lastDefaultPasswd: "",
                agentPath: "",
            },

        }
    },
    mounted(){
        
    },
    methods: {
        Message_Button() {
            this.message_visible = !this.message_visible;
            this.message = '';
        },
        check_user(c) {
            var search = ''
            if(c=='1'){
                search = this.Class_AccountManagement.aAccountName
            }
            else if(c=='2'){
                search = this.Class_AccountManagement.aEmail
            }
            axios.get(this.$httpPath + '/AgentManager/vipAccount/'+c+'?search=' + search)
                .then((response) => {
                    if (response.status == 200) {
                        this.message = response.data == 0 ? "可使用" : "請換一個";
                        this.message_visible = true;
                    }
                })
                .catch((error) => {
                    this.message = "發生錯誤"
                }).finally(() => {
                });
        }
    },
};
</script>
<style scoped>
body{
    height:100%; 
    margin : -16px;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    padding:16px;
}
.gridtoolbar{
    height: 40px;
    width:100%;
    padding: 0px 0px;
    border: 0px;
    background-color: #0c4d70;
    color: #fff;
    display: inline-flex;
    
}
.bar{
    flex :1;
    width:100%;
    display: inline-flex;
    flex-direction: column;
}
.center{
    flex :1;
    width:100%;
    padding: 0px 0px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
}
.DatePicker
{
    margin:3px; 
    font-size:12px; 
    width:120px;
}
.DatePicker > span
{
    margin:0px; 
    
}
Button{
    height:100%;
    width:auto;
    color: #fff;
    background-color: #17a2b8;
    padding: 2px 5px;
    margin: 3px;
}
</style>
