<template>
    <body id ="Transaction_Return" class="body">
        <Grid 
            ref="grid"
            :style="{height: gridhight+'px', width: gridwidth+'px'}"
            :data-items="example_data"
            :column-virtualization="true"
            :columns = "columns"
            >
            <GridToolbar class="gridtoolbar">
                <div class="myfloatleft">
                    <span v-text="'商品'"></span>
                    <span >&nbsp;</span>
                    <DropDownList
                        :size="'small'"
                        :style="{ width: 'auto', margin:'2px' }"
                        :data-items="dropdownlist"
                        :text-field="'text'"
                        :data-item-key="'id'"
                        v-model="dropdownlist_value"
                    >
                    </DropDownList>
                </div>
                <div class="myfloatright">
                    <Button size="small" v-text="'EXCEL'"></Button>
                </div>
            </GridToolbar>
        </Grid>
    </body>
</template>
<script>
import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
import { Button, ButtonGroup } from '@progress/kendo-vue-buttons';
import { DropDownList } from '@progress/kendo-vue-dropdowns';
export default {
    name: 'Transaction_Return', /*成交回報 */
      components: {
        Grid,
        GridToolbar,
        Button,
        DropDownList,
    },   
    data: function () {
        return {
            gridhight:"",
            gridwidth:"",
            datepicker_start:"",
            
            dropdownlist_value:"",
            dropdownlist:[
                { text: '這是要倒入商品名稱', id: 1 },
                { text: '這是要倒入商品名稱', id: 2 },
                { text: '這是要倒入商品名稱', id: 3 },
                { text: '這是要倒入商品名稱', id: 4 },
                { text: '這是要倒入商品名稱', id: 5 },
                { text: '這是要倒入商品名稱', id: 6 },
                { text: '這是要倒入商品名稱', id: 7 },
                { text: '這是要倒入商品名稱', id: 8 },
            ],
            columns:[
                { field: 'a', title: '會員', width: '100%', locked: true},
                { field: 'b', title: '序號', width: '100%', locked: true},
                { field: 'c', title: '商品', width: '100%', locked: true},
                { field: 'd', title: '倒', width: '100%'},
                { field: 'e', title: '多空', width: '100%'},
                { field: 'f', title: '委託價', width: '100%'},
                { field: 'g', title: '口數', width: '100%'},
                { field: 'h', title: '成交價', width: '100%'},
                { field: 'i', title: '下單時間', width: '100%'},
                { field: 'j', title: '完成時間', width: '100%'},
                { field: 'k', title: '型別', width: '100%'},
                { field: 'l', title: '損失點', width: '100%'},
                { field: 'm', title: '獲利點', width: '100%'},
                { field: 'n', title: '狀態', width: '100%'},
            ],
            example_data:[{
                "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
                "m":"範例",
                "n":"範例",
            },
            ],
        }
    },
    mounted(){
        this.dropdownlist_value = this.dropdownlist[0];
        const myObserver = new ResizeObserver(entries => {
            this.gridhight=document.getElementById('TabStrip').getElementsByTagName('div')[1].clientHeight-40;
            this.gridwidth=document.getElementById('TabStrip').getElementsByTagName('div')[1].clientWidth-33;
            console.log(document.getElementById('TabStrip').getElementsByTagName('div')[1].offsetHeight)
            console.log(this.gridwidth)
        });
        const TabStrip = document.getElementById('TabStrip');
        myObserver.observe(TabStrip, {
            box: "content-box",
        });
    },
    methods: {

    }
};
</script>
<style scoped>
.body{
    height:100%;
    width:100%;
    display: inline-flex;
    color:#fff;
    font-family:"微軟正黑體";
    flex-direction: row;
    flex-wrap: nowrap;
}
.gridtoolbar{
    height: auto;
    padding: 0px 5px;
    background-color: #0c4d70;
    color: #fff;
    display: inline-flex;
    flex-direction:row  
}
.myfloatleft{
    flex:1;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
}
.myfloatright{
    width:auto;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
}
.borader{
    display: inline-flex;
    background-color: #198754;
    border-radius:4px;
    overflow: hidden;
    justify-content: center;
    color: #fff;
    margin: 3px;
    border: 1px;
    padding: 3px 6px;
    vertical-align: middle;
}
Button{
    height:100%;
    width:auto;
    color: #fff;
    background-color: #17a2b8;
    padding: 2px 5px;
    margin: 3px;
}
</style>
