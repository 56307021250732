<template>
  <body>
    <component 
      id="myLogin"
      v-bind:is="Login" 
      v-on:Login_Click="Login_Click"
      v-if="this.$store.state.isAuthenticated === false">
    </component>
    <div v-else id="mymain"  class="mymain">
      <div class="toper">
        <MenueBox></MenueBox>
      </div>
      <Splitter
        class="center"
        :panes="firsthorizontalpanes"
        :orientation="'horizontal'"
        @change="onFirstHorizontalChange"
      >
        <template v-slot:L_Splitter>
          <Splitter
            id="L_Splitter"
            style="height:100%;"
            :orientation="'vertical'"
            @change="onSecondVerticalChange"
            :panes="secondverticalpanes"
          >
            <template v-slot:top>
              <AgentListPane></AgentListPane>
            </template>
            <template v-slot:bottom>
              <MemberProfile></MemberProfile>
            </template>
          </Splitter>
        </template>
        <template v-slot:R_Splitter>
          <Group></Group>
        </template>
      </Splitter>
      <div class="footer">
        <BottomBar></BottomBar>
      </div>
    </div>
  </body>
</template>
<script>
  import MenueBox from '@/components/MenueBox.vue';
  import BottomBar from '@/components/BottomBar';
  import Group from '@/components/Group';
  import AgentListPane from '@/components/AgentListPane';
  import MemberProfile from '@/components/MemberProfilePane';
  import Login from '@/components/Login.vue';


  import { LayLayer } from "@layui/layer-vue";
  import {Monitorin_MQ_Initialization_Connect, User_MQ_Initialization_Connect, MQ_DisConnect} from '@/store/RabbitMQ.js';
  import { Window, Dialog } from '@progress/kendo-vue-dialogs';
  import { Button } from '@progress/kendo-vue-buttons';
  import { Input } from '@progress/kendo-vue-inputs';
  import 'vue-toast-notification/dist/theme-sugar.css';
  import '@progress/kendo-ui'
  import '@progress/kendo-theme-default/dist/all.css'
  import '@progress/kendo-ui/js/messages/kendo.messages.zh-TW.js'
  import { Splitter } from '@progress/kendo-vue-layout';
  import {Start_Connect} from '@/store/SignalRHub.js'
  export default {
    components: {
      LayLayer,
      Button,
      MenueBox,
      BottomBar,
      Splitter,
      AgentListPane,
      MemberProfile,
      Group, 
    }, 
    computed: {
        isAuthenticated(){
           //console.log( this.$store.state.isAuthenticated);
           if(this.$store.state.isAuthenticated == true )
           {
            //Start_Connect();
           }
          return this.$store.state.isAuthenticated;
        },
        
        agentlistpane_width(){
            return this.$store.state.agentlistpane_width;
        },
        
        
    },
    watch: {
        
        agentlistpane_width(){
          //console.log(this.$store.state.agentlistpane_width);
          //document.getElementById('L_Splitter').style.width=this.$store.state.agentlistpane_width.min*document.getElementsByTagName('body')[0].offsetWidth + "px";
          //console.log(this.$store.state.agentlistpane_width.min);
          //console.log(document.getElementsByTagName('body')[0].offsetWidth);
          if(this.$store.state.agentlistpane_width.OnOff == false)
          {
            console.log(this.$store.state.agentlistpane_width.min);
            this.firsthorizontalpanes[0].size = this.$store.state.agentlistpane_width.min*100 + "%";
          }
          if(this.$store.state.agentlistpane_width.OnOff == true)
          {
            console.log(this.$store.state.agentlistpane_width.min);
            this.firsthorizontalpanes[0].size = this.$store.state.agentlistpane_width.min*100 + "%";
          }
          //this.firsthorizontalpanes[0].size = this.$store.state.agentlistpane_width.min + "%";
          this.firsthorizontalpanes[0].min = this.$store.state.agentlistpane_width.min + "%";
          this.firsthorizontalpanes[0].max = this.$store.state.agentlistpane_width.max + "%";
          this.agentlistpane_w = this.$store.state.agentlistpane_width;
        },
        
        isAuthenticated(){
          this.login_click = this.$store.state.isAuthenticated;
          //console.log(this.login_click);
        }
    },  
    data: function () {
      return {
        Login:Login,
        login_click:false,
        w_visible:false,
        titleRender: 'myTemplate',
        agentlistpane_w:{
          OnOff:true,
          min:10,
          max:1,
        },
        agentlistpane_s:{},
        agentlistpane_w_min:'80%',
        firsthorizontalpanes: [
        { content: 'L_Splitter', size: "13%" ,min: "", max:'80%'},
        { content: 'R_Splitter', min: "20%"},
        ],
        secondverticalpanes: [
        { content: 'top', size: '50%', min: '5%', scrollable: false,},
        { content: 'bottom', scrollable: true,},
        ],
      }
    },  
    created() {
      //Monitorin_MQ_Initialization_Connect();
      //console.log("訂閱")
      //Monitorin_MQ_Subscribe("TX00");
      //window.addEventListener("resize", this.windowResize);
    },  
    destroyed() {
      //window.removeEventListener("resize", this.windowResize);
      //MQ_DisConnect();
    }, 
    mounted(){
    },
    methods: {
      Login_Click(){
        //this.login_click = true;
        Monitorin_MQ_Initialization_Connect();
      },
      onFirstHorizontalChange(event) {
        var storage_event_newState = event.newState
        //console.log(event)
        var size=storage_event_newState[0].size.replace("%","");
        //console.log(storage_event_newState)
        if(size < this.agentlistpane_w.min*100)
        {
          storage_event_newState[0].size= this.agentlistpane_w.min*100 + "%";
        }
        if(size > this.agentlistpane_w.max*100)
        {
          storage_event_newState[0].size= this.agentlistpane_w.max*100 + "%";
        }
        this.firsthorizontalpanes = storage_event_newState;
        //console.log(document.getElementById('L_Splitter').style.width)
      },
      onSecondVerticalChange(event) {
        //console.log(event.newState)
        this.secondverticalpanes = event.newState;
      },     
    },    
  };
</script>
<style scoped>
  body{
    height:100%; 
    width: 100%;
    margin: 0px;
    border: 0px;
    display: inline-flex;
    flex-direction: column;
    font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
   }
  .mylogin{
    flex:1;
    display: inline-flex;
    flex-direction: column;
    background-color: #073052;
    justify-content: center; 
    align-items: center; 
  }
  .loginbox{
    background:url("../assets/img/board01.png") no-repeat;
    width:614px;height:239px;
  }
  .mymain{
    flex:1;
    display: inline-flex;
    flex-direction: column;
  }
  .toper{
    height: auto;
  }
  .center{
    flex:1;
    border: 0px;
    background-color: rgb(255, 127, 127);
  }
  .footer{
    height: auto;
  }
  .window{
    color: #212529;
    border-bottom-color:rgb(222, 226, 230);
    background-color: rgb(222, 226, 230);
  }
  Button{
    height:100%;
    width:auto;
    color: #fff;
    background-color: #17a2b8;
    padding: 2px 5px;
    margin: 3px;
}
</style>