<template>
    <body id ="Login_Message" class="body">
        <Grid 
            ref="grid"
            :style="{height: gridhight+'px', width: gridwidth+'px'}"
            :data-items="example_data"
            :column-virtualization="true"
            :columns = "columns"
            >
            <GridToolbar class="gridtoolbar">
                <div class="myfloatleft">
                    <Button size="small" v-text="'回補當日紀錄'"></Button>
                    <span >&nbsp;</span>
                    <span >&nbsp;</span>
                    <span style="padding-top:2px; vertical-align: middle; white-space:nowrap;" v-text="'帳號/代理/IP'"></span>
                    <Input :style="{ margin:'2px',padding:'0px', overflow: 'hidden',flex: 'nowrap' }" :size="'small'"></Input>
                    <Button size="small" v-text="'查詢'"></Button>
                    <Button size="small" v-text="'清除'"></Button>

                    <span >&nbsp;</span>
                    <span >&nbsp;</span>
                    <Button
                        :rounded="'large'"
                        :size="'small'"
                        v-for="button in buttons"
                        :togglable="true"
                        :key="button.id"
                        :selected="select_button.id === button.id"
                        @click="ButtonChange(button.id)"
                        v-text="button.name"
                        >
                    </Button> 

                    <Button size="small" v-text="'登入記錄查詢'"></Button>
                </div>
                <div class="myfloatright">
                    <Button size="small" v-text="'EXCEL'"></Button>
                </div>
            </GridToolbar>
        </Grid>
    </body>
</template>
<script>
import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
import { Button, ButtonGroup } from '@progress/kendo-vue-buttons';
import { Checkbox, Input, RadioGroup } from '@progress/kendo-vue-inputs';
export default {
    name: 'Login_Message', /*登入訊息*/
      components: {
        Grid,
        GridToolbar,
        Button,
        Input,
    },   
    data: function () {
        return {
            gridhight:"",
            gridwidth:"",
            datepicker_start:"",

            select_button:{},
            buttons:[
                {
                    id: "member",
                    name: "會員",
                },
                {
                    id: "admin",
                    name: "管理員",
                },
                {
                    id: "IP_address",
                    name: "同IP",
                },
            ],

            columns:[
                { field: 'a', title: '日期', width: '100%'},
                { field: 'b', title: '代理', width: '100%'},
                { field: 'c', title: '帳號', width: '100%'},
                { field: 'd', title: 'IP', width: '100%'},
                { field: 'e', title: '訊息', width: '100%'},
                { field: 'f', title: '版本', width: '100%'},
                { field: 'g', title: '系統', width: '100%'},
                { field: 'h', title: '語系', width: '100%'},
                { field: 'i', title: '風格', width: '100%'},
                { field: 'j', title: '重連', width: '100%'},
            ],
            example_data:[{
                "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
                "m":"範例",
                "n":"範例",
            },
            ],
        }
    },
    mounted(){
        const myObserver = new ResizeObserver(entries => {
            this.gridhight=document.getElementById('TabStrip').getElementsByTagName('div')[1].clientHeight-40;
            this.gridwidth=document.getElementById('TabStrip').getElementsByTagName('div')[1].clientWidth-33;
            console.log(document.getElementById('TabStrip').getElementsByTagName('div')[1].offsetHeight)
            console.log(this.gridwidth)
        });
        const TabStrip = document.getElementById('TabStrip');
        myObserver.observe(TabStrip, {
            box: "content-box",
        });
    },
    methods: {
        ButtonChange(id) {
            this.select_button = this.l_buttons.find(b => b.id == id);
        },
    }
};
</script>
<style scoped>
.body{
    height:100%;
    width:100%;
    display: inline-flex;
    color:#fff;
    font-family:"微軟正黑體";
    flex-direction: row;
    flex-wrap: nowrap;
}
.gridtoolbar{
    height: auto;
    padding: 0px 5px;
    background-color: #0c4d70;
    color: #fff;
    display: inline-flex;
    flex-direction:row  
}
.myfloatleft{
    flex:1;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
}
.myfloatright{
    width:auto;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
}
.borader{
    display: inline-flex;
    background-color: #198754;
    border-radius:4px;
    overflow: hidden;
    justify-content: center;
    color: #fff;
    margin: 3px;
    border: 1px;
    padding: 3px 6px;
    vertical-align: middle;
}
Button{
    height:100%;
    width:auto;
    color: #fff;
    background-color: #17a2b8;
    padding: 2px 5px;
    margin: 3px;
}
</style>
