import Stomp from 'stompjs'
import axios from 'axios';
import store from './index.js';//store import //coconut補充
import Vue from 'vue';
import Vuex from 'vuex';
import * as signalR from '@aspnet/signalr'

var connection = new signalR.HubConnectionBuilder()
                            .withUrl("https://localhost:44344/Management_Hub")
                            .configureLogging(signalR.LogLevel.Debug)
                            .build();

export function Start_SignalR_Connect() {
    console.log("開始訂閱")
    
    connection.start()
                .then(function(){

                    connection.invoke("User_Login", "RICKY")
                                .catch(function (err) {
                                    return console.error(err.toString());
                                });

                    connection.on("Topic", (topic_list) => {
                        //messages.push({ user, message });
                        console.log( topic_list )
                        topic_list.forEach(function(element, index, array){
                            Subscribe(element);
                        });
                    });
                })
                .catch(function(err){
                    // 錯誤處理
                });
    var connectionUrl = connection ;
    //console.log(connectionUrl);
};
export function Subscribe(topic) {
    //console.log(topic);
    connection.on(topic, (message) => {
        //messages.push({ user, message });
        console.log( topic )
        console.log( message )
        store.dispatch(topic+"DataActions", message);
    });
}
export function unSubscribe(topic) {
    connection.on(topic, (message) => {
        //messages.push({ user, message });
        console.log( message )
    });
}
