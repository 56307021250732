import Vue from "vue";
import VueRouter from "vue-router";
//import stock_1 from "@/components/manager/stock_1.vue";
import stock from "@/components/stock.vue";
import addAgent from "@/components/manager/addAgent.vue";
import addClient from "@/components/manager/addClient.vue";

import Login from "@/components/Login.vue";
import '@progress/kendo-ui/js/messages/kendo.messages.zh-TW.js'
Vue.use(VueRouter);

export const routes = [      
  {
    path: "/",
    name: "stock",
    component: stock,    
  },
  {
    path: '/addAgent',
    name: "addAgent",
    component:addAgent,
    //meta: { requiresAuth: true},
    // children:[   
    //   {
    //     path: "/addAgent",
    //     name: "addAgent",
    //     component: addAgent,
    //     //meta: { requiresAuth: true},   
    //   },
    // ]
  },
  {
    path: '/addClient',
    name: "addClient",
    component:addClient,
  }
];

const router = new VueRouter({
  model: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
