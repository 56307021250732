<template>
    <table>
        <tbody class= "menuetoper">
            <th class="myfloatleft">
                <span style="padding-bottom:1px">
                    通路管理系統
                    &emsp;
                    &emsp;
                    &emsp;
                    &emsp;
                </span>
            </th>
            <th class="myfloatright">
                <div>
                    <span class=yellow > {{agent_user_name}}</span>
                    <span class=glay>您好!</span>
                </div>
                <div>
                    <span class=glay>在線管理員</span>
                    :
                    <span class=yellow>{{online_agent}}</span>
                    <span class=glay>人</span>
                </div>
                <div>
                    <span class=glay>在線會員</span>
                    :
                    <span class=yellow>{{online_membership}}</span>
                    <span class=glay>人</span>
                </div>
                <div>
                    <span class=glay>下單人數合計</span>
                    :
                    <span class=yellow >{{order_people}}</span>
                    <span class=glay>人</span>
                </div>
                <div>
                    <span class=yellow id=lblMessage>{{new_message}}</span>
                    <span data-aa=a0001 class=glay>則新訊息</span>
                </div>
                <div>
                    <span class=yellow id=lblWithdrawal>{{withdrawal_balance}}</span>
                    <span data-aa=bk121 class=glay>筆提領餘額</span>
                </div>
                <div style="margin:0px 3px">
                    <span data-bb=h7 class=glay>成交</span>
                    <div style="margin:0px" class="yellow" @click="deal_voice_click">
                        <svg style="margin:1px" width=1.5em height=1.3em viewBox="0 0 14.6 14.6" class="bi bi-volume-down" fill=currentColor xmlns=http://www.w3.org/2000/svg>
                            <path fill-rule=evenodd v-bind:d=deal_voice_svg_1></path>
                            <path fill-rule=evenodd v-bind:d=deal_voice_svg_2></path>
                        </svg>
                    </div>
                </div>
                <div style="margin:0px 3px">
                <span data-bb=b30 class=glay>客服</span>
                    <div style="margin:0px" class="yellow" @click="service_voice_click">
                        <svg style="margin:1px 0px" width=1.5em height=1.3em viewBox="0 0 14.6 14.6" class="bi bi-volume-down" fill=currentColor xmlns=http://www.w3.org/2000/svg>
                            <path fill-rule=evenodd v-bind:d=service_voice_svg_1></path>
                            <path fill-rule=evenodd v-bind:d=service_voice_svg_2></path>
                        </svg>
                    </div>
                </div>
            </th>
        </tbody>
    </table>
</template>
<script>
import Store from '@/store/index.js'
export default {
  components: {
  },
  name: 'MenueBox',    
  data: function () {
    return {
        agent_user_name : "TRS6",
        online_agent:"1/1",
        online_membership:"0/0",
        order_people:"0",
        new_message:"0",
        withdrawal_balance:"0",
        deal_voice:true,
        deal_voice_svg_1:"",
        deal_voice_svg_2:"",
        service_voice:true,
        service_voice_svg_1:"",
        service_voice_svg_2:"",
    }
  },
  created: function(){
    this.deal_voice_svg_1="M8.717 3.55A.5.5 0 0 1 9 4v8a.5.5 0 0 1-.812.39L5.825 10.5H3.5A.5.5 0 0 1 3 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zM8 5.04L6.312 6.39A.5.5 0 0 1 6 6.5H4v3h2a.5.5 0 0 1 .312.11L8 10.96V5.04z";
    this.deal_voice_svg_2="M10.707 11.182A4.486 4.486 0 0 0 12.025 8a4.486 4.486 0 0 0-1.318-3.182L10 5.525A3.489 3.489 0 0 1 11.025 8c0 .966-.392 1.841-1.025 2.475l.707.707z";
    this.service_voice_svg_1="M8.717 3.55A.5.5 0 0 1 9 4v8a.5.5 0 0 1-.812.39L5.825 10.5H3.5A.5.5 0 0 1 3 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zM8 5.04L6.312 6.39A.5.5 0 0 1 6 6.5H4v3h2a.5.5 0 0 1 .312.11L8 10.96V5.04z";
    this.service_voice_svg_2="M10.707 11.182A4.486 4.486 0 0 0 12.025 8a4.486 4.486 0 0 0-1.318-3.182L10 5.525A3.489 3.489 0 0 1 11.025 8c0 .966-.392 1.841-1.025 2.475l.707.707z";
  },
  mounted(){
    this.agent_user_name = this.$store.getters.loggedInUser;
  },
  methods: {
    deal_voice_click(){
        if(this.deal_voice == true){
            console.log("關閉交易聲音");
            this.deal_voice_svg_1="M6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zM6 5.04L4.312 6.39A.5.5 0 0 1 4 6.5H2v3h2a.5.5 0 0 1 .312.11L6 10.96V5.04zm7.854.606a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708l4-4a.5.5 0 0 1 .708 0z";
            this.deal_voice_svg_2="M9.146 5.646a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0z";
            this.deal_voice = false;
        }
        else if(this.deal_voice == false){
            console.log("開啟交易聲音");
            this.deal_voice_svg_1="M8.717 3.55A.5.5 0 0 1 9 4v8a.5.5 0 0 1-.812.39L5.825 10.5H3.5A.5.5 0 0 1 3 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zM8 5.04L6.312 6.39A.5.5 0 0 1 6 6.5H4v3h2a.5.5 0 0 1 .312.11L8 10.96V5.04z";
            this.deal_voice_svg_2="M10.707 11.182A4.486 4.486 0 0 0 12.025 8a4.486 4.486 0 0 0-1.318-3.182L10 5.525A3.489 3.489 0 0 1 11.025 8c0 .966-.392 1.841-1.025 2.475l.707.707z";
            this.deal_voice = true;
        }
    },
    service_voice_click(){
        if(this.service_voice == true){
            console.log("關閉客服聲音");
            this.service_voice_svg_1="M6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zM6 5.04L4.312 6.39A.5.5 0 0 1 4 6.5H2v3h2a.5.5 0 0 1 .312.11L6 10.96V5.04zm7.854.606a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708l4-4a.5.5 0 0 1 .708 0z";
            this.service_voice_svg_2="M9.146 5.646a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0z";
            this.service_voice = false;
        }
        else if(this.service_voice == false){
            console.log("開啟客服聲音");
            this.service_voice_svg_1="M8.717 3.55A.5.5 0 0 1 9 4v8a.5.5 0 0 1-.812.39L5.825 10.5H3.5A.5.5 0 0 1 3 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zM8 5.04L6.312 6.39A.5.5 0 0 1 6 6.5H4v3h2a.5.5 0 0 1 .312.11L8 10.96V5.04z";
            this.service_voice_svg_2="M10.707 11.182A4.486 4.486 0 0 0 12.025 8a4.486 4.486 0 0 0-1.318-3.182L10 5.525A3.489 3.489 0 0 1 11.025 8c0 .966-.392 1.841-1.025 2.475l.707.707z";
            this.service_voice = true;
        }
    },
  }
};
</script>
<style scoped>
.glay{
    color:#a7a7a7
}
.yellow{
    color:#fceda1
}
table{
    height:auto;
    width:100%;
    background:#000;
    color:#fff;
    font-family:"微軟正黑體";
    flex-direction: column;
    font-size:14.6px;
    display: inline-flex;
}
.myfloatleft{
    width: auto;
    align-items: center;
    background-color:#000000;
    font-size:16px;
    font-weight:bold;
}
.myfloatright{
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    text-align: right;    
    
}
.myfloatright div{
    display: inline-flex;
    margin-right: 15px;
}
.menuetoper{
    height:25px;
    display: inline-flex;
    white-space: nowrap;
    overflow:hidden;
    padding-left: 10px;
    padding-right: 10px;
  }
</style>
