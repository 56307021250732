<template>
    <body id="Member_List" class="body">
        <Grid 
            ref="grid"
            :style="{height:'100%', width:'cale(100%-0px)', border:'0px'}"
            :data-items="result"
            :column-virtualization="true"
            :columns = "columns"

            :pageable="true"
            :skip="skip"
            :take="take"
            :total="total"
            >
            <GridToolbar class="gridtoolbar">
                <div class="myfloatleft ">
                    <div >
                        <span style="padding-top:2px;font-size:18px; vertical-align: middle; white-space:nowrap;" v-text="'會員清單'"></span>
                        <Input :style="{ margin:'2px',padding:'0px', overflow: 'hidden',flex: 'nowrap' }" :size="'small'" :icon-name="'k-icon k-i-zoom'"></Input>
                        <Button :rounded="'medium'" :size="'small'" v-text="'搜索'" ></Button>
                        <Button :rounded="'medium'" :size="'small'" v-text="'全部'" ></Button>
                        <RadioGroup :size="'small'"  :data-items="select_layer" ></RadioGroup>
                    </div>
                    <div style="flex-direction: column; align-items:flex-start; ">
                        <div>
                            <checkbox :size="'small'" class="checkbox" :default-checked="false" :label="'在線'" ></checkbox>
                            <checkbox :size="'small'" class="checkbox" :default-checked="false" :label="'未平'" ></checkbox>
                            <checkbox :size="'small'" class="checkbox" :default-checked="false" :label="'禁新'" ></checkbox>
                        </div>
                        <div>
                            <checkbox :size="'small'" class="checkbox" :default-checked="false" :label="'正常'" ></checkbox>
                            <checkbox :size="'small'" class="checkbox" :default-checked="false" :label="'下單'" ></checkbox>
                            <checkbox :size="'small'" class="checkbox" :default-checked="false" :label="'凍結'" ></checkbox>
                        </div>
                        <div>
                            <checkbox :size="'small'" class="checkbox" :default-checked="false" :label="'隱藏從未下過單會員'" @click="Table_3_Filter_4"></checkbox>
                        </div>
                    </div>
                    <div style="display: inline-flex; align-items:center; flex-direction:row; white-space:nowrap;">
                        <checkbox class="checkbox" :default-checked="false"></checkbox>
                        <Button :rounded="'medium'" :size="'small'" v-text="'進階選項'" ></Button>
                    </div>
                    <div>
                        <checkbox :default-checked="false"></checkbox>
                        <div style="flex-direction: column; margin:0px;">
                            <div style="flex-direction: row; ">
                                <DropDownList
                                    :style="{ width: 'auto', margin:'2px' }"
                                    :data-items="columns_dropdownlist"
                                    :text-field="'text'"
                                    :data-item-key="'id'"
                                    v-model="columns_dropdownlist_value"
                                >
                                </DropDownList>
                                <DropDownList
                                    :style="{ width: 'auto', margin:'2px' }"
                                    :data-items="inequality_dropdownlist"
                                    :text-field="'text'"
                                    :data-item-key="'id'"
                                    v-model="inequality_dropdownlist_value"
                                >
                                </DropDownList>
                            </div>
                            <div style="flex-direction: row;">
                                <Input :style="{ width:'60px', margin:'2px', padding:'0px',overflow:'hidden' }" :size="'small'" v-model.number="filter_value"></Input>
                                <DropDownList
                                    :style="{ width: 'auto', margin:'2px' }"
                                    :data-items="sort_dropdownlist"
                                    :text-field="'text'"
                                    :data-item-key="'id'"
                                    v-model="sort_dropdownlist_value"
                                >
                                </DropDownList>
                            </div>
                        </div>
                        <Button :rounded="'medium'" :size="'small'" v-text="'更新'" ></Button>
                    </div>
                    <div style="font-size: 18px; flex-direction: column; align-items:flex-start;" >
                        <span style="vertical-align: middle;" v-text="'篩選出' + filter_calculation + '筆'"></span>
                        <span style="vertical-align: middle;" v-text="'總損益' + filter_total_profit_and_loss + ' '+'總口數' + filter_total_Lot "></span>                      
                    </div>
                </div>
                <div class="myfloatright ">
                    <Button size="small" v-text="'EXCEL'"></Button>
                </div>
            </GridToolbar>
        </Grid>
    </body>
</template>
<script>
import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
import { Button, ButtonGroup } from '@progress/kendo-vue-buttons';
import { Checkbox, Input, RadioGroup } from '@progress/kendo-vue-inputs';
import { DropDownList } from '@progress/kendo-vue-dropdowns';
import axios from 'axios';
export default {
    name: 'Member_List', 
      components: {
        Grid, 
        GridToolbar,
        Button,
        Input,
        Checkbox,
        RadioGroup,
        DropDownList,
    },   
    data: function () {
        return {
            filter_calculation:0,
            filter_total_profit_and_loss:0,
            filter_total_Lot:0,

            skip: 0,
            take: 10,
            currentPage:1,
            data:[],

            columns_dropdownlist_value:'',
            inequality_dropdownlist_value:'',
            sort_dropdownlist_value:"",
            filter_value:0,

            select_layer: [
            {
                label: "延伸會員",
                value: "extend",
            },
            {
                label: "當層會員",
                value: "current",
            },
            ],
            columns_dropdownlist:[
                { text: '餘額', id: 1 },
                { text: '今日損益', id: 2 },
                { text: '信用額度', id: 3 },
                { text: '總空', id: 4 },
                { text: '總多', id: 5 },
                { text: '未平多', id: 6 },
                { text: '未平空', id: 7 },
                { text: '入金', id: 8 },
                { text: '出金', id: 9 },
                { text: '費用', id: 10 },
                { text: '手續費', id: 11 },
            ],
            inequality_dropdownlist:[
                { text: '大於', id: 1 },
                { text: '小於', id: 2 },
                { text: '等於', id: 3 },
                { text: '大於等於', id: 4 },
                { text: '小於等於', id: 5 },
                { text: '不等於', id: 6 },
            ],
            sort_dropdownlist:[
                { text: '不排序', id: 1 },
                { text: '由大到小', id: 2 },
                { text: '由小到大', id: 3 },
            ],
            columns:[
                { field: 'zero', title: ' ', width: '40px', locked: true},
                { field: 'userName', title: '帳號aaa', width: '100%', locked: true},
                { field: 'b', title: '設定', width: '100%'},
                { field: 'c', title: '狀態', width: '100%'},
                { field: 'd', title: '動作', width: '100%'},
                { field: 'allLong', title: '總多', width: '100%'},
                { field: 'allShort', title: '總空', width: '100%'},
                { field: 'allDealLong', title: '未平多', width: '100%'},
                { field: 'allDealShort', title: '未平空', width: '100%'},
                { field: 'i', title: '信用額度', width: '100%'},
                { field: 'j', title: '昨日餘額', width: '100%'},
                { field: 'k', title: '未平損益', width: '100%'},
                { field: 'l', title: '平倉損益', width: '100%'},
                { field: 'm', title: '今日損益', width: '100%'},
                { field: 'n', title: '餘額', width: '100%'},
                { field: 'o', title: '留倉預扣', width: '100%'},
                { field: 'p', title: '手續費', width: '100%'},
                { field: 'q', title: '出金', width: '100%'},
                { field: 'r', title: '入金', width: '100%'},
                { field: 's', title: '費用', width: '100%'},
                { field: 't', title: '超爆', width: '100%'},
                { field: 'u', title: '歷史', width: '100%'},
                { field: 'v', title: '對帳表', width: '100%'},
            ],
        }
    },
    computed: {
        result: {
            get: function() {
                console.log(this.data.data)
                return this.data.data;
            }
        },
        total () {
            return this.data.totalRows;
        },  
    },
    mounted(){
        this.refreshGrid();
        this.columns_dropdownlist_value = this.columns_dropdownlist[0];
        this.inequality_dropdownlist_value = this.inequality_dropdownlist[0];
        this.sort_dropdownlist_value = this.sort_dropdownlist[0]
    },
    methods: {
        pageChangeHandler: function(event) {
            this.skip = event.page.skip;
            this.take = event.page.take;
            this.currentPage = (event.page.skip/this.take)+1,
            this.refreshGrid();
        },
        refreshGrid()
        {  
            axios.get(this.$httpPath+'/Trade/dealCount/'+this.take+'/'+this.currentPage, {
                params:{
                    
                }})
                .then((response)=>{
                    if(response.status===200)
                    {
                        this.data=response.data;
                        console.log(this.data)
                    }
                })
        },
    }
};
</script>
<style scoped>
.body{
    height:100%;
    width:100%;
    margin: 0px;
    border: 0px;
    display: inline-flex;
    color:#fff;
    font-family:"微軟正黑體";
    flex-direction: row;
    flex-wrap: nowrap;
    flex-direction: column;
}
.gridtoolbar{
    height: auto;
    padding: 0px 5px;
    background-color: #0c4d70;
    color: #fff;
    display: inline-flex;
    flex-direction:row
}
.myfloatleft{
    flex:1;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
}
.myfloatleft div{
    margin: 0px 5px;
    display: inline-flex; 
    align-items:center; 
    justify-content: flex-start;
    overflow: hidden;
    flex-direction:row; 
    white-space:nowrap;
}
.myfloatright{
    width:auto;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
}


Button{
    height:100%;
    width:auto;
    color: #fff;
    background-color: #17a2b8;
    padding: 2px 5px;
    margin: 3px;
}
.a{
    width:100%;
    height: 40px;
    background-color: aquamarine;
}
.b{
    flex:1;
    background-color: rgb(255, 127, 127);
}
.c{
    width:100%;
    height: 40px;
    background-color: rgb(255, 127, 212);
}
</style>
