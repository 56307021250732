<template>
    <body id="BottomBar" class="body">
      <table class="myfooter bluelibgtobottom" style="width:100%">
          <tbody class="colbottom microword">
              <th class="myfloatleft">
                  <span class="alert alert-danger" style="padding-bottom:1px;font-size:14.6px">公告</span>
              </th>
              <th @click="toggleDialog">
                  <vue-seamless-scroll 
                      :data="listData"
                      :class-option="classOption"
                      class="warp"
                      >
                      <ul class="item">
                      <li 
                          v-for="(item, index) in listData" 
                          :key="index">
                          <span class="title" v-text="item.title"></span>
                          <span class="date" v-text="item.date"></span>
                      </li>
                      </ul>
                  </vue-seamless-scroll>
              </th>
              <th class="myfloatright">
                  <div>
                      <span class=vrule></span>
                      <span >結算時間</span>
                      <span >{{end_time}}</span>
                  </div>
                  <div>
                      <span class=vrule></span>
                      <span >{{now_time}}</span>
                  </div>
                  <div>
                      <span class=vrule></span>
                      <span >{{connect_status}}</span>
                  </div>
                  <div>
                      <span class=progress>
                          <span class="progress-bar progress-bar-striped progress-bar-animated" role=progressbar aria-valuenow=100 aria-valuemin=0 aria-valuemax=100 style="width:100%"></span>
                      </span>
                  </div>
              </th>
          </tbody>
      </table>
      <Dialog 
      v-if="w_visible" 
      :title="'Please confirm'" 
      @close="toggleDialog">
        <component 
          id="myLogin"
          v-bind:is="Login" 
          v-on:Login_Click="Login_Click"
          >
        </component>
      </Dialog>
    </body>
</template>
<script>
import { Window, Dialog } from '@progress/kendo-vue-dialogs';
import vueSeamlessScroll from 'vue-seamless-scroll'
import Store from '@/store/index.js'
export default {
  name: "BottomBar",
  components: {
    vueSeamlessScroll,
    Dialog,
  },
  computed: {
      window_visible(){
          return Store.state.window_visible;
      },
  },
  watch: {
      window_visible(){
          console.log(Store.state.window_visible)
          this.w_visible = Store.state.window_visible;
      },
  },
  data: function () {
    return {
         w_visible: false,
         end_time:"2022/07/27 13:47:00",
         now_time:"2022/07/26 15:53:14",
         connect_status:"連線1",
         classOption: {
          direction: 0,
          step: 0.1,
        },
         listData: [{
          'title': '无缝滚动第一行无缝滚动第一行',
          'date': '2017-12-16'
        }, {
          'title': '无缝滚动第二行无缝滚动第二行',
          'date': '2017-12-16'
        }, {
          'title': '无缝滚动第三行无缝滚动第三行',
          'date': '2017-12-16'
        }, {
          'title': '无缝滚动第四行无缝滚动第四行',
          'date': '2017-12-16'
        }, {
          'title': '无缝滚动第五行无缝滚动第五行',
          'date': '2017-12-16'
        }, {
          'title': '无缝滚动第六行无缝滚动第六行',
          'date': '2017-12-16'
        }, {
          'title': '无缝滚动第七行无缝滚动第七行',
          'date': '2017-12-16'
        }, {
          'title': '无缝滚动第八行无缝滚动第八行',
          'date': '2017-12-16'
        }, {
          'title': '无缝滚动第九行无缝滚动第九行',
          'date': '2017-12-16'
        }],
    }
  },

  methods: {
    toggleDialog () {
        this.w_visible = !this.w_visible;
        //return Store.dispatch("OpenWindow", !this.w_visible);
    },
  },
};
</script>
<style scoped>
.body{
    height:100%; 
    margin: 0px;
    display: flex;
    flex-direction: column;
}
.progress-bar{
    display:-ms-flexbox;
    display:flex;
    -ms-flex-direction:column;
    flex-direction:column;
    -ms-flex-pack:center;
    justify-content:center;
    color:#fff;
    text-align:center;
    white-space:nowrap;
    background-color:#007bff;
    transition:width .6s ease;
    padding-left:50px;
}
.progress-bar-striped{
    background-image:linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    background-size:1rem 1rem
}
.progress-bar-animated{
    -webkit-animation:progress-bar-stripes 1s linear infinite;
    animation:progress-bar-stripes 1s linear infinite
}
.progress{
    display:-ms-flexbox;
    display:flex;
    overflow:hidden;
    font-size:.75rem;
    background-color:#e9ecef;
    border-radius:.25rem;
    margin-bottom:0px;
    margin-left: 5px;
    height:12px;
    line-height:00px

}
.vrule{
    font-size:16px;
    margin:0 5px;
    border-left:2px ridge #298ab5
}
.microword{
    font-family:"微軟正黑體";
    font-size:14.6px
}
.font-14{
    line-height:22px;
    font-size:14.6px
}
.alert{
    padding:0px 5px;
    margin-left:5px;
    margin-right:2px
}
.colbottom{
    height:26px;
    line-height:26px;
    text-align: right;
    white-space: nowrap;
    vertical-align:middle;
}
.colbottom div{
    display: inline-block;
}
.colbottomr{
    float:left;
    height:26px;
    line-height:26px;
    vertical-align:middle
}
.myfloatleft{
    width: 24px;
    text-align: left;
    margin-left:5%;
}
.myfloatright{
    margin-left: 35%;
    width:25%;
    margin-right: 0.1%;
    text-align: right;
    white-space: nowrap;
}
.myfooter{
    height:26px;
    line-height:26px;
    padding:0 5px;
    color:#fff;
    width:100%;
    overflow:hidden;
    border-top:solid 1px #21597b
}
.bluelibgtobottom{
    background-color:#084163;
    background-image:linear-gradient(to bottom,#084163 0,#105d7b 100%)
}
</style>
<style lang="scss" scoped>
  .warp {
    height: 20px;
    width:100%;
    min-width: 20%;
    margin: 0px 5px;
    overflow: hidden;
    float: left;
    ul {
      list-style: none;
      padding: 0;
      margin: 0 auto;
      li,
      a {
        display: block;
        height: 30px;
        line-height: 30px;
        display: flex;
        justify-content: space-between;
        font-size: 14.6px;
      }
    }
  }
</style>