<template>
    <body id = 'Subaccount_Setting_Dialog'>
        <div class="conter">
            <div class = "title">帳戶資訊</div>
            <table>
                <tr>
                    <td v-text="'會員編號'"/>
                    <td>
                        <Input style=" padding:0px 5px; width:160px; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;" v-text="select_row_data['mAccountName']"/>
                    </td>
                </tr>
                    <tr>
                    <td/>
                    <td>
                        <Button :rounded="'medium'" :size="'small'" v-text="'修改密碼'"></Button>
                    </td>
                </tr>
                    <tr>
                    <td v-text="'代理名稱'"/>
                    <td>
                        <Input style=" padding:0px 5px; width:160px; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;" v-text="select_row_data['mAgentNickName']"/>
                    </td>
                </tr>
                    <tr>
                    <td v-text="'建立日期'"/>
                    <td>
                        <Input style=" padding:0px 5px; width:160px; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;" v-text="select_row_data['mJoinTime']"/>
                    </td>
                </tr>
            </table>
        </div>
        <div class="conter">
            <div class = "title">子帳號設定</div>
            <div class="conter-n">
                <Checkbox
                    v-for="(permissiom, index) in subaccount_permission"
                    :key="index"
                    :disable="permissiom.disable"
                    v-model="permissiom.value"
                    :label="permissiom.label"
                />
            </div>
        </div>
    </body>
</template>
<script>
import { Button, ButtonGroup } from '@progress/kendo-vue-buttons';
import { Checkbox, Input, RadioGroup, TextArea } from '@progress/kendo-vue-inputs';
export default {
    name: 'Subaccount_Setting_Dialog', 
      components: {
        Input,
        Button,
        Checkbox,
    },   
    props: {
        select_row_data:{},
    },
    data: function () {
        return {
            account_name:'範例',
            agnet_name:'範例',
            creat_date:'範例',
            subaccount_permission : [
                {
                    label:'代課下單',
                    value:false,
                    name:'mPlace_Order',
                    disabled:false,
                },
                {
                    label:'代客刪單',
                    value:false,
                    name:'mDelete_Order',
                    disabled:false,
                },
                {
                    label:'改成交價',
                    value:false,
                    name:'mChange_Order',
                    disabled:false,
                },
                {
                    label:'盤後單',
                    value:false,
                    name:'mAfter_Hours_Order',
                    disabled:false,
                },
                {
                    label:'編輯會員(子帳號檢視)',
                    value:false,
                    name:'mEdit_Member',
                    disabled:false,
                },
                {
                    label:'編輯代理',
                    value:false,
                    name:'mEditorial_Agent',
                    disabled:false,
                },
                {
                    label:'同主帳號(功能開啟)',
                    value:false,
                    name:'mMaster_Functionality',
                    disabled:false,
                },
                {
                    label:'新增會員',
                    value:false,
                    name:'mAdd_Member',
                    disabled:false,
                },
                {
                    label:'新增代理',
                    value:false,
                    name:'mAdd_Agent',
                    disabled:false,
                },
                {
                    label:'新增子帳號',
                    value:false,
                    name:'mAdd_Subaccount',
                    disabled:false,
                },
                {
                    label:'編輯費用',
                    value:false,
                    name:'mEditing_Cost',
                    disabled:false,
                },
                {
                    label:'編輯手續費',
                    value:false,
                    name:'mEditing_Tip',
                    disabled:false,
                },
                {
                    label:'顯示名稱電話',
                    value:false,
                    name:'mDisplay_Name_Phone',
                    disabled:false,
                },
            ],
        }
    },
    mounted(){
        const Permissions = Object.keys(this.$store.getters.Select_Agent_Permissions);
        var asd = Permissions.forEach((element, Index) => {
            if( this.subaccount_permission[Index].name == element)
            {
                if(this.objectsss[element] == '啟用')
                    this.subaccount_permission[Index].value = true;
                if(this.objectsss[element] == '停用')
                    this.subaccount_permission[Index].value = false;
                if(this.objectsss[element] == '不使用')
                    return false;
            }
        });
        console.log(asd);  // [1, 2, 3]
    },
    computed: {

    },
    methods: {


    },
};
</script>
<style scoped>
body{
    height:100%; 
    margin : -16px;
    display: flex;
    flex-direction: row;
    font-size: 15px;
}
.conter{
    border-width : 1px;
    border-style : solid;
    border-color : #ddd;
    margin : 1px;
    flex : 1;
    display : inline-flex;
    flex-direction : column;
    overflow:auto
}
.conter-n{
    margin : 1px;
    flex : 1;
    display : inline-flex;
    flex-direction : column;
    overflow:auto
}
.title{
    height: 32px;
    padding-left: 20px;
    display : inline-flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    background-color: #f5f5f5;
}
table{
    color:#495057;
}
table tr td{
    width: 100%;
    padding: 5px;
}
.row{
    width: auto;
    display : inline-flex;
    flex-direction : column;
    align-items: center;
    white-space: nowrap;
}
Button{
    height:100%;
    width:auto;
    color: #fff;
    background-color: #17a2b8;
    padding: 2px 5px;
    margin: 3px;
}
</style>
