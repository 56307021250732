import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from '@/router'
import {Start_SignalR_Connect} from '@/store/SignalRHub.js'
import {Monitorin_MQ_Subscribe} from '@/store/RabbitMQ.js';

Vue.use(Vuex);

export default new Vuex.Store({
  //data
  state: { 
    agentlistpane_width:"",
    window_visible:"",



    refresh_token: "",
    access_token: "",
    loggedInUser: "",
    user_permissions:{},
    isAuthenticated: false,




    selectedCatImg:"",
    My3DModelGuid:"111",
    My3DToolTipDatas:[],
    My3DModelData:{},
    /**/
    AgentTreeData:[],
    AgentTreeData_SelectedItem:undefined,
    AgentListData:[],
    MemberListData:[],
    MemberProfilePaneData:[],


    Select_Agent_Permissions:{},
    
    /**/
    /**/
    ManagementSubscribeTopic:[],
    /**/

    allProducts: ["NoData"],
    allKLineDay: ["NoData"],
    allKLineMin: ["NoData"],
    allTick: ["NoData"],
    nowSelectProduct:{
        bstrStockNo:'DHF00',
        bstrStock:'',
        nBid:'',
        nAsk:'',
        nClose:'',
        nTQty:'',
        nYQty:'',
        nRef:'',
        nOpen:'',
        nHigh:'',
        nLow:'',
        sDecimal:'',
        nDown:'',
        nUp:'',
        nFutureOI:'',
        nTAc:'',
        nTBc:'',
        nAc:'',
        nBc:'',
        nTradingDay:'',
        Amplitude:'',
        nAmplitude:'',
        nTickQty:'',
        bstrMarketNo:'',
        sTypeNo:'',
    },
  },
  //改變資料內容的方法
  //改變state
  mutations: { 
    agentListPane_Width(state, width) {
      state.agentlistpane_width = null;
      state.agentlistpane_width = width;
      //console.log(state.agentlistpane_width)
    },
    openWindow(state, window_status) {
      state.window_visible = window_status;
      //console.log(state.window_visible)
    },
    AgentTreeDataMutations(state, data) {
      state.AgentTreeData = data;
      console.log(state.AgentTreeData);
      if( state.AgentTreeData_SelectedItem ===  undefined )
      {
        state.AgentTreeData_SelectedItem = state.AgentTreeData[0];
        state.AgentTreeData_SelectedItem.selected=true;
        console.log(state.AgentTreeData_SelectedItem);
      }
    },
    AgentTreeData_SelectedItemMutations(state, data) {
      state.AgentTreeData_SelectedItem.selected = !state.AgentTreeData_SelectedItem.selected;
      state.AgentTreeData_SelectedItem = data;
    },
    AgentListDataMutations(state, data)
    {
      state.AgentListData = data;
      console.log(state.AgentListData);
    },
    MemberListDataMutations(state, data)
    {
      state.MemberListData = data;
      //console.log(state.MemberListData);
    },
    MemberProfilePaneDataMutations(state, data)
    {
      state.MemberProfilePaneData = data;
      console.log(state.MemberProfilePaneData);
    },
    ManagementSubscribeTopicMutations(state, topiclist) {
      state.ManagementSubscribeTopic = topiclist;
    },
    reloadProductsData(state, payload) {
      state.allProducts = payload;
      //console.log(state.allProducts)
    },
    reloadKLineDayData(state, payload) {
      state.allKLineDay = payload;
      //console.log(state.allKLineDay)
    },
    reloadKLineMinData(state, payload) {
      state.allKLineMin = payload;
      //console.log(state.allKLineMin)
    },
    reloadTickData(state, payload) {
      state.allTick = payload;
      //console.log(state.allTick)
    },
    loadNowSelectProduct(state, payload) {
      state.nowSelectProduct = payload;
      //console.log(state.nowSelectProduct);
      //console.log(state.nowSelectProduct.bstrStockNo);
      Monitorin_MQ_Subscribe(state.nowSelectProduct.bstrStockNo)
    },
    setRefreshToken: function(state, refreshToken) {
      state.refresh_token = refreshToken;
    },
    setAccessToken: function(state, accessToken) {
      state.access_token = accessToken;
      localStorage.setItem("isAuthenticated",true);
    },
    // sets state with user information and toggles 
    // isAuthenticated from false to true
    setLoggedInUser: function(state, user_data) {
      state.loggedInUser = user_data.user_name;
      state.user_permissions = user_data.user_permissions;
      console.log(state.user_permissions)
      console.log(typeof(state.user_permissions))
      state.isAuthenticated = true;
      //console.log(state.isAuthenticated)
    },
    // delete all auth and user information from the state
    clearUserData: function(state) {
      state.refresh_token = "";
      state.access_token = "";
      state.loggedInUser = {};
      state.isAuthenticated = false;
      localStorage.setItem("access_token","");
    },  
    setCT3DModel: function(state,modelData) {
      state.My3DModelData = modelData;
    },    
    setCT3DModelToopTip: function(state,modelToolTip) {
      state.My3DToolTipDatas = modelToolTip;
    },
    addCT3DModelToopTip: function(state,modelToolTip) {
      state.My3DToolTipDatas.push(modelToolTip);
    },             
  },
  //methods
  //用非同步取得遠端資料
  //用dispatch呼叫actions
  //action用commit呼叫mutations
  actions: {   
    AgentListPane_Width: async ({ commit }, status)=>{
      //console.log(status)
      commit('agentListPane_Width', status)
    },
    OpenWindow: async ({ commit }, status)=>{
      //console.log(status)
      commit('openWindow', status)
    },
    AgentTreeData_SelectedItemActions: async ({ commit }, status)=>{
      commit('AgentTreeData_SelectedItemMutations', status)
    },
    AgentTreeDataActions: async ({ commit }, status)=>{
      //console.log(status)
      commit('AgentTreeDataMutations', status)
    },

    AgentListDataActions : async ({ commit }, status)=>{
      //console.log(status)
      commit('AgentListDataMutations', status)
    },
    MemberListDataActions : async ({ commit }, status)=>{
      //console.log(status)
      commit('MemberListDataMutations', status)
    },
    MemberProfilePaneDataActions : async ({ commit }, status)=>{
      console.log(status)
      commit('MemberProfilePaneDataMutations', status)
    },
    ManagementSubscribeTopicActions: async ({ commit, getters}, status)=>{
      const GetManagementSubscribeTopicUrl = Vue.prototype.$httpPath+"/ManagementInfo/GetManagementSubscribeTopic";
      //console.log(GetManagementSubscribeTopicUrl)
      //console.log(getters.loggedInUser)
      try {
        await axios.get(GetManagementSubscribeTopicUrl,{
          params:{
            AccountName : getters.loggedInUser
          }
        }).then(response => {
          if (response.status === 200) {
            console.log(response.data)
            commit('ManagementSubscribeTopicMutations', response.data)
          }
        });
      } catch (e) {
        console.log(e)
      }
    },

    ReloadProductsData: async ({ commit }, status)=>{
      //console.log(status)
      commit('reloadProductsData', status)
    },
    ReloadKLineDayData: async ({ commit }, status)=>{
      //console.log(status)
      commit('reloadKLineDayData', status)
    },
    ReloadKLineMinData: async ({ commit }, status)=>{
      //console.log(status)
      commit('reloadKLineMinData', status)
    },
    ReloadTickData: async ({ commit }, status)=>{
      //console.log(status)
      commit('reloadTickData', status)
    },
    LoadNowSelectProduct: async ({ commit }, status)=>{
      //console.log(status)
      commit('loadNowSelectProduct', status)
    },    
    AddCT3DModelToopTip: async ({ commit }, myToolTipdata) => {
      let self = this
      const url = Vue.prototype.$httpPath+'/CT3DModelToolTip/AddCT3DModelToolTip';
      try {
        await axios.post(url,myToolTipdata).then(response => {
          if (response.status === 200) {
            //this.Get3DToolTipDatas();
            //commit("addCT3DModelToopTip", myToolTipdata);
          }
          else
          {
            console.log("response.status=",response.status);
          }
        });
      } catch (e) {
        console.log(e);
      }           
      
    },      
    Get3DToolTipDatas: async ({ commit,getters},) => {
      let self = this
      const modelUrl = Vue.prototype.$httpPath+'/CT3DModelToolTip/GetCT3DModelToolTip?guid='+getters.My3DModelGuid;
      try {
        await axios.get(modelUrl).then(response => {
          if (response.status === 200) {
            commit("setCT3DModelToopTip", response.data);
          }
          else
          {
            console.log("response.status=",response.status);
          }
        });
      } catch (e) {
        console.log(e);
      }
    },    
    GetCT3DModel: async ({ commit,getters}, modelGuid) => {
      let self = this
      const modelUrl = Vue.prototype.$httpPath+'/CT3DModel/GetCT3DModel?guid='+getters.My3DModelGuid;
      try {
        await axios.get(modelUrl).then(response => {
          if (response.status === 200) {
            commit("setCT3DModel", response.data);
          }
          else
          {
            console.log("response.status=",response.status);
          }
        });
      } catch (e) {
        console.log(e);
      }
    },   
    logIn: async ({ commit, dispatch }, {email,password}) => {
      let self = this
      //console.log(email)
      //console.log(password)
      const loginUrl = Vue.prototype.$httpPath+'/AuthManagement/Login';
      try {
        await axios.post(loginUrl, {email,password}).then(response => {
          if (response.status === 200) {
            localStorage.setItem("access_token",response.data.token);
            commit("setRefreshToken", response.data.refreshToken);
            commit("setAccessToken", response.data.token);
            router.go(0);
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    refreshToken: async ({ state, commit }) => {
      const refreshUrl = Vue.prototype.$httpPath+'/AuthManagement/RefreshToken';
      try {
        await axios
          .post(refreshUrl, { Token: state.access_token, RefreshToken: state.refresh_token })
          .then(response => {
            if (response.status === 200) {
              commit("setRefreshToken", response.data.refreshToken);
              commit("setAccessToken", response.data.token);
            }
          });
      } catch (e) {
        console.log(e.response);
      }
    },
    fetchUser: async ({ commit,  dispatch }) => {
      const currentUserUrl = Vue.prototype.$httpPath+"/ManagementInfo/GetCurrentUser_Data";
      try {
        await axios.get(currentUserUrl).then(response => {
          if (response.status === 200) {
            commit("setLoggedInUser", response.data);
            //console.log(response.data)
            //dispatch('ManagementSubscribeTopicActions');
            Start_SignalR_Connect();
          }
        });
      } 
      catch (e) {
        console.log(e);
      }
    },   
    logout: async ({ commit, dispatch }) => {
      commit("clearUserData");
      router.go(0);
    },    
  },
  getters: { //compute
    loggedInUser: state => {
      return state.loggedInUser.toUpperCase();
    },
    user_permissions: state=>{
      return state.user_permissions;
    },
    MemberProfilePane : state => {
      return state.MemberProfilePaneData
    },
    isAuthenticated: state => {
      return state.isAuthenticated
    },
    AgentTreeData : state => {
      return state.AgentTreeData
    },
    AgentTreeData_SelectedItem : state => {
      return state.AgentTreeData_SelectedItem
    },
    AgentListData : state => {
      return state.AgentListData
    },
    MemberListData: state => {
      return state.MemberListData
    },
    Select_Agent_Permissions:state => {
      return state.Select_Agent_Permissions
    },


    ManagementSubscribeTopic: state => {
      return state.ManagementSubscribeTopic
    },
    accessToken: state => state.access_token,
    refreshToken: state => state.refresh_token, 
    My3DModelData: state => state.My3DModelData, 
    My3DToolTipDatas: state => state.My3DToolTipDatas,
    My3DModelGuid: state => state.My3DModelGuid,
  }
});
