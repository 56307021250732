<template>
    <body id="Agent_List" class="body">
        <Grid ref="grid" :style="{ height: gridhight + 'px', width: gridwidth + 'px' }" :data-items="result"
            :column-virtualization="false" :columns="columns" :pageable="true" :skip="skip" :take="take" :total="total"
            :enableRtl="true" :resizable="false" :selectable="true" @scroll="scrollHandler()"
            @pagechange="pageChangeHandler($event)">
            <template v-slot:mzero="{ props }">
                <td style="width:100%; padding:0px; text-align: center; flex-wrap: nowrap; position:sticky; z-index:2"
                    :style="result.indexOf(props.dataItem) % 2 === 1 ? 'background-color: #F9F9F9' : result[0] === props.dataItem ? 'background-color: #FFE4C0;' : 'background-color: #FFFF;'">
                    <div v-if="props.dataItem['mSubaccount'] != '子帳號'">
                        <Button :rounded="'medium'" :size="'small'" v-text="'選取'"
                            @click=onSelectClick(props.dataItem)></Button>
                    </div>
                </td>
            </template>
            <template v-slot:head_mAgentNickName="{ props }">
                <div style="width:100%; padding:0px; text-align: center; font-weight:bold; font-size:16px; flex-wrap: nowrap;"
                    @click="customHandler" v-text="props.title">
                </div>
            </template>
            <template v-slot:mAgentNickName="{ props }">
                <td style="width:100%; padding:0px; text-align: center; flex-wrap: nowrap; position:sticky; z-index:2;"
                    :style="result.indexOf(props.dataItem) % 2 === 1 ? 'background-color: #F9F9F9' : result[0] === props.dataItem ? 'background-color: #FFE4C0;' : 'background-color: #FFFF;'">
                    <span style="color:#0053ff;" v-text="props.dataItem[props.field]"></span>
                </td>
            </template>
            <template v-slot:head_mAccountName="{ props }">
                <div style="text-align: center; font-weight:bold; font-size:16px" @click="customHandler"
                    v-text="props.title">
                </div>
            </template>
            <template v-slot:mAccountName="{ props }">
                <td style="width:100%; padding:0px; text-align: left; flex-wrap: nowrap; position:sticky; z-index:2;"
                    :style="result.indexOf(props.dataItem) % 2 === 1 ? 'background-color: #F9F9F9' : result[0] === props.dataItem ? 'background-color: #FFE4C0;' : 'background-color: #FFFF;'">
                    <span style="color:#0053ff;" v-text="props.dataItem[props.field]"
                        @click="AccountName_Button(props.dataItem)"></span>
                </td>
            </template>
            <template v-slot:head_mCreditsAmount="{ props }">
                <div style="text-align: center; font-weight:bold; font-size:16px" @click="customHandler"
                    v-text="props.title">
                </div>
            </template>
            <template v-slot:mCreditsAmount="{ props }">
                <td style="width:100%; padding:0px; text-align: right; flex-wrap: nowrap;"
                    :style="result.indexOf(props.dataItem) % 2 === 1 ? 'background-color: #F9F9F9' : result[0] === props.dataItem ? 'background-color: #FFE4C0;' : 'background-color: #FFFF;'">
                    <div v-if="props.dataItem['mSubaccount'] != '子帳號'">
                        <span style="color:#0053ff;" v-text="props.dataItem[props.field]"></span>
                        <Button :rounded="'medium'" :size="'small'" v-text="'增減'"
                            @click="CreditsAmount_Button(props.dataItem)"></Button>
                    </div>
                </td>
            </template>
            <template v-slot:head_mLastBalance="{ props }">
                <div style="text-align: center; font-weight:bold; font-size:16px" @click="customHandler"
                    v-text="props.title">
                </div>
            </template>
            <template v-slot:mLastBalance="{ props }">
                <td style=" width:100%; padding:0px; text-align: right; flex-wrap: nowrap;"
                    :style="result.indexOf(props.dataItem) % 2 === 1 ? 'background-color: #F9F9F9' : result[0] === props.dataItem ? 'background-color: #FFE4C0;' : 'background-color: #FFFF;'">
                    <div v-if="props.dataItem['mSubaccount'] != '子帳號'">
                        <span style="color:#0053ff;" v-text="props.dataItem[props.field]"></span>
                        <Button :rounded="'medium'" :size="'small'" v-text="'儲值'"
                            @click="LastBalance_Button(props.dataItem)"></Button>
                    </div>
                </td>
            </template>
            <template v-slot:head_mStatus="{ props }">
                <div style="text-align: center; font-weight:bold; font-size:16px" @click="customHandler"
                    v-text="props.title">
                </div>
            </template>
            <template v-slot:mStatus="{ props }">
                <td style="width:100%; padding:0px; text-align: center; flex-wrap: nowrap;"
                    :style="result.indexOf(props.dataItem) % 2 === 1 ? 'background-color: #F9F9F9' : result[0] === props.dataItem ? 'background-color: #FFE4C0;' : 'background-color: #FFFF;'">
                    <span v-if="props.dataItem[props.field] === '正常'" style="color:#313131;" v-text="'正常'"></span>
                    <span v-if="props.dataItem[props.field] === '禁新'" style="color:#fb0000;" v-text="'禁新'"></span>
                    <span v-if="props.dataItem[props.field] === '凍結'" style="color:#156dff;" v-text="'凍結'"></span>
                    <span v-if="props.dataItem[props.field] === '停用'" style="color:#b5963e;" v-text="'停用'"></span>
                    <Button :rounded="'medium'" :size="'small'" v-text="'設'"
                        @click="Setting_Status_Button(props.dataItem)"></Button>
                    <Button :rounded="'medium'" :size="'small'" v-text="'設定會員'"
                        @click="Status_Member_Button(props.dataItem)"></Button>
                </td>
            </template>
            <template v-slot:head_mCategory="{ props }">
                <div style="text-align: center; font-weight:bold; font-size:16px" @click="customHandler"
                    v-text="props.title">
                </div>
            </template>
            <template v-slot:mCategory="{ props }">
                <td style="width:100%; padding:0px; text-align: center; flex-wrap: nowrap;"
                    :style="result.indexOf(props.dataItem) % 2 === 1 ? 'background-color: #F9F9F9' : result[0] === props.dataItem ? 'background-color: #FFE4C0;' : 'background-color: #FFFF;'">
                    <div v-if="props.dataItem[props.field] == '測試'" style="color:#0053ff;" v-text="'測試'"></div>
                    <div v-if="props.dataItem[props.field] == '正常'" style="color:#0053ff;" v-text="'正常'"></div>
                </td>
            </template>
            <template v-slot:head_mProportion="{ props }">
                <div style="text-align: center; font-weight:bold; font-size:16px" @click="customHandler"
                    v-text="props.title">
                </div>
            </template>
            <template v-slot:mProportion="{ props }">
                <td style="width:100%; padding:0px; text-align: center; flex-wrap: nowrap;"
                    :style="result.indexOf(props.dataItem) % 2 === 1 ? 'background-color: #F9F9F9' : result[0] === props.dataItem ? 'background-color: #FFE4C0;' : 'background-color: #FFFF;'">
                    <div v-if="props.dataItem['mSubaccount'] != 1" style="color:#0053ff;"
                        v-text="props.dataItem[props.field] + '%'"></div>
                </td>
            </template>
            <template v-slot:head_mRent="{ props }">
                <div style="text-align: center; font-weight:bold; font-size:16px" @click="customHandler"
                    v-text="props.title">
                </div>
            </template>
            <template v-slot:mRent="{ props }">
                <td style="width:100%; padding:0px; text-align: center; flex-wrap: nowrap;"
                    :style="result.indexOf(props.dataItem) % 2 === 1 ? 'background-color: #F9F9F9' : result[0] === props.dataItem ? 'background-color: #FFE4C0;' : 'background-color: #FFFF;'">
                    <div v-if="props.dataItem['mSubaccount'] != 1" style="color:#0053ff;"
                        v-text="props.dataItem[props.field]"></div>
                </td>
            </template>
            <template v-slot:head_mSubaccount="{ props }">
                <div style="text-align: center; font-weight:bold; font-size:16px" @click="customHandler"
                    v-text="props.title">
                </div>
            </template>
            <template v-slot:mSubaccount="{ props }">
                <td style="width:100%; padding:0px; text-align: center; flex-wrap: nowrap;"
                    :style="result.indexOf(props.dataItem) % 2 === 1 ? 'background-color: #F9F9F9' : result[0] === props.dataItem ? 'background-color: #FFE4C0;' : 'background-color: #FFFF;'">
                    <div style="color:#0053ff;" v-if="props.dataItem[props.field] != '子帳號'" v-text="''"></div>
                    <div style="color:#0053ff;" v-if="props.dataItem[props.field] == '子帳號'" v-text="'子帳號'"></div>
                </td>
            </template>
            <template v-slot:head_mAdd="{ props }">
                <div style="text-align: center; font-weight:bold; font-size:16px" @click="customHandler"
                    v-text="props.title">
                </div>
            </template>
            <template v-slot:mAdd="{ props }">
                <td style="width:100%; padding:0px; text-align: left; flex-wrap: nowrap;"
                    :style="result.indexOf(props.dataItem) % 2 === 1 ? 'background-color: #F9F9F9' : result[0] === props.dataItem ? 'background-color: #FFE4C0;' : 'background-color: #FFFF;'">
                    <Button :rounded="'medium'" :size="'small'" v-text="'代理'"
                        @click="Add_Agent_Button(props.dataItem)"></Button>
                    <Button :rounded="'medium'" :size="'small'" v-text="'會員'"
                        @click="Add_Member_Button(props.dataItem)"></Button>
                    <Button :rounded="'medium'" :size="'small'" v-text="'公告'"
                        @click="Add_Post_Button(props.dataItem)"></Button>
                    <Button v-if="props.dataItem['mSubaccount'] != '子帳號'" :rounded="'medium'" :size="'small'" v-text="'子帳號'"
                        @click="Add_Subaccount_Button(props.dataItem)"></Button>

                </td>
            </template>
            <template v-slot:head_mSetting="{ props }">
                <div style="text-align: center; font-weight:bold; font-size:16px" @click="customHandler"
                    v-text="props.title">
                </div>
            </template>
            <template v-slot:mSetting="{ props }">
                <td style="width:100%; padding:0px; text-align: center; flex-wrap: nowrap;"
                    :style="result.indexOf(props.dataItem) % 2 === 1 ? 'background-color: #F9F9F9' : result[0] === props.dataItem ? 'background-color: #FFE4C0;' : 'background-color: #FFFF;'">
                    <Button :rounded="'medium'" :size="'small'" v-text="'設定'"
                        @click="Setting_Subaccount_Button(props.dataItem)"></Button>
                    <Button :rounded="'medium'" :size="'small'" v-text="'修改密碼'"
                        @click="Setting_Password_Button(props.dataItem)"></Button>

                </td>
            </template>
            <template v-slot:head_mHistory="{ props }">
                <div style="text-align: center; font-weight:bold; font-size:16px" @click="customHandler"
                    v-text="props.title">
                </div>
            </template>
            <template v-slot:mHistory="{ props }">
                <td style="width:100%; padding:0px; text-align: center; flex-wrap: nowrap;"
                    :style="result.indexOf(props.dataItem) % 2 === 1 ? 'background-color: #F9F9F9' : result[0] === props.dataItem ? 'background-color: #FFE4C0;' : 'background-color: #FFFF;'">
                    <Button v-if="props.dataItem['mSubaccount'] != 1" :rounded="'medium'" :size="'small'" v-text="'儲值'"
                        @click="History_Stored_Button(props.dataItem)"></Button>
                </td>
            </template>
            <GridToolbar class="gridtoolbar">
                <div class="myfloatleft ">
                    <div>
                        <span style="padding-top:2px;font-size:18px; vertical-align: middle;margin:3px;"
                            v-text="'代理清單'"></span>
                        <Input :style="{ margin: '2px', padding: '0px', overflow: 'hidden', flex: 'nowrap' }"
                            :size="'small'" :icon-name="'k-icon k-i-zoom'" v-model="search_word"></Input>
                        <Button :rounded="'medium'" :size="'small'" v-text="'搜索'"></Button>
                    </div>
                    <div>
                        <Button :rounded="'large'" :size="'small'" v-for="button in filter_buttons" :togglable="true"
                            :key="button.id" :selected="filter_select_button.id === button.id" v-text="button.name"
                            @click="Filter_Change(button.id)">
                        </Button>
                        <span class="borader" v-text="'篩選出 ' + filter_calculation + ' 筆'"></span>
                    </div>
                    <div>
                        <Button @click="add_this_level_agent_visible = true">新增代理</Button>

                    </div>
                    <!--div>帳號延伸紀錄
                    </div-->
                </div>
                <div class="myfloatright ">
                    <Button :rounded="'medium'" :size="'small'" v-text="'EXCEL'"></Button>
                </div>
            </GridToolbar>
        </Grid>
        <Dialog v-if="accountName_visible" :width="'830px'" :height="'745px'" :title="'代理設定'"
            @close="AccountName_Button(null)">
            <AgentSetting :select_row_data="select_row_data" />
            <DialogActionsBar>
                <div style="display:flex; justify-content:flex-end; margin-bottom:0px">
                    <Button style="width:58px;height:38px;" :rounded="'medium'" v-text="'確認'"></Button>
                    <Button style="width:58px;height:38px;" :rounded="'medium'" v-text="'取消'"
                        @click="AccountName_Button(null)"></Button>
                </div>
            </DialogActionsBar>
        </Dialog>
        <Dialog v-if="creditsAmount_visible" class="Dialogs" :width="'800px'" :height="'500px'"
            :title="'增減額度 : ' + select_row_data['mAccountName']" @close="CreditsAmount_Button(null)">
            <CreditsAmount :select_row_data="select_row_data" />
            <DialogActionsBar>
                <div style="display:flex; justify-content:flex-end; margin-bottom:0px">
                    <Button style="width:58px;height:38px;" :rounded="'medium'" v-text="'關閉'"
                        @click="CreditsAmount_Button(null)"></Button>
                </div>
            </DialogActionsBar>
        </Dialog>
        <Dialog v-if="lastBalance_visible" :width="'800px'" :height="'500px'"
            :title="'儲值 : ' + select_row_data['mAccountName']" @close="LastBalance_Button(null)">
            <LastBalance :select_row_data="select_row_data" />
        </Dialog>
        <Dialog v-if="setting_status_visible" :width="'auto'" :height="'auto'"
            :title="'設定狀態 : ' + select_row_data['mAccountName']" @close="Setting_Status_Button(null)">
            <SettingStatus :select_row_data="select_row_data" />
            <DialogActionsBar>
                <div style="display:flex; justify-content:flex-end; margin-bottom:0px">
                    <Button style="width:58px;height:38px;" :rounded="'medium'" v-text="'確認'"></Button>
                    <Button style="width:58px;height:38px;" :rounded="'medium'" v-text="'取消'"
                        @click="Setting_Status_Button(null)"></Button>
                </div>
            </DialogActionsBar>
        </Dialog>
        <Dialog v-if="setting_membe_statusr_visible" :width="'362px'" :height="'445px'"
            :title="'設定會員 : ' + select_row_data['mAccountName']" @close="Status_Member_Button(null)">
            <SettingMemberStatus :select_row_data="select_row_data" />
            <DialogActionsBar>
                <div style="display:flex; justify-content:flex-end; margin-bottom:0px">
                    <Button style="width:58px;height:38px;" :rounded="'medium'" v-text="'確認'"></Button>
                    <Button style="width:58px;height:38px;" :rounded="'medium'" v-text="'取消'"
                        @click="Status_Member_Button(null)"></Button>
                </div>
            </DialogActionsBar>
        </Dialog>
        <Dialog v-if="add_agent_visible" :width="'445px'" :height="'auto'"
            :title="'新增代理 : ' + select_row_data['mAccountName']" @close="Add_Agent_Button(null)">
            <AddAgent :select_row_data="select_row_data" v-on:add_data="Add_Agent" />
            <DialogActionsBar>
                <div style="display:flex; justify-content:flex-end; margin-bottom:0px">
                    <Button style="width:58px;height:38px;" :rounded="'medium'" v-text="'下一步'"
                        @click="CreatAgentInfo()"></Button>
                    <Button style="width:58px;height:38px;" :rounded="'medium'" v-text="'關閉'"
                        @click="Add_Agent_Button(bull)"></Button>
                </div>
            </DialogActionsBar>
        </Dialog>
        <Dialog v-if="add_member_visible" :width="'auto'" :height="'auto'"
            :title="'新增會員 : ' + select_row_data['mAgentNickName']" @close="Add_Member_Button(null)">
            <AddMember :select_row_data="select_row_data" v-on:add_data="Add_Member()" />
            <DialogActionsBar>
                <div style="display:flex; justify-content:flex-end; margin-bottom:0px">
                    <Button style="width:58px;height:38px;" @click="createVipAccount()" :rounded="'medium'"
                        v-text="'確認'"></Button>
                    <Button style="width:58px;height:38px;" :rounded="'medium'" v-text="'取消'"
                        @click="Add_Member_Button(null)"></Button>
                </div>
            </DialogActionsBar>
        </Dialog>
        <Dialog v-if="add_post_visible" :width="'320px'" :height="'auto'" :title="'新增公告'" @close="Add_Post_Button(null)">
            <AddPost :select_row_data="select_row_data" />
            <DialogActionsBar>
                <div style="display:flex; justify-content:flex-end; margin-bottom:0px">
                    <Button style="width:58px;height:38px;" :rounded="'medium'" v-text="'確認'"></Button>
                    <Button style="width:58px;height:38px;" :rounded="'medium'" v-text="'取消'"
                        @click="Add_Post_Button(null)"></Button>
                </div>
            </DialogActionsBar>
        </Dialog>
        <Dialog v-if="add_subaccount_visible" :width="'400px'" :height="'auto'"
            :title="'子帳號 : ' + select_row_data['mAccountName']" @close="Add_Subaccount_Button()">
            <AddSubaccount :select_row_data="select_row_data" v-on:add_subaccount="add_data" />
            <DialogActionsBar>
                <div style="display:flex; justify-content:flex-end; margin-bottom:0px">
                    <Button style="width:58px;height:38px;" @click="createChildAccount()" :rounded="'medium'"
                        v-text="'確認'"></Button>
                    <Button style="width:58px;height:38px;" @click="Add_Subaccount_Button(null)" :rounded="'medium'"
                        v-text="'取消'"></Button>
                </div>
            </DialogActionsBar>
        </Dialog>
        <Dialog v-if="setting_subaccount_visible" :width="'500px'" :height="'400px'" :title="'子帳號設定'"
            @close="Setting_Subaccount_Button(null)">
            <SubaccountSetting :select_row_data="select_row_data" />
        </Dialog>
        <Dialog v-if="setting_password_visible" :width="'355px'" :height="'auto'" :title="'修改密碼'"
            @close="Setting_Password_Button(null)">
            <SettingPassword :select_row_data="select_row_data" />
            <DialogActionsBar>
                <div style="display:flex; justify-content:flex-end; margin-bottom:0px">
                    <Button style="width:58px;height:38px;" :rounded="'medium'" v-text="'確認'"></Button>
                    <Button style="width:58px;height:38px;" :rounded="'medium'" v-text="'取消'"></Button>
                </div>
            </DialogActionsBar>
        </Dialog>
        <Dialog v-if="history_visible" :width="'800px'" :height="'500px'" :title="'儲值 : ' + select_row_data['mAccountName']"
            @close="History_Stored_Button(null)">
            <HistoryStored :select_row_data="select_row_data" />
        </Dialog>
        <Dialog v-if="add_this_level_agent_visible" :width="'auto'" :height="'auto'" :title="'新增代理_2 : '"
            @close="Add_This_Level_Agent_Button()">
            <AddThisAgent :select_row_data="select_row_data" v-on:AccountManagement="AddAccountManagementData" />
            <DialogActionsBar>
                <div style="display:flex; justify-content:flex-end; margin-bottom:0px">
                    <Button style="width:58px;height:38px;" @click="CreatThisAccountManagement()" :rounded="'medium'"
                        v-text="'確認'"></Button>
                    <Button style="width:58px;height:38px;" :rounded="'medium'" v-text="'取消'"
                        @click="Add_This_Level_Agent_Button()"></Button>
                </div>
            </DialogActionsBar>
        </Dialog>
    </body>
</template>
<script>
import CreditsAmount_Dialog from '@/components/Account_Manage_Group/Dialog/CreditsAmount_Dialog';
import LastBalance_Dialog from '@/components/Account_Manage_Group/Dialog/LastBalance_Dialog';
import History_Stored_Dialog from '@/components/Account_Manage_Group/Dialog/History_Stored_Dialog';
import Subaccount_Setting_Dialog from '@/components/Account_Manage_Group/Dialog/Subaccount_Setting_Dialog';
import Setting_Password_Dialog from '@/components/Account_Manage_Group/Dialog/Setting_Password_Dialog'
import Add_Subaccount_Dialog from '@/components/Account_Manage_Group/Dialog/Add_Subaccount_Dialog'
import Add_Agent_Dialog from '@/components/Account_Manage_Group/Dialog/Add_Agent_Dialog'
import Add_This_Level_Agent_Dialog from '@/components/Account_Manage_Group/Dialog/Add_This_Level_Agent_Dialog'
import Add_Member_Dialog from '@/components/Account_Manage_Group/Dialog/Add_Member_Dialog'
import Setting_Member_Status_Dialog from '@/components/Account_Manage_Group/Dialog/Setting_Member_Status_Dialog'
import Setting_Status_Dialog from '@/components/Account_Manage_Group/Dialog/Setting_Status_Dialog'
import Add_Post_Dialog from '@/components/Account_Manage_Group/Dialog/Add_Post_Dialog'
import Agent_Setting_Dialog from '@/components/Account_Manage_Group/Dialog/Agent_Setting_Dialog';

import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
import { Checkbox, Input, RadioGroup, TextArea } from '@progress/kendo-vue-inputs';
import { Button, ButtonGroup } from '@progress/kendo-vue-buttons';
import { Window, Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import axios from 'axios';
import store from '../../store';
export default {
    name: 'Agent_List',
    components: {
        Grid,
        GridToolbar,
        Button,
        Dialog,
        DialogActionsBar,
        Input,

        'LastBalance': LastBalance_Dialog,
        'CreditsAmount': CreditsAmount_Dialog,
        'HistoryStored': History_Stored_Dialog,
        'SubaccountSetting': Subaccount_Setting_Dialog,
        'AgentSetting': Agent_Setting_Dialog,
        'SettingPassword': Setting_Password_Dialog,
        'AddSubaccount': Add_Subaccount_Dialog,
        'AddAgent': Add_Agent_Dialog,
        'AddThisAgent': Add_This_Level_Agent_Dialog,
        'AddMember': Add_Member_Dialog,
        'AddPost': Add_Post_Dialog,
        'SettingMemberStatus': Setting_Member_Status_Dialog,
        'SettingStatus': Setting_Status_Dialog

    },
    data: function () {
        return {

            select_row_data: [],
            selectedField: 'selected',
            selectedID: 1,
            datepicker_start: new Date(), //Ricky 不知道是啥 先新增除error//
            accountName_visible: false,
            creditsAmount_visible: false,
            lastBalance_visible: false,
            setting_status_visible: false,
            setting_membe_statusr_visible: false,
            add_agent_visible: false,
            add_member_visible: false,
            add_post_visible: false,
            add_subaccount_visible: false,
            setting_subaccount_visible: false,
            setting_password_visible: false,
            history_visible: false,
            add_this_level_agent_visible: false,
            ThisAccountManagement: {},
            AccountManagement: {
                guid: '',
                aAgentName: '',
                aAgentGuid: '',
                mAccountName: '',
                aCreditsAmount: '',
                aLastBalance: '',
                aStatus: '',
                aCategory: '',
                aProportion: '',
                aRent: '',
                aType: '',
                aAdd: '',
                aSetting: '',
                aHistory: '',
                aTime: '',
                aJoinTime: '',
                aEmail: '',
            },
            AccountManagements: [],
            post_dataItems: [
                {
                    label: "公告",
                    value: "公告",
                },
                {
                    label: "內部公告",
                    value: "內部公告",
                },
            ],


            search_word: "",
            gridhight: "",
            gridwidth: "",
            filter_calculation: 0,
            skip: 0,
            take: 10,
            currentPage: 1,
            filter_select_button: {},
            filter_buttons: [
                {
                    id: "all",
                    name: "所有下線",
                },
                {
                    id: "layer",
                    name: "當層下線",
                },
                {
                    id: "extend",
                    name: "延伸下線",
                },
            ],
            columns: [
                // { field: 'zero', cell: 'mzero', title: ' ', width: '46px', locked: true },
                // { field: 'mAgentNickName', cell: 'mAgentNickName', headerCell: 'head_mAgentNickName', title: '代理', width: '135px', locked: true, },
                // { field: 'mAccountName', cell: 'mAccountName', headerCell: 'head_mAccountName', title: '帳號', width: '146px', locked: true },

                // { field: 'mCredistAmout', cell: 'mCreditsAmount', headerCell: 'head_mCreditsAmount', title: '信用額度', width: '125px' },
                // { field: 'mLastBalance', cell: 'mLastBalance', headerCell: 'head_mLastBalance', title: '昨日餘額', width: '125px' },
                // { field: 'mStatus', cell: 'mStatus', headerCell: 'head_mStatus', title: '狀態', width: '160px' },
                // { field: 'mCategory', cell: 'mCategory', headerCell: 'head_mCategory', title: '類別', width: '50px' },
                // { field: 'mProportion', cell: 'mProportion', headerCell: 'head_mProportion', title: '佔成', width: '50px' },
                // { field: 'mRent', cell: 'mRent', headerCell: 'head_mRent', title: '租金', width: '50px' },
                // { field: 'mSubaccount', cell: 'mSubaccount', headerCell: 'head_mSubaccount', title: '類型', width: '60px' },
                // { field: 'mAdd', cell: 'mAdd', headerCell: 'head_mAdd', title: '新增', width: '230px' },
                // { field: 'mSetting', cell: 'mSetting', headerCell: 'head_mSetting', title: '設定', width: '140px' },
                // { field: 'mHistory', cell: 'mHistory', headerCell: 'head_mHistory', title: '歷史', width: '50px' },
                { field: 'aAccountName', title: '帳號' , width: '200px'},
                { field: 'nickName', title: '暱稱' , width: '200px'},
                { field: 'aEmail', title: 'Email' , width: '200px'},
                { field: 'aAgentName', title: '上層代理' , width: '200px'},
                { field: 'lastDefaultPasswd', title: '預設密碼' , width: '200px'},
            ],

            // 新增子帳號/代理用 coconut
            add_agent: {
                mCurrent_AccountName: "",
                mLower_AccountName: "",
                mLower_AccountLevel: "",
                mLower_AccountPassword: "",
                mLower_AccountNickname: "",
                mSubaccount: "",
                mProportion: 0,
                mRent: 0,
                mQuote: "",
            },
            // 新增子帳號/代理用 coconut
            // 新增會員用 coconut
            add_user: {
                mCurrent_AccountName: "",
                mLower_AccountName: "",
                mLower_AccountLevel: "",
                mLower_AccountPassword: "",
                mLower_AccountNickname: "",
                mSubaccount: "",
                mProportion: 0,
                mRent: 0,
                mQuote: "",
            },
            // 新增會員用 coconut


            // 新增子帳號/會員用 Ricky
            addVipInfo: {
                guid: '',
                name: '',
                password: '',
                func: '',
            },
            // 新增子帳號/會員用 Ricky
            // 新增代理用 Ricky
            addAgentInfo: {
                guid: '',
                name: '',
                password: '',
                nickName: '',

            },
            // 新增代理用 Ricky
        }
    },
    computed: {
        result: {
            get: function () {
                console.log(this.$store.getters.AgentListData)

                return this.$store.getters.AgentListData;
            }
        },
        total() {
            return this.$store.getters.AgentListData.totalRows;
        },
        selectedItem() {
            return this.result[0];
        }
    },
    mounted() {
        //this.refreshGrid()


        const myObserver = new ResizeObserver(entries => {
            this.gridhight = document.getElementById('Agent_List').clientHeight;
            this.gridwidth = document.getElementById('Agent_List').clientWidth;
        });
        const TabStrip = document.getElementById('Agent_List');
        myObserver.observe(TabStrip, {
            box: "content-box",
        });
        this.filter_select_button = this.filter_buttons[0];
        this.GetThisLevelAgent()
    },
    methods: {
        GetThisLevelAgent() {
            axios.get(this.$httpPath + '/AgentManager/agentAccount/3')
                .then((response) => {
                    if (response.status == 200) {
                        this.$store.state.AgentListData = response.data
                        console.log(this.$store.getters.AgentListData)
                    }
                })
                .catch((error) => {
                    this.message = "發生錯誤"
                    console.log(error)
                }).finally(() => {
                });
        },
        AddAccountManagementData(AccountManagement) {
            console.log(AccountManagement);
            this.ThisAccountManagement = AccountManagement;
        },
        CreatThisAccountManagement() {
            this.ThisAccountManagement.isChild = false;
            axios.post(this.$httpPath + '/AgentManager/agentAccount', this.ThisAccountManagement)
                .then((response) => {
                    if (response.status == 200) {
                        this.message = response.data
                        this.message_visible = true;
                    }
                })
                .catch((error) => {
                    this.message = "發生錯誤"
                }).finally(() => {
                });
        },
        createThisLevelAgentAccount() {
            console.log(this.add_agent)
            axios.post(this.$httpPath + '/AgentManager/CreatAgentInfo', this.add_agent)
                .then((response) => {
                    if (response.status == 200) {
                        this.message = response.data
                        this.message_visible = true;
                    }
                    if (response.status == 215) {
                        this.message = response.data
                        this.message_visible = true;
                    }
                })
                .catch((error) => {
                    this.message = "發生錯誤"
                }).finally(() => {
                });
        },
        scrollHandler(event) {
            const e = event;
            // if (e.target.scrollTop + 10 >= e.target.scrollHeight - e.target.clientHeight) {

            // }
            //console.log(event.target.scrollHeight);
        },
        onSelectClick(item) {
            console.log(item);
        },
        onRowClick(event) {
            console.log(event.dataItem);
            event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
        },
        //
        CreatAgentInfo() {
            this.select_row_data = null;
            this.add_agent_visible = !this.add_agent_visible;
            console.log(this.add_agent)
            axios.post(this.$httpPath + '/AgentManager/CreatAgentInfo', this.add_agent)
                .then((response) => {
                    if (response.status == 200) {
                        this.message = response.data
                        this.message_visible = true;
                    }
                    if (response.status == 215) {
                        this.message = response.data
                        this.message_visible = true;
                    }
                })
                .catch((error) => {
                    this.message = "發生錯誤"
                }).finally(() => {
                });
        },
        //
        createAgentAccount() {// 新增代理用 Ricky
            console.log("createVipAccount")
            var vm = this
            this.addAgentInfo.name = "T" + this.addAgentInfo.name;
            axios.post(this.$httpPath + '/AgentManager/firstAgent', this.addAgentInfo)
                .then((response) => {
                    if (response.status == 200) {
                        Vue.$toast.open({
                            message: '新增完成。',
                            position: 'bottom-right',
                            type: 'success'
                        });
                    }
                    else if (response.status == 215) {
                        Vue.$toast.open({
                            message: '帳號已被使用。',
                            position: 'top',
                            type: 'error'
                        });
                    }
                    else {
                        Vue.$toast.open({
                            message: '新增錯誤。' + response,
                            position: 'top',
                            type: 'error'
                        });
                    }
                })
                .catch((error) => {
                    Vue.$toast.open({
                        message: '新增錯誤。001',
                        position: 'top',
                        type: 'error'
                    });
                }).finally(() => {
                });
        },// 新增代理用 Ricky
        createVipAccount() {// 新增子帳號用 Ricky
            console.log("createVipAccount")
            var vm = this
            this.addVipInfo.name = "T" + this.addVipInfo.name;
            axios.post(this.$httpPath + '/AgentManager/secAgent/2', this.addVipInfo) //secAgent/1 1:新增子帳號
                .then((response) => {
                    if (response.status == 200) {
                        Vue.$toast.open({
                            message: '新增完成。',
                            position: 'bottom-right',
                            type: 'success'
                        });
                        vm.accountName_visible = false
                    }
                    else if (response.status == 215) {
                        Vue.$toast.open({
                            message: '帳號已被使用。',
                            position: 'top',
                            type: 'error'
                        });
                    }
                    else {
                        Vue.$toast.open({
                            message: '新增錯誤。' + response,
                            position: 'top',
                            type: 'error'
                        });
                    }
                })
                .catch((error) => {
                    Vue.$toast.open({
                        message: '新增錯誤。001',
                        position: 'top',
                        type: 'error'
                    });
                }).finally(() => {
                });
        },// 新增子帳號用 Ricky
        createChildAccount() {// 新增子帳號用 Ricky
            console.log("createVipAccount")
            var vm = this
            this.addVipInfo.name = "T" + this.addVipInfo.name;
            axios.post(this.$httpPath + '/AgentManager/secAgent/1', this.addVipInfo) //secAgent/1 1:新增子帳號
                .then((response) => {
                    if (response.status == 200) {
                        Vue.$toast.open({
                            message: '新增完成。',
                            position: 'bottom-right',
                            type: 'success'
                        });
                        vm.accountName_visible = false
                    }
                    else if (response.status == 215) {
                        Vue.$toast.open({
                            message: '帳號已被使用。',
                            position: 'top',
                            type: 'error'
                        });
                    }
                    else {
                        Vue.$toast.open({
                            message: '新增錯誤。' + response,
                            position: 'top',
                            type: 'error'
                        });
                    }
                })
                .catch((error) => {
                    Vue.$toast.open({
                        message: '新增錯誤。001',
                        position: 'top',
                        type: 'error'
                    });
                }).finally(() => {
                });
        },// 新增子帳號用 Ricky
        customHandler() {
            console.log("15656")
        },
        Add_Agent(add_agent_data) {
            console.log(add_agent_data)
            this.add_agent = add_agent_data
            /*暫放 代理 接收回傳的function*/
        },
        Add_member() {
            /*暫放 新增會員 接收回傳的function*/
        },
        AccountName_Button(data) {
            this.select_row_data = data;
            //console.log(this.select_row_data)
            this.accountName_visible = !this.accountName_visible;
            //console.log(this.accountName_visible);
            //console.log(123);
            //this.refreshGrid();
        },
        CreditsAmount_Button(data) {
            this.select_row_data = data;
            this.creditsAmount_visible = !this.creditsAmount_visible;
            //console.log(this.select_row_data)
            //console.log(this.select_row_data.mAccountName)
        },
        LastBalance_Button(data) {
            this.select_row_data = data;
            this.lastBalance_visible = !this.lastBalance_visible;
        },
        Setting_Status_Button(data) {
            this.select_row_data = data;
            this.setting_status_visible = !this.setting_status_visible;
        },
        Status_Member_Button(data) {
            this.select_row_data = data;
            this.setting_membe_statusr_visible = !this.setting_membe_statusr_visible;
        },
        Add_Agent_Button(data) {
            this.add_agent = "";
            this.select_row_data = data;
            console.log(this.select_row_data)
            this.add_agent_visible = !this.add_agent_visible;
        },
        Add_Member_Button(data) {
            this.select_row_data = data;
            this.add_member_visible = !this.add_member_visible;
        },
        Add_This_Level_Agent_Button() {
            this.add_this_level_agent_visible = false;
        },
        Add_Post_Button(data) {
            this.select_row_data = data;
            this.add_post_visible = !this.add_post_visible;
        },
        Add_Subaccount_Button(data) {
            this.select_row_data = data;
            this.add_subaccount_visible = !this.add_subaccount_visible;
        },
        Setting_Subaccount_Button(data) {
            this.select_row_data = data;
            this.setting_subaccount_visible = !this.setting_subaccount_visible;
        },
        Setting_Password_Button(data) {
            this.select_row_data = data;
            this.setting_password_visible = !this.setting_password_visible;
        },
        History_Stored_Button(data) {
            this.select_row_data = data;
            this.history_visible = !this.history_visible;
        },

        Filter_Change(key_word_id) {
            this.filter_select_button = this.filter_buttons.find(b => b.id == key_word_id);

        },
        pageChangeHandler: function (event) {
            console.log(event)
            this.skip = event.page.skip;
            this.take = event.page.take;
            this.currentPage = (event.page.skip / this.take) + 1,
            this.refreshGrid();
        },
        find_odd(data, element) {
            var e = data.indexof(element) % 2 == 0;
            console.log(e)
            return e;
        },
        refreshGrid() {
            // axios.get(this.$httpPath + '/AgentManager/secAgent/1/' + this.take + '/' + this.currentPage, {
            //     params: {

            //     }
            // })
            //     .then((response) => {
            //         if (response.status === 200) {
            //             this.AccountManagements = response.data;
            //             console.log(this.AccountManagements)
            //         }
            //     })
            axios.get(this.$httpPath + '/AgentManager/agentAccount/3?currentPage='+this.currentPage+'&pageSize='+this.take)
                .then((response) => {
                    if (response.status == 200) {
                        this.$store.state.AgentListData = response.data
                        console.log(this.$store.getters.AgentListData)
                    }
                })
                .catch((error) => {
                    this.message = "發生錯誤"
                    console.log(error)
                }).finally(() => {
                });
        },
    }
};
</script>
<style scoped>
.body {
    height: 100%;
    width: 100%;
    display: inline-flex;
    color: #fff;
    font-family: "微軟正黑體";
    flex-direction: row;
    flex-wrap: nowrap;
}

.gridtoolbar {
    height: auto;
    padding: 0px 5px;
    background-color: #0c4d70;
    color: #fff;
    display: inline-flex;
    flex-direction: row
}

.myfloatleft {
    flex: 1;
    display: inline-flex;
    align-items: center;
}

.myfloatleft div {
    margin: 0px 5px;
}

.borader {
    display: inline-flex;
    background-color: #198754;
    border-radius: 4px;
    overflow: hidden;
    justify-content: center;
    color: #fff;
    margin: 3px;
    border: 1px;
    padding: 3px 6px;
    vertical-align: middle;
}

Button {
    height: 100%;
    width: auto;
    color: #fff;
    background-color: #17a2b8;
    padding: 2px 5px;
    margin: 3px;
}

.k-window-content:last-child {
    background-color: #17a2b8;
    padding: 0px;
}
</style>
