<template>
    <body id ="Order_Record" class="body">
        <Grid 
            ref="grid"
            :style="{height: gridhight+'px', width: gridwidth+'px'}"
            :data-items="example_data"
            :column-virtualization="true"
            :columns = "columns"

            :pageable="true"
            :skip="skip"
            :take="take"
            :total="total"
            >
            <GridToolbar class="gridtoolbar">
                <div>
                    <span style="padding-top:2px;font-size:18px; vertical-align: middle;" v-text="'日期'"></span>
                    <Button :rounded="'medium'" :size="'small'" v-text="'搜索'" ></Button>
                    <DatePicker
                        style="margin:3px"
                        :size="'small'"
                        :rounded="'large'"
                        :default-value="new Date()"
                        :max="new Date()"
                        @change="OnChange_Date($event,'start')"
                        :format="'yyyy/MM/dd'"
                        >
                    </DatePicker>
                    <Button size="small" v-text="'EXCEL'"></Button>
                    <Button size="small" v-text="'下載'"></Button>
                </div>
            </GridToolbar>
        </Grid>
    </body>
</template>
<script>
import { DatePicker } from '@progress/kendo-vue-dateinputs';
import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
import { Button, ButtonGroup } from '@progress/kendo-vue-buttons';
export default {
    name: 'Order_Record', 
      components: {
        Grid,
        GridToolbar,
        DatePicker,
        Button,
    },   
    data: function () {
        return {
            gridhight:"",
            gridwidth:"",
            datepicker_start:"",
            skip: 0,
            take: 10,
            total: 0,
            columns:[
                { field: 'zero', title: ' ', width: '40px'},
                { field: 'a', title: '會員', width: '100%'},
                { field: 'b', title: '日期', width: '100%'},
                { field: 'c', title: '昨日餘額', width: '55px'},
                { field: 'd', title: '今日損益', width: '100%'},
                { field: 'e', title: '餘額', width: '100%'},
                { field: 'f', title: '手續費', width: '100%'},
                { field: 'g', title: '費用', width: '100%'},
                { field: 'h', title: '口數', width: '100%'},
                { field: 'i', title: '出金', width: '100%'},
                { field: 'j', title: '入金', width: '100%'},
                { field: 'k', title: '超爆', width: '100%'},
            ],
            example_data:[{
                "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
            },
            ],
        }
    },
    mounted(){
        const myObserver = new ResizeObserver(entries => {
            this.gridhight=document.getElementById('TabStrip').getElementsByTagName('div')[1].clientHeight-40;
            this.gridwidth=document.getElementById('TabStrip').getElementsByTagName('div')[1].clientWidth-34;
            
        });
        const TabStrip = document.getElementById('TabStrip');
        myObserver.observe(TabStrip, {
            box: "content-box",
        });
    },
    methods: {

    }
};
</script>
<style scoped>
.body{
    height:100%;
    width:100%;
    display: inline-flex;
    color:#fff;
    font-family:"微軟正黑體";
    flex-direction: row;
    flex-wrap: nowrap;
}
.gridtoolbar{
    height: auto;
    padding: 0px 5px;
    background-color: #0c4d70;
    color: #fff;
    display: inline-flex;
    flex-direction:row  
}
.myfloatleft{
    flex:1;
    display: inline-flex;
    align-items: center;
}
.myfloatleft div{
    margin: 0px 5px;
}
.borader{
    display: inline-flex;
    background-color: #198754;
    border-radius:4px;
    overflow: hidden;
    justify-content: center;
    color: #fff;
    margin: 3px;
    border: 1px;
    padding: 3px 6px;
    vertical-align: middle;
}
Button{
    height:100%;
    width:auto;
    color: #fff;
    background-color: #17a2b8;
    padding: 2px 5px;
    margin: 3px;
}
</style>
