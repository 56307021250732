<template>
    <body id = 'Setting_Status_Dialog'>
        <RadioGroup style="font-size:16px;" :data-items="status_setting_radiogroup" :default-value="select_row_data['mStatus']" />
    </body>
</template>
<script>

import { Button, ButtonGroup } from '@progress/kendo-vue-buttons';
import { Checkbox, Input, RadioGroup, TextArea } from '@progress/kendo-vue-inputs';
export default {
    name: 'Setting_Status_Dialog', 
      components: {
        RadioGroup,
    },   
    props: {
        select_row_data:{},
    }, 
    data: function () {
        return {
            status_setting_radiogroup_checked:"",
            status_setting_radiogroup:[
                {
                    label: "正常",
                    value: "正常",
                },
                {
                    label: "禁新",
                    value: "禁新",
                },
                {
                    label: "凍結",
                    value: "凍結",
                },
                {
                    label: "停用",
                    value: "停用",
                },
            ],
        }
    },
    mounted(){
        
    },
    methods: {

        
    },
};
</script>
<style scoped>
body{
    height:100%; 
    margin : -16px;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    padding: 16px;
}
.gridtoolbar{
    height: 40px;
    width:100%;
    padding: 0px 0px;
    border: 0px;
    background-color: #0c4d70;
    color: #fff;
    display: inline-flex;
    
}
.bar{
    flex :1;
    width:100%;
    display: inline-flex;
    flex-direction: column;
}
.center{
    flex :1;
    width:100%;
    padding: 0px 0px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
}
.DatePicker
{
    margin:3px; 
    font-size:12px; 
    width:120px;
}
.DatePicker > span
{
    margin:0px; 
    
}
Button{
    height:100%;
    width:auto;
    color: #fff;
    background-color: #17a2b8;
    padding: 2px 5px;
    margin: 3px;
}
</style>
