<template>
    <body id ="Order_Monitor" class="body">
        <Splitter
            id="Splitter"
            :style="{height:'100%', width:'100%', border:'0px'}"
            :orientation="'vertical'"
            @change="onVerticalChange"
            :panes="verticalpanes" 
            >
            <template v-slot:top>
                <Member_List></Member_List>
            </template>
            <template v-slot:bottom>
                <Order_Monitor_List></Order_Monitor_List>
            </template>
        </Splitter>
    </body>
</template>
<script>
import { TabStrip, TabStripTab, Splitter } from '@progress/kendo-vue-layout';
import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
import { Button, ButtonGroup } from '@progress/kendo-vue-buttons';
import { Checkbox, Input, RadioGroup } from '@progress/kendo-vue-inputs';
import { DropDownList } from '@progress/kendo-vue-dropdowns';
import test from '@/components/group/flex_test.vue'
import Member_List from '@/components/Order_Monitor_Group/Member_List.vue'
import Order_Monitor_List from '@/components/Order_Monitor_Group/Order_Monitor_List.vue'
export default {
    name: 'Order_Monitor', 
      components: {
        Splitter,
        Member_List,
        Order_Monitor_List,
        
    },   
    data: function () {
        return {
            member_list_filter_calculation:0,
            filter_total_profit_and_loss:0,
            filter_total_Lot:0,
            
            member_list_columns_dropdownlist_value:'',
            member_list_inequality_dropdownlist_value:'',
            member_list_sort_dropdownlist_value:"",
            member_list_filter:0,

            member_list_columns_dropdownlist:[
                { text: '餘額', id: 1 },
                { text: '今日損益', id: 2 },
                { text: '信用額度', id: 3 },
                { text: '總空', id: 4 },
                { text: '總多', id: 5 },
                { text: '未平多', id: 6 },
                { text: '未平空', id: 7 },
                { text: '入金', id: 8 },
                { text: '出金', id: 9 },
                { text: '費用', id: 10 },
                { text: '手續費', id: 11 },
            ],
            member_list_inequality_dropdownlist:[
                { text: '大於', id: 1 },
                { text: '小於', id: 2 },
                { text: '等於', id: 3 },
                { text: '大於等於', id: 4 },
                { text: '小於等於', id: 5 },
                { text: '不等於', id: 6 },
            ],
            member_list_sort_dropdownlist:[
                { text: '不排序', id: 1 },
                { text: '由大到小', id: 2 },
                { text: '由小到大', id: 3 },
            ],

            select_layer: [
            {
                label: "延伸會員",
                value: "extend",
            },
            {
                label: "當層會員",
                value: "current",
            },
            ],
            verticalpanes: [
                { content: 'top', size: '50%', scrollable: false,},
                { content: 'bottom', scrollable: false,},
            ],
        }
    },
    mounted(){
        this.member_list_columns_dropdownlist_value = this.member_list_columns_dropdownlist[0];
        this.member_list_inequality_dropdownlist_value = this.member_list_inequality_dropdownlist[0];
        this.member_list_sort_dropdownlist_value = this.member_list_sort_dropdownlist[0]
    },
    methods: {
        onVerticalChange(event) {
            //console.log(event.newState)
            this.verticalpanes = event.newState;
        }, 
    }
};
</script>
<style scoped>
.body{
    height:100%;
    width:100%;
    margin: 0px;
    border: 0px;
    display: inline-flex;
    color:#fff;
    font-family:"微軟正黑體";
    flex-direction: row;
    flex-wrap: nowrap;
    flex-direction: column;
}
.gridtoolbar{
    height: auto;
    padding: 0px 5px;
    background-color: #0c4d70;
    color: #fff;
    display: inline-flex;
    flex-direction:row
}
.myfloatleft{
    flex:1;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
}
.myfloatleft div{
    margin: 0px 5px;
    display: inline-flex; 
    align-items:center; 
    justify-content: flex-start;
    overflow: hidden;
    flex-direction:row; 
    white-space:nowrap;
}
.myfloatright{
    width:auto;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
}
.borader{
    display: inline-flex;
    background-color: #198754;
    border-radius:4px;
    overflow: hidden;
    justify-content: center;
    color: #fff;
    margin: 3px;
    border: 1px;
    padding: 3px 6px;
    vertical-align: middle;
}
Button{
    height:100%;
    width:auto;
    color: #fff;
    background-color: #17a2b8;
    padding: 2px 5px;
    margin: 3px;
}
.checkbox{
    margin-right: 10px;
}

.mybadge {
    position: relative;
    top: -2px;
    padding: 2px 3px;
    margin-right: -3px;
}
</style>
