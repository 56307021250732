<template>
    <body id = 'Add_Agent_Dialog'>
        <div style="display:flex;height:35px; width:100%; margin:5px 0px;align-items:center; justify-content:flex-start;">
                <div style="justify-content:flex-end;" v-text="'名稱'"></div>
                <Input 
                    style="margin-left:34px; font-size:16px; border-radius:4px; border:1px solid #ced4da;"
                    :style="{ width: '268px', height:'33px'}"
                    v-model="add_agent.mLower_AccountNickname"
                    :placeholder="'中英文皆可'"/>
        </div>
        <div style="display:flex;height:35px; width:100%; margin:5px 0px;align-items:center; justify-content:flex-start;">
            <div style="justify-content:flex-end;white-space: nowrap;" v-text="'帳號'"></div>
            <div style="display:flex; justify-content:space-end; margin-left:34px;">
                <div
                    style="display:flex; padding:0px 10px; background-color:#e9ecef; align-items:center; font-weight:bold; font-size:16px; border-radius:4px 0px 0px 4px; color:blue; border:1px solid #ced4da;"
                    :style="{ width: '108px', height:'33px'}"
                    v-text="TransformASCII(select_row_data['mAccountLevel'])"/>
                <Input 
                    style="font-size:16px; border-radius:0px 4px 4px 0px; border:1px solid #ced4da;"
                    :style="{ width: '160px', height:'33px'}"
                    v-model="add_agent.mLower_AccountName"
                    :placeholder="'請輸入數字或英文'"/>
                <Button style="width:76px;height:30px;" :rounded="'medium'" v-text="'檢查帳號'" @click="check_user()"></Button>
            </div>
        </div>
        <div style="display:flex;height:35px; width:100%; margin:5px 0px;align-items:center; justify-content:flex-start;">
            <div style="justify-content:flex-end;" v-text="'密碼'"></div>
            <Input 
                style=" margin-left:34px; font-size:16px; border-radius:4px; border:1px solid #ced4da;"
                v-model="add_agent.mLower_AccountPassword"
                :style="{ width: '268px', height:'33px'}"/>
        </div>
        <div style="display:flex;height:35px; width:100%; margin:5px 0px;align-items:center; justify-content:flex-start;">
            <div style="justify-content:flex-end;" v-text="'佔成'"></div>
            <Input 
                    style="margin-left:34px; font-size:16px; border-radius:4px; border:1px solid #ced4da;"
                    :style="{ width: '108px', height:'33px'}"
                    v-model="add_agent.mProportion"
                    :default-value="select_row_data['mProportion']"/>
            <div v-text="'<=0'"></div>
        </div>
        <div style="display:flex;height:35px; width:100%; margin:5px 0px; align-items:center; justify-content:flex-start;">
            <div style="justify-content:flex-end;" v-text="'租金'"></div>
            <Input 
                style="margin-left:34px; font-size:16px; border-radius:4px; border:1px solid #ced4da;"
                :style="{ width: '108px', height:'33px'}"
                v-model="add_agent.mRent"
                :default-value="select_row_data['mRent']"/>
            <div v-text="'>=0'"></div>
        </div>
        <div style="display:flex;height:35px; width:100%; margin:5px 0px; align-items:center; justify-content:flex-start;">
            <span v-text="'商品報價'"/>
            <RadioGroup 
                style="margin-left:7px"
                :data-items="quote_dataItems"
                :layout="'horizontal'"
                v-model="add_agent.mQuote"
                :default-value="select_row_data['mQuote']"/>
        </div>
        <div style="height:25px;justify-content:flex-start; margin:10px 0px;">
            <span style="text-align:left; float:left; width:100%; background-color: #fff3cd; border-radius:4px; color:#856404; padding:3px;">
                整數模式為商品報價 無小數點
            </span>
        </div>
        <div style="height:25px; justify-content:flex-start; margin:10px 0px;">
            <span style="text-align:left; float:left; width:100%; background-color: #fff3cd; border-radius:4px; color:#856404; padding:3px">
                完整模式為商品報價 有小數點
            </span>
        </div>
        <Dialog 
            v-if="message_visible" 
            :width="'300px'"
            :height="'125px'"
            :title="'訊息'" 
            @close="Message_Button(null)">
            <div style="height:100%; width:100%; display: inline-flex; align-items: center; justify-content: center;">
                <div style="font-size:16px; font-weight: bold;" v-text="message" />
            </div>
        </Dialog>
    </body>
</template>
<script>
import { Checkbox, Input, RadioGroup, TextArea } from '@progress/kendo-vue-inputs';
import { Button, ButtonGroup } from '@progress/kendo-vue-buttons';
import { Window, Dialog, DialogActionsBar} from '@progress/kendo-vue-dialogs';
import axios from 'axios';
export default {
    name: 'Add_Agent_Dialog', 
      components: {
        Button,
        Input,
        RadioGroup,
        Dialog,

    },   
    props: {
        select_row_data:{},
    }, 
    watch:{
        add_agent: {
            handler(val, oldVal){
                console.log(this.add_agent)
                this.$emit('add_data', this.add_agent)
            },
            deep: true
        },
    },
    data: function () {
        return {
            aa:{
                accountname:''
            },
            message_visible:false,
            message:"",
            add_agent:{
                mCurrent_AccountName:this.select_row_data['mAccountName'],
                mLower_AccountName:"",
                mLower_AccountLevel:"",
                mLower_AccountPassword:"",
                mLower_AccountNickname:"",
                mSubaccount:"",
                mProportion:this.select_row_data['mProportion'],
                mRent:this.select_row_data['mRent'],
                mQuote:this.select_row_data['mQuote'],
            },
            quote_dataItems: [
            {
                label: "完整模式",
                value: "完整模式",
            },
            {
                label: "整數模式",
                value: "整數模式",
            },
            ],
        }
    },
    mounted(){
        
    },
    methods: {
        TransformASCII(level){
            this.add_agent.mLower_AccountLevel = String.fromCharCode(level.charCodeAt() + 1)
            return this.add_agent.mLower_AccountLevel
        },
        Message_Button(){
            this.message_visible = ! this.message_visible;
            this.message = '';
        },
        check_user(){
            console.log(this.add_agent)
            axios.get(this.$httpPath+'/AgentManager/CheckAccountHasBeenUsed?accountname='+this.add_agent.mLower_AccountName)
                    .then((response) => {
                        if(response.status==200)
                        {
                             this.message = response.data
                             this.message_visible = true;
                        }
                    })
                    .catch((error) => {
                        this.message = "發生錯誤"
                    }).finally(() => {
            }); 
        }
    },
};
</script>
<style scoped>
body{
    height:100%; 
    margin : -16px;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    padding:16px;
}
.gridtoolbar{
    height: 80px;
    width:100%;
    padding: 0px 0px;
    border: 0px;
    background-color: #0c4d70;
    color: #fff;
    display: inline-flex;
    
}
.bar{
    flex :1;
    width:100%;
    display: inline-flex;
    flex-direction: column;
}
.center{
    flex :1;
    width:100%;
    padding: 0px 0px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
}
.DatePicker
{
    margin:3px; 
    font-size:12px; 
    width:120px;
}
.DatePicker > span
{
    margin:0px; 
    
}
Button{
    height:100%;
    width:auto;
    color: #fff;
    background-color: #17a2b8;
    padding: 2px 5px;
    margin: 3px;
}
</style>
