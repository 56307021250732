<template>
    <body id="Order_Monitor_List" class="body">
        <TabStrip 
                id="TabStrip" 
                class="tabstrip" 
                :selected="tabstrip_selected" 
                @select="TabStrip_OnSelect"
                >
                <TabStripTab 
                    id="TabStripTab" 
                    class="tabstriptab" 
                    titleRender="title"
                    disabled
                    >
                    <template v-slot:title>
                        <span class="namebackground">T321</span>
                    </template>
                </TabStripTab>
                <TabStripTab 
                    id="TabStripTab" 
                    class="tabstriptab" 
                    titleRender="title"
                    >
                    <template v-slot:title>
                        <span>
                            <span class="tabstriptab_font" >全部單</span>
                            <span class="redbadge" v-text="'0'"></span>
                        </span>
                    </template>
                    <All_Orders></All_Orders>
                </TabStripTab>
                <TabStripTab 
                    id="TabStripTab" 
                    class="tabstriptab" 
                    titleRender="title"
                    >
                    <template v-slot:title>
                        <span>
                            <span class="tabstriptab_font" >未平倉</span>
                            <span class="redbadge" v-text="'0'"></span>
                            <span class="greenbadge" v-text="'0'"></span>
                        </span>
                    </template>
                    <Non_Hirakura></Non_Hirakura>
                </TabStripTab>
                <TabStripTab 
                    id="TabStripTab" 
                    class="tabstriptab" 
                    titleRender="title"
                    >
                    <template v-slot:title>
                        <span>
                            <span class="tabstriptab_font" >已平倉</span>
                        </span>
                    </template>
                    <Closed></Closed>
                </TabStripTab>
                <TabStripTab 
                    id="TabStripTab" 
                    class="tabstriptab" 
                    titleRender="title"
                    >
                    <template v-slot:title>
                        <span>
                            <span class="tabstriptab_font" >統計</span>
                        </span>
                    </template>
                    <Statistics></Statistics>
                </TabStripTab>
                <TabStripTab 
                    id="TabStripTab" 
                    class="tabstriptab" 
                    titleRender="title"
                    >
                    <template v-slot:title>
                        <span>
                            <span class="tabstriptab_font" >委託回報</span>
                        </span>
                    </template>
                    <Entrust_Return></Entrust_Return>
                </TabStripTab>
                <TabStripTab 
                    id="TabStripTab" 
                    class="tabstriptab" 
                    titleRender="title"
                    >
                    <template v-slot:title>
                        <span>
                            <span class="tabstriptab_font" >未成交委託(全部會員)</span>
                            <span class="redbadge" v-text="'0'"></span>
                        </span>
                    </template>
                    <Unfilled_Entrust></Unfilled_Entrust>
                </TabStripTab>
                <TabStripTab 
                    id="TabStripTab" 
                    class="tabstriptab" 
                    titleRender="title"
                    >
                    <template v-slot:title>
                        <span>
                            <span class="tabstriptab_font" >成交回報(全部會員)</span>
                            <span class="redbadge" v-text="'0'"></span>
                        </span>
                    </template>
                    <Transaction_Return></Transaction_Return>
                </TabStripTab>
                <TabStripTab 
                    id="TabStripTab" 
                    class="tabstriptab" 
                    titleRender="title"
                    >
                    <template v-slot:title>
                        <span>
                            <span class="tabstriptab_font" >歷史報價</span>
                        </span>
                    </template>
                    <Historical_Quotes></Historical_Quotes>
                </TabStripTab>
                <TabStripTab 
                    id="TabStripTab" 
                    class="tabstriptab" 
                    titleRender="title"
                    >
                    <template v-slot:title>
                        <span>
                            <span class="tabstriptab_font" >市場總覽</span>
                        </span>
                    </template>
                    <Overview></Overview>
                </TabStripTab>
                <TabStripTab 
                    id="TabStripTab" 
                    class="tabstriptab" 
                    titleRender="title"
                    >
                    <template v-slot:title>
                        <span>
                            <span class="tabstriptab_font" >登入信息</span>
                        </span>
                    </template>
                    <Login_Message></Login_Message>
                </TabStripTab>
                <TabStripTab 
                    id="TabStripTab" 
                    class="tabstriptab" 
                    titleRender="title"
                    >
                    <template v-slot:title>
                        <span>
                            <span class="tabstriptab_font" >線上客服</span>
                            <span class="redbadge" v-text="'0'"></span>
                        </span>
                    </template>
                    <Customer_Service></Customer_Service>
                </TabStripTab>
                <TabStripTab 
                    id="TabStripTab" 
                    class="tabstriptab" 
                    titleRender="title"
                    >
                    <template v-slot:title>
                        <span>
                            <span class="tabstriptab_font" >提領餘額</span>
                            <span class="redbadge" v-text="'0'"></span>
                        </span>
                    </template>
                    <Withdrawal></Withdrawal>
                </TabStripTab>
                <TabStripTab 
                    id="TabStripTab" 
                    class="tabstriptab" 
                    titleRender="title"
                    >
                    <template v-slot:title>
                        <span>
                            <span class="tabstriptab_font" >開盤風控</span>
                        </span>
                    </template>
                    <Risk_Control></Risk_Control>
                </TabStripTab>
        </TabStrip> 
    </body>
</template>
<script>
import { TabStrip, TabStripTab, Splitter } from '@progress/kendo-vue-layout';
import All_Orders from'@/components/Order_Monitor_Group/Order_Monitor_List_Group/All_Orders.vue'
import Non_Hirakura from'@/components/Order_Monitor_Group/Order_Monitor_List_Group/Non_Hirakura.vue'
import Closed from'@/components/Order_Monitor_Group/Order_Monitor_List_Group/Closed.vue'
import Statistics from'@/components/Order_Monitor_Group/Order_Monitor_List_Group/Statistics.vue'
import Entrust_Return from'@/components/Order_Monitor_Group/Order_Monitor_List_Group/Entrust_Return.vue'
import Unfilled_Entrust from'@/components/Order_Monitor_Group/Order_Monitor_List_Group/Unfilled_Entrust.vue'
import Transaction_Return from'@/components/Order_Monitor_Group/Order_Monitor_List_Group/Transaction_Return.vue'
import Historical_Quotes from'@/components/Order_Monitor_Group/Order_Monitor_List_Group/Historical_Quotes.vue'
import Overview from'@/components/Order_Monitor_Group/Order_Monitor_List_Group/Overview.vue'
import Login_Message from'@/components/Order_Monitor_Group/Order_Monitor_List_Group/Login_Message.vue'
import Customer_Service from'@/components/Order_Monitor_Group/Order_Monitor_List_Group/Customer_Service.vue'
import Withdrawal from'@/components/Order_Monitor_Group/Order_Monitor_List_Group/Withdrawal.vue'
import Risk_Control from'@/components/Order_Monitor_Group/Order_Monitor_List_Group/Risk_Control.vue'
export default {
    name: 'Order_Monitor_List', 
      components: {
        TabStrip, 
        TabStripTab, 
        All_Orders,
        Non_Hirakura,
        Closed,
        Statistics,
        Entrust_Return,
        Unfilled_Entrust,
        Transaction_Return,
        Historical_Quotes,
        Overview,
        Login_Message,
        Customer_Service,
        Withdrawal,
        Risk_Control,
    },   
    data: function () {
        return {
            tabstrip_selected: 1,
            myTemplate:"132",
        }
    },
    mounted(){
        //this.select_button=this.l_buttons[0]
    },
    methods: {
         TabStrip_OnSelect(e){
            //console.log(e)
            this.tabstrip_selected = e.selected;
        },
    }
};
</script>
<style scoped>
.body{
    height:100%;
    width:100%;
    margin: 0px;
    border: 0px;
    display: inline-flex;
    color:#94dbff;
    font-family:"微軟正黑體";
    flex-direction: column;
}

.tabstrip{
    display: inline-flex;
    flex:1;
    width:100%;
    padding: 10px 10px 10px 10px;
    background-color: #fff;
    color: #fff;
}
.tabstriptab{
    background-color: #17a2b8;
}

.tabstriptab_font{
    color: #008cba;
}
.namebackground{
    height: 100%;
    width: 100%;
    color: #424242;
    background-color: #ffc107;
    border-radius:4px ;
    font-size: 14px;
    font-weight:bold;
    vertical-align:center;
    opacity:1;
}
.redbadge{
    height: 100%;
    width: 100%;
    color: #fff;
    background-color: #dc3545;
    border-radius:4px ;
    font-size: 12px;
    font-weight:bold;
    vertical-align:center;
    padding: 1px 3px;
}
.greenbadge{
    height: 100%;
    width: 100%;
    color: #fff;
    background-color: #28a745;
    border-radius:4px ;
    font-size: 12px;
    font-weight:bold;
    vertical-align:center;
    padding: 1px 3px;
}
:deep .k-disabled{
    opacity:1;
}
</style>
