<template>
    <body id="Account_Manage" class="body">
        <div class="account_manage">
            <TabStrip 
                id="TabStrip" 
                class="tabstrip" 
                :selected="tabstrip_selected" 
                @select="TabStrip_OnSelect"
                >
                <TabStripTab 
                    id="TabStripTab" 
                    class="tabstriptab" 
                    titleRender="title"
                    >
                    <template v-slot:title style=" z-index: 1;">
                        <span class="tabstriptab_font" v-text="tabstrip_selected_title[0]"></span>
                    </template>
                    <Splitter
                        id="Splitter"
                        :style="{height:splitterhight +'px', width:splitterwidth +'px'}"
                        :orientation="'vertical'"
                        @change="onVerticalChange"
                        :panes="verticalpanes"
                    >
                        <template v-slot:top>
                            <Agent_List></Agent_List>
                        </template>
                        <template v-slot:bottom>
                            <Member_List></Member_List>
                        </template>
                    </Splitter>
                </TabStripTab>
                <TabStripTab 
                    id="TabStripTab" 
                    class="tabstriptab" 
                    titleRender="title"
                    :style="{height:'100%', width:'100%'}"
                    >
                    <template v-slot:title>
                        <span class="tabstriptab_font" v-text="tabstrip_selected_title[1]"></span>
                    </template>
                    <Order_Record></Order_Record>
                </TabStripTab>
            </TabStrip>
        </div>
    </body>
</template>
<script>

import { TabStrip, TabStripTab, Splitter } from '@progress/kendo-vue-layout';
import { DatePicker } from '@progress/kendo-vue-dateinputs';
import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
import { Checkbox, Input } from '@progress/kendo-vue-inputs';
import { Button, ButtonGroup } from '@progress/kendo-vue-buttons';
import Agent_List from '@/components/Account_Manage_Group/Agent_List.vue';
import Member_List from '@/components/Account_Manage_Group/Member_List.vue';
import Order_Record from '@/components/Account_Manage_Group/Order_Record.vue';

export default {
    name:"Account_Manage",
    components:{
        TabStrip,
        TabStripTab,
        Splitter,

        Agent_List,
        Member_List,
        Order_Record,
    },
    mounted(){
        const myObserver = new ResizeObserver(entries => {
            this.splitterhight=document.getElementById('TabStrip').getElementsByTagName('div')[1].clientHeight-40;
            this.splitterwidth=document.getElementById('TabStrip').getElementsByTagName('div')[1].clientWidth-34;
            //console.log(entries);
            //console.log(document.getElementById('TabStrip').getElementsByTagName('div')[1].clientHeight-40)
            //console.log(document.getElementById('TabStrip').getElementsByTagName('div')[1].clientWidth-34)
            //console.log(document.getElementById('TabStrip').clientWidth)
            //console.log(this.splitterhight)
            //console.log(this.splitterwidth)
        });
        const TabStrip = document.getElementById('TabStrip');
        myObserver.observe(TabStrip, {
            box: "content-box",
        });Order_Record
    },
    methods:{
        TabStrip_OnSelect(e){
            //console.log(e)
            this.tabstrip_selected = e.selected;
        },
        onVerticalChange(event) {
            //console.log(event.newState)
            this.verticalpanes = event.newState;
        }, 
    },
    data() {
        return {
            tabstrip_selected: 0,
            tabstrip_selected_title:["會員管理","下單紀錄"],
            splitterhight:"",
            splitterwidth:"",
            verticalpanes: [
                { content: 'top', size: '50%', scrollable: false,},
                { content: 'bottom', scrollable: false,},
            ],
        }
    },
}
</script>

<style scoped>

.body{
    height:100%;
    width:100%;
    display: inline-flex;
    color:#fff;
    font-family:"微軟正黑體";
    flex-direction: row;
    flex-wrap: nowrap;
}
.account_manage{
    height:100%;
    margin: 0px;
    width:100%;
    display: inline-flex;
    color:#fff;
    font-family:"微軟正黑體";
}
.tabstrip{
    display: inline-flex;
    flex:1;
    width:100%;
    padding: 10px 10px 10px 10px;
    background-color: #fff;
    color: #fff;
}
.tabstriptab{
    background-color: #17a2b8;
}

.tabstriptab_font{
    color: #008cba;
}
.k-tabstrip-items-wrapper{
    z-index: 1;
}
</style>