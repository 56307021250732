<template>
    <body id="Group" class="body">
        <div class= "grouptoper" >
            <div class="myfloatleft ">
                <Button
                    :rounded="'large'"
                    :size="'small'"
                    v-for="button in l_buttons"
                    :togglable="true"
                    :key="button.id"
                    :selected="select_button.id === button.id"
                    @click="GroupVueChange(button.id)"
                    >
                    <svg width=1em height=1em viewBox="0 0 16 16" fill=currentColor xmlns=http://www.w3.org/2000/svg>
                        <path fill-rule=evenodd v-bind:d="button.svg_1"></path>
                        <path fill-rule=evenodd v-bind:d="button.svg_2"></path>
                    </svg>
                    <span>{{ button.name }}</span>
                </Button>              
            </div>
            <div class="myfloatright">
                <Button
                    style="k-button"
                    v-for="button in r_buttons"
                    :key="button.id"
                    @click="(e) => LoginOut()"
                    >
                    <svg style="color:#c12424; width:1em; height:1em;"  width=1em height=1em viewBox="0 0 16 16" fill=currentColor xmlns=http://www.w3.org/2000/svg>
                        <path fill-rule=evenodd v-bind:d="button.svg_1"></path>
                        <path fill-rule=evenodd v-bind:d="button.svg_2"></path>
                    </svg>
                    <span>{{ button.name }}</span>
                </Button> 
            </div>
        </div>
        <div class= "groupcenter">
            <Component v-bind:is="currentview_vue"></Component> 
        </div>
        
    </body>
</template>
<script>
import Store from '@/store/index.js'
import Classification_Form from '@/components/group/Classification_Form.vue'
import Account_Manage from '@/components/group/Account_Manage.vue'
import Order_Monitor from '@/components/group/Order_Monitor.vue'
import Group_Statistic from '@/components/group/Group_Statistic.vue'
import Other_Operate from '@/components/group/Other_Operate.vue'
import Charity from '@/components/group/Charity.vue'

import { Button } from '@progress/kendo-vue-buttons';
export default {
    name: 'Group', 
      components: {
        Button,
        "classification_from":Classification_Form,
        "account_manage":Account_Manage,
        "order_monitor":Order_Monitor,
        "group_statistic":Group_Statistic,
        "other_operate": Other_Operate,
        "charity":Charity,
    },   
    data: function () {
        return {
            currentview_vue:"classification_from",
            select_button:{},
            l_buttons:[
                {
                    id: "classification_from",
                    name: "分類報表",
                    svg_1: "M2 1h12a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zm12-1a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z",
                    svg_2: "M5 15V1H4v14h1zm8-11.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 .5-.5zm0 3a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 .5-.5zm0 3a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 .5-.5zm0 3a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 .5-.5z",
                },
                {
                    id: "account_manage",
                    name: "帳號管理",
                    svg_1: "M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zM8.5 5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1zM0 11.5A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z",
                    svg_2: "",
                },
                {
                    id: "order_monitor",
                    name: "下單監看",
                    svg_1: "M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z",
                    svg_2: "M9.5 1h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3zm4.354 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z",
                },
                {
                    id: "group_statistic",
                    name: "群組統計",
                    svg_1: "M6 1H1v3h5V1zM1 0a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1zm14 12h-5v3h5v-3zm-5-1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-5zM6 8H1v7h5V8zM1 7a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H1zm14-6h-5v7h5V1zm-5-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1h-5z",
                    svg_2: "",
                },
                {
                    id: "other_operate",
                    name: "其它操作",
                    svg_1: "M14.5 3h-13a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z",
                    svg_2: "M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z",
                },
                {
                    id: "charity",
                    name: "慈善專區",
                    svg_1: "M8 2.748l-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z",
                    svg_2: "",
                },
            ],
            r_buttons:[
                {
                    id: "login_out",
                    name: "退出系統",
                    svg_1: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                    svg_2: "M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z",
                },
            ],
            login_out_svg_1:"M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
            login_out_svg_2:"M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z",
        }
    },
    mounted(){
        this.select_button=this.l_buttons[0]
    },
    methods: {
        LoginOut(){
            this.$store.dispatch("logout");
        },
        GroupVueChange(vue_id) {
            this.select_button = this.l_buttons.find(b => b.id == vue_id);
            this.currentview_vue = this.select_button.id;
        },
    }
};
</script>
<style scoped>
.glay{
    color:#a7a7a7
}
.yellow{
    color:#fceda1
}
.body{
    height:100%;
    width:100%;
    display: inline-flex;
    color:#fff;
    font-family:"微軟正黑體";
    flex-direction: row;
    flex-wrap: nowrap;
    flex-direction: column;
}
.myfloatleft{
    width:100%;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
}
.myfloatright{
    width:auto;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    white-space: nowrap;
}
.grouptoper{
    display: inline-flex;
    height:auto;
    width: 100%;
    overflow:hidden;
    color:#94dbff;
    padding: 1px 10px;
    border-bottom:solid 1px #001c31;
    background-color:#106184;
    background-image:linear-gradient(to bottom,#106184 0,#08385a 100%)
}
.groupcenter{
    flex:1;
    border: 0px;
    background-color: #106184;
}
.k-button{
    padding: 2px 8px;
    margin: 3px 5px;
    
}
</style>
