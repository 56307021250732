<template>
    <body id ="All_Orders" class="body">
        <Grid 
            ref="grid"
            :style="{height: gridhight+'px', width: gridwidth+'px'}"
            :data-items="result"
            :column-virtualization="true"
            :columns = "columns"
            :pageable="true"
            :skip="skip"
            :take="take"
            :total="total"
            @pagechange="pageChangeHandler"
            >
            <GridToolbar class="gridtoolbar">
                <div class="myfloatleft">
                </div>
                <div class="myfloatright">
                    <Button size="small" v-text="'EXCEL'"></Button>
                </div>
            </GridToolbar>
        </Grid>
    </body>
</template>
<script>
import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
import { Button, ButtonGroup } from '@progress/kendo-vue-buttons';
import axios from 'axios';

export default {
    name: 'All_Orders', /*全部單*/ 
      components: {
        Grid,
        GridToolbar,
        Button,
    },   
    data: function () {
        return {
            gridhight:"",
            gridwidth:"",
            datepicker_start:"",

            filter: {
                logic: "and",
                filters: [],
            },
            sort: [
                { field: 'aAccountName', dir: 'asc' },
            ],
            skip: 0,
            take: 10,
            currentPage:1,
            AllDealInfo:{},
            AllDealInfos:[],

            columns:[
                { field: 'zero', title: ' ', width: '40px', locked: true},
                { field: 'zero_1', title: ' ', width: '40px', locked: true},
                { field: 'zero_2', title: ' ', width: '40px', locked: true},
                { field: 'userName', title: '會員A', width: '100%', locked: true},
                { field: 'productName', title: '商品', width: '100%', locked: true},
                { field: 'invert', title: '倒', width: '100%'},
                { field: 'longOrShort', title: '多空', width: '100%'},
                { field: 'price', title: '委託價', width: '100%'},
                { field: 'amount', title: '口數', width: '100%'},
                { field: 'dealPrice', title: '成交價', width: '100%'},
                { field: 'orderTime', title: '下單時間', width: '200px'},
                { field: 'dealTIme', title: '完成時間', width: '100%'},
                { field: 'type', title: '類別', width: '100%'},
                { field: 'stopLoss', title: '損失點', width: '100%'},
                { field: 'takeProfit', title: '獲利點', width: '100%'},
                { field: 'dealStatus', title: '狀態', width: '100%'},
                { field: 'number', title: '序號', width: '400px'},
            ],
        }
    },
    computed: {
        result: {
            get: function() {
                console.log(this.AllDealInfos.data)
                return this.AllDealInfos.data;
            }
        },
        total () {
            return this.AllDealInfos.totalRows;
        },  
    },
    mounted(){
        this.refreshGrid()
        const myObserver = new ResizeObserver(entries => {
            this.gridhight=document.getElementById('TabStrip').getElementsByTagName('div')[1].clientHeight-40;
            this.gridwidth=document.getElementById('TabStrip').getElementsByTagName('div')[1].clientWidth-33;
            console.log(document.getElementById('TabStrip').getElementsByTagName('div')[1].offsetHeight)
            console.log(this.gridwidth)
        });
        const TabStrip = document.getElementById('TabStrip');
        myObserver.observe(TabStrip, {
            box: "content-box",
        });
    },
    methods: {
        pageChangeHandler: function(event) {
            this.skip = event.page.skip;
            this.take = event.page.take;
            this.currentPage = (event.page.skip/this.take)+1,
            this.refreshGrid();
        },
        refreshGrid()
        {  
            axios.get(this.$httpPath+'/Trade/deal/'+this.take+'/'+this.currentPage, {
                params:{
                    
                }})
                .then((response)=>{
                    if(response.status===200)
                    {
                        this.AllDealInfos=response.data;
                        console.log(this.AllDealInfos)
                    }
                })
        },
    }
};
</script>
<style scoped>
.body{
    height:100%;
    width:100%;
    display: inline-flex;
    color:#fff;
    font-family:"微軟正黑體";
    flex-direction: row;
    flex-wrap: nowrap;
}
.gridtoolbar{
    height: auto;
    padding: 0px 5px;
    background-color: #0c4d70;
    color: #fff;
    display: inline-flex;
    flex-direction:row  
}
.myfloatleft{
    flex:1;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
}
.myfloatright{
    width:auto;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
}
.borader{
    display: inline-flex;
    background-color: #198754;
    border-radius:4px;
    overflow: hidden;
    justify-content: center;
    color: #fff;
    margin: 3px;
    border: 1px;
    padding: 3px 6px;
    vertical-align: middle;
}
Button{
    height:100%;
    width:auto;
    color: #fff;
    background-color: #17a2b8;
    padding: 2px 5px;
    margin: 3px;
}
</style>
