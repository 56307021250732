<template>
    <body id ="Login" class="body login">
        <div id="LoginBox" class="loginbox">
            <div class="myfloatleft">
                <span class="title" v-text="'創兆管理'"></span>
                <span class="min-title" v-text="'v 測試'"></span>
            </div>
            <div class="myfloatright">
                <div style="width: 60%;flex-direction: column;justify-content: center; align-items: center; ">
                    <Input 
                        :style="{margin:'10px 0px',padding:'0px' }" 
                        :size="'medium'" 
                        :placeholder="'帳號'"
                        :icon-name="'k-icon k-i-user'"
                        v-model="logindata.email" ></Input>
                    <Input 
                        :style="{ margin:'10px 0px',padding:'0px' }" 
                        :size="'medium'" 
                        :placeholder="'密碼'"
                        :type="'password'"
                        :icon-name="'k-icon k-i-lock'" 
                        v-model="logindata.password"
                        ></Input>
                </div>
                <div style="width: 60%;flex-direction: row;justify-content: center; align-items: center; ">
                    <Checkbox :size="'medium'" class="checkbox" :default-checked="false" :label="'儲存帳號'" ></Checkbox>
                    <span >&nbsp;&nbsp;&nbsp;</span>
                    <DropDownList
                            :style="{ width: 'auto', margin:'2px'}"
                            :data-items="dropdownlist"
                            :text-field="'text'"
                            :data-item-key="'id'"
                            v-model="logindata.model"
                        ></DropDownList>
                </div>
            </div >
        </div>
        <div id="LoginClick" class="loginclick">
            <Button @click="Login_Click" v-text="'登入'"></Button>
        </div>
    </body>
</template>
<script>
import { Button } from '@progress/kendo-vue-buttons';
import { DropDownList } from '@progress/kendo-vue-dropdowns';
import { Checkbox, Input, RadioGroup } from '@progress/kendo-vue-inputs';
 import {Monitorin_MQ_Initialization_Connect, User_MQ_Initialization_Connect, MQ_DisConnect} from '@/store/RabbitMQ.js';
import { Encrypt, Decrypt } from '@/utils/aes.js'//Ricky: AES
import axios from 'axios';
export default {
    name: 'Login', 
      components: {
        Button,
        Input,
        Checkbox,
        DropDownList,
    },   
    data: function () {
        return {
            encrypt_logindata:{
                email:'', 
                password:'', 
                model:'',
            },
            logindata:{
                email:'', 
                password:'', 
                model:'',
            },
            dropdownlist:[
                { text: '選擇', id: 1 },
                { text: '正式', id: 2 },
                { text: '測試', id: 3 },
            ],
        }
    },
    mounted(){
        this.logindata.model = this.dropdownlist[0]
        axios.get(this.$httpPath+'/AgentManager/checkLogin')
            .then((response)=>{
                if(response.status===200)
                {
                    this.$store.dispatch('fetchUser');
                    console.log('登入正確');
                }
            })
    },
    methods: {
        Login_Click(){
            this.encrypt_logindata.email = Encrypt(this.logindata.email)
            this.encrypt_logindata.password = Encrypt(this.logindata.password)
            this.encrypt_logindata.model = Encrypt(this.logindata.model)
            console.log(this.encrypt_logindata)
            this.$store.dispatch('logIn', this.encrypt_logindata);
        }
    }
};
</script>
<style scoped>
.body{
    height:100%; 
    margin: 0px;
    display: flex;
    flex-direction: column;
    color: #FFF;
}
.login{
    flex:1;
    display: inline-flex;
    flex-direction: column;
    background-color: #073052;
    justify-content: center; 
    align-items: center; 
    overflow: hidden;
    flex-wrap: nowrap;
}
.loginbox{
    flex:1;
    display: inline-flex;
    background:url("../assets/img/board02.jpg") no-repeat;
    border-radius:8px ;
    border-color:#fff ;
    border-style:solid;
    border-width: 10px;
    margin: 10px;
    padding: 0px;
    width:614px;
    max-height:239px;
    box-shadow: 12px 12px 7px rgba(0, 0, 0, 0.7);
}
.myfloatleft{
    display: inline-flex;
    max-height: inherit;
    flex:3;
    border-color:#fff ;
    flex-direction: column;
    border-width: 2px;
    justify-content: center; 
    border-style:solid;

}
.title{
    width: auto;
    font-family:微軟正黑體;
    font-weight:bold;
    font-size: 24px;
    color: #fff;
    text-align: center; 
}
.min-title{
    width: auto;
    font-family:微軟正黑體;
    font-size: 16px;
    color: #fff;
    text-align: right; 
    margin-right:15px ;
}
.myfloatright{
    width: 50%;
    flex-direction: column;
    display: inline-flex;
    max-height: inherit;
    flex:7;
    border-color:#fff ;
    border-style:solid;
    border-width: 2px;   
    justify-content: center; 
    align-items: center; 
    
}
.loginclick{
    width:280px;
    height:50px;
    margin:0 auto;
    margin-top:10px;
}
Button{
    font-family:微軟正黑體;
    font-size: 20px;
    height:100%;
    width:100%;
    color: #fff;
    background-color: #428bca;
    padding: 2px 5px;
    margin: 3px;
}
.a{
    width:100%;
    height: 40px;
    background-color: aquamarine;
}
.b{
    flex:1;
    background-color: rgb(255, 127, 127);
}
.c{
    width:100%;
    height: 40px;
    background-color: rgb(255, 127, 212);
}
</style>
