<template>
    <body id ="Customer_Service" class="body">
        <Grid 
            ref="grid"
            :style="{height: gridhight+'px', width: gridwidth+'px'}"
            :data-items="example_data"
            :column-virtualization="true"
            :columns = "columns"
            >
            <GridToolbar class="gridtoolbar">
                <div class="myfloatleft">
                    <span >&nbsp;&nbsp;&nbsp;</span>
                    <div>
                        <span class="messagebackground" v-text="message_amount"></span>
                    </div>
                    <span style="font-size:14px; vertical-align: middle; white-space:nowrap;" v-text="'則新訊息'"></span>
                </div>
                <div class="myfloatright">
                    <Button size="small" v-text="'EXCEL'"></Button>
                </div>
            </GridToolbar>
        </Grid>
    </body>
</template>
<script>
import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
import { Button, ButtonGroup } from '@progress/kendo-vue-buttons';
export default {
    name: 'Customer_Service', /*線上課服 */
      components: {
        Grid,
        GridToolbar,
        Button,
    },   
    data: function () {
        return {
            gridhight:"",
            gridwidth:"",
            datepicker_start:"",
            message_amount:0,

            columns:[
                { field: 'zero', title: ' ', width: '40px'},
                { field: 'a', title: '會員', width: '100%'},
                { field: 'b', title: '新訊息', width: '100%'},
                { field: 'c', title: '待接收', width: '100%'},
                { field: 'd', title: '最後時間', width: '100%'},
                { field: 'zero', title: ' ', width: '40px'},
                { field: 'zero', title: ' ', width: '40px'},
            ],
            example_data:[{
                "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
                "m":"範例",
                "n":"範例",
            },
            ],
        }
    },
    mounted(){
        const myObserver = new ResizeObserver(entries => {
            this.gridhight=document.getElementById('TabStrip').getElementsByTagName('div')[1].clientHeight-40;
            this.gridwidth=document.getElementById('TabStrip').getElementsByTagName('div')[1].clientWidth-33;
            console.log(document.getElementById('TabStrip').getElementsByTagName('div')[1].offsetHeight)
            console.log(this.gridwidth)
        });
        const TabStrip = document.getElementById('TabStrip');
        myObserver.observe(TabStrip, {
            box: "content-box",
        });
    },
    methods: {

    }
};
</script>
<style scoped>
.body{
    height:100%;
    width:100%;
    display: inline-flex;
    color:#fff;
    font-family:"微軟正黑體";
    flex-direction: row;
    flex-wrap: nowrap;
}
.gridtoolbar{
    height: auto;
    padding: 0px 5px;
    background-color: #0c4d70;
    color: #fff;
    display: inline-flex;
    flex-direction:row  
}
.myfloatleft{
    flex:1;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
}
.myfloatright{
    width:auto;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
}
.borader{
    display: inline-flex;
    background-color: #198754;
    border-radius:4px;
    overflow: hidden;
    justify-content: center;
    color: #fff;
    margin: 3px;
    border: 1px;
    padding: 3px 6px;
    vertical-align: middle;
}
Button{
    height:100%;
    width:auto;
    color: #fff;
    background-color: #17a2b8;
    padding: 2px 5px;
    margin: 3px;
}
.messagebackground{
    height: 100%;
    width: 100%;
    color: #424242;
    background-color: #f5c6cb;
    border-radius:4px ;
    padding: 2px 5px;
    font-size: 14px;
    font-weight:bold;
    vertical-align:center;
    opacity:1;
}
</style>
