<template >
    <div id=bottompane>
        <table>
            <tbody>
                <tr>
                    <td v-text="columnsdata[0].title"> </td>
                    <td>
                      <span class="namebackground" v-text="this.$store.getters.MemberProfilePane.mMember_AccountName"></span>
                    </td>
                </tr>
                <tr>
                    <td v-text="columnsdata[1].title"> </td>
                    <td>
                        <img v-if="this.$store.getters.MemberProfilePane.mStatus == '上限'" src="../assets/img/defaultLeafIconOff_A_Stop.png">
                        <img v-if="this.$store.getters.MemberProfilePane.mStatus == '正常'" src="../assets/img/defaultLeafIconOff.png">
                        <img v-if="this.$store.getters.MemberProfilePane.mStatus == '禁新'" src="../assets/img/defaultLeafIconOff_A_Ban.png">
                        <img v-if="this.$store.getters.MemberProfilePane.mStatus == '凍結'" src="../assets/img/defaultLeafIconOff_A_Stop.png">
                        <img v-if="this.$store.getters.MemberProfilePane.mStatus == '停用'" src="../assets/img/defaultLeafIconOff_A_Stop.png">
                        <span v-text="this.$store.getters.MemberProfilePane.mStatus"></span>
                    </td>
                </tr>
                <tr>
                    <td v-text="columnsdata[2].title"> </td>
                    <td>
                        <span v-text="this.$store.getters.MemberProfilePane.mQuote"></span>
                    </td>
                </tr>
                <tr>
                    <td v-text="columnsdata[3].title"> </td>
                    <td>
                        <span v-text="this.$store.getters.MemberProfilePane.mCategory"></span>
                    </td>
                </tr>
                <tr>
                    <td v-text="columnsdata[4].title"> </td>
                    <td>
                      <span v-text="this.$store.getters.MemberProfilePane.mAgent_AccountNickName"></span>
                    </td>
                </tr>
                <tr>
                    <td v-text="columnsdata[5].title"> </td>
                    <td>
                      <span v-text="this.$store.getters.MemberProfilePane.mAgent_AccountName"></span>
                    </td>
                </tr>
                <tr>
                    <td v-text="columnsdata[6].title"> </td>
                    <td>
                      <span v-text=" new Date(this.$store.getters.MemberProfilePane.mMember_JoinTime).toLocaleDateString()"></span>
                    </td>
                </tr>
                <tr>
                    <td v-text="columnsdata[7].title"> </td>
                    <td>
                      <span v-text="this.$store.getters.MemberProfilePane.mCredistAmout" ></span>
                    </td>
                </tr>
                <tr>
                    <td v-text="columnsdata[8].title"> </td>
                    <td>
                      <span v-if="this.$store.getters.MemberProfilePane.mExtreme_Limit == '-1' " v-text="'未設定'"></span>
                      <span v-if="this.$store.getters.MemberProfilePane.mExtreme_Limit !='-1'" v-text="this.$store.getters.MemberProfilePane.mExtreme_Limit" ></span>
                    </td>
                </tr>
                <tr>
                    <td v-text="columnsdata[9].title"> </td>
                    <td>
                      <span v-text="this.$store.getters.MemberProfilePane.mCalculate_Extreme" ></span>
                    </td>
                </tr>
                <tr>
                    <td v-text="columnsdata[10].title"> </td>
                    <td>
                      <span v-if="this.$store.getters.MemberProfilePane.mDaily_Settlement == '-1' " v-text="'未設定'"></span>
                      <span v-if="this.$store.getters.MemberProfilePane.mDaily_Settlement != '-1' " v-text="this.$store.getters.MemberProfilePane.mDaily_Settlement" ></span>
                    </td>
                </tr>
                <tr>
                    <td v-text="columnsdata[11].title"> </td>
                    <td>
                      <span v-text="this.$store.getters.MemberProfilePane.mAccount_Name" ></span>
                    </td>
                </tr>
                <tr>
                    <td v-text="columnsdata[12].title"> </td>
                    <td>
                      <span v-text="this.$store.getters.MemberProfilePane.mContact_Number" ></span>
                    </td>
                </tr>
                <tr>
                    <td v-text="columnsdata[13].title"> </td>
                    <td>
                      <span v-text="this.$store.getters.MemberProfilePane.mPosition_Limit"></span>
                    </td>
                </tr>
                <tr>
                    <td v-text="columnsdata[14].title"></td>
                    <td>
                      <span v-if="this.$store.getters.MemberProfilePane.mLimit_Per_Transaction == '-1' " v-text="'未設定'"></span>
                      <span v-if="this.$store.getters.MemberProfilePane.mLimit_Per_Transaction != '-1' " v-text="this.$store.getters.MemberProfilePane.mLimit_Per_Transaction"></span>
                    </td>
                </tr>

                <tr>
                    <td v-text="columnsdata[15].title"> </td>
                    <td>
                      <span v-text="this.$store.getters.MemberProfilePane.mTomekura_Limit" ></span>
                    </td>
                </tr>
                <tr>
                    <td v-text="columnsdata[16].title"> </td>
                    <td>
                      <span v-text="this.$store.getters.MemberProfilePane.mSmallest_Lot" ></span>
                    </td>
                </tr>
                <tr>
                    <td v-text="columnsdata[17].title"> </td>
                    <td>
                      <span v-text="this.$store.getters.MemberProfilePane.mMargin_Per_Lot" ></span>
                    </td>
                </tr>
                <tr>
                    <td v-text="columnsdata[18].title"> </td>
                    <td>
                      <span v-text="this.$store.getters.MemberProfilePane.mWithdraw_Balance"  ></span>
                    </td>
                </tr>
                <tr>
                    <td v-text="columnsdata[19].title"> </td>
                    <td>
                      <span v-text="this.$store.getters.MemberProfilePane.mMatching" ></span>
                    </td>
                </tr>
                <tr>
                    <td v-text="columnsdata[20].title"> </td>
                    <td >
                      <span v-text="this.$store.getters.MemberProfilePane.mBalance_Sheet_Show" ></span>
                    </td>
                </tr>
                <tr>
                    <td v-text="columnsdata[21].title"> </td>
                    <td>
                      <span v-if="this.$store.getters.MemberProfilePane.nScalping_Restrictions == '停用' " v-text="'停用'"></span>
                      <span v-if="this.$store.getters.MemberProfilePane.nScalping_Restrictions == '啟用_1' " v-text="'啟用'" ></span>
                      <span v-if="this.$store.getters.MemberProfilePane.nScalping_Restrictions == '啟用_2' " v-text="'啟用'"></span>
                    </td>
                </tr>
                <tr>
                    <td v-text="columnsdata[22].title"> </td>
                    <td>
                      <span v-text="this.$store.getters.MemberProfilePane.mOrder_Box_C" ></span>
                    </td>
                </tr>
                <tr>
                    <td v-text="columnsdata[23].title"> </td>
                    <td>
                      <span v-text="this.$store.getters.MemberProfilePane.mLightning_Single_Box"  ></span>
                    </td>
                </tr>
                <tr>
                    <td v-text="columnsdata[24].title"> </td>
                    <td>
                      <span v-text="this.$store.getters.MemberProfilePane.mLimit_Profit"></span>
                    </td>
                </tr>
                <tr>
                    <td v-text="columnsdata[25].title"> </td>
                    <td>
                      <span v-text="this.$store.getters.MemberProfilePane.mMarket_Loss_And_Profit" ></span>
                    </td>
                </tr>
                <tr>
                    <td v-text="columnsdata[26].title"> </td>
                    <td>
                      <span v-text="this.$store.getters.MemberProfilePane.mLast_Place_Order" ></span>
                    </td>
                </tr>
                <tr>
                    <td v-text="columnsdata[27].title"> </td>
                    <td>
                      <span v-text="this.$store.getters.MemberProfilePane.mLast_Login_Time" ></span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import Store from '@/store/index.js'
export default {
    name:"MemberProfilePane",
    data: function () {
        return {
        columnsdata: [
        {
          'title': '會員編號',
          'detail': '範例'
        }, 
        {
          'title': '狀態',
          'detail': '範例'
        }, 
        {
          'title': '商品報價',
          'detail': '範例'
        }, 
        {
          'title': '屬性',
          'detail': '範例'
        }, 
        {
          'title': '代理名稱',
          'detail': '範例'
        }, 
        {
          'title': '代理',
          'detail': '範例'
        }, 
        {
          'title': '建立日期',
          'detail': '範例'
        }, 
        {
          'title': '信用額度',
          'detail': '範例'
        }, 
        {
          'title': '極贏額度',
          'detail': '範例'
        },
        {
          'title': '計算極贏',
          'detail': '範例'
        },
        {
          'title': '日結金額',
          'detail': '範例'
        }, 
        {
          'title': '帳戶名稱',
          'detail': '範例'
        }, 
        {
          'title': '聯絡電話',
          'detail': '範例'
        }, 
        {
          'title': '持倉上限',
          'detail': '範例'
        },
        {
          'title': '每筆上限',
          'detail': '範例'
        },
        {
          'title': '留倉上限',
          'detail': '範例'
        },
        {
          'title': '最小口數',
          'detail': '範例'
        },
        {
          'title': '每口保證金',
          'detail': '範例'
        },
        {
          'title': '提領餘額',
          'detail': '範例'
        },
        {
          'title': '撮合自設',
          'detail': '範例'
        },
        {
          'title': '對帳表不顯示',
          'detail': '範例'
        },
        {
          'title': '剝頭皮限制',
          'detail': '範例'
        },
        {
          'title': '下單匣C',
          'detail': '範例'
        },
        {
          'title': '閃電下單匣',
          'detail': '範例'
        },
        {
          'title': '限損利',
          'detail': '範例'
        },
        {
          'title': '市損利',
          'detail': '範例'
        },
        {
          'title': '最後下單',
          'detail': '範例'
        },
        {
          'title': '最後登入',
          'detail': '範例'
        },  ],
            }
    },
}
</script>
<style scoped>
    table{
      border-collapse:collapse;
      background-color:rgb(255,255,255);
      table-layout: fixed;
      position:absolute;
      overflow:hidden;
      width: 100%;
      font-family: "Helvetica Neue",Helvetica,Arial,sans-serif,Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
      font-size:12px;
      color:#000;
    }
    table tr td{
      white-space: nowrap;
      overflow:hidden;
      text-overflow:ellipsis;
      width: 100%;
      border:1px solid #ddd;
      padding:3px;
      color:#5a5a5a
    }
    span{
      font-weight:bold;
      color:#000
    }
    #bottompane table {
      width: 100%;
    }
    .namebackground{
      display: inline-block;
      color: #000;
      background-color: #ffc107;
      border-radius:4px ;
      font-size: 12px;
      font-weight:bold;
      vertical-align:center;
      opacity:1;
      padding: 1px 3px;
    }
</style>