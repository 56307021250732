<template>
    <body id=AgentListPane class="body">
      <div class= "treetoper" >
        <div class="agent_title">
          <div id=agent_title>
            <span>代理列表</span>
            :
            <span style="margin-right:30px" v-text="agent_name"></span>
          </div>
        </div>
        <div id=treesbtnaa class="treesbtnaa" @click="expandAll()">
          <svg xmlns=http://www.w3.org/2000/svg width=16 height=16 fill=currentColor class="bi bi-chevron-double-left" viewBox="0 0 16 16">
           <path fill-rule=evenodd v-bind:d="treesbtnaa.treesbtnaa_svg_1"></path>
          </svg>
        </div>
        <div id=treesOnOff class="treesOnOff" @click="treesOnOff_click" >
          <svg xmlns=http://www.w3.org/2000/svg width=16 height=16 fill=currentColor class="bi bi-chevron-double-left" viewBox="0 0 16 16">
          <path fill-rule=evenodd v-bind:d="treesOnOff.treesOnOff_svg_1"></path>
          <path fill-rule=evenodd v-bind:d="treesOnOff.treesOnOff_svg_2"></path>
          </svg>
        </div>
      </div>
      <div class= "treecenter">
        <TreeView 
          class="treeview"
          :animate="false"
          :size="'small'"
          :dataItems="this.$store.getters.AgentTreeData"
          :expandIcons="true"
          :text-field="'text'"
          :children-field="'items'"
          @itemclick="onItemChange"
          @expandchange="onExpandChange"
          >
        </TreeView>
      </div>
    </body>   
</template>
<script>
import { TreeView, processTreeViewItems, handleTreeViewCheckChange, } from '@progress/kendo-vue-treeview';
import Store from '../store/index.js';
import axios from "axios";
export default {

  name: 'AgentListPane',
  components: {
    TreeView
  },    

  watch:{
    AgentTreeData_SelectedItem()
    {
      this.selectedItem = this.$store.state.AgentTreeData_SelectedItem
      //console.log(this.selectedItem)
    }
  },
  computed: {
    AgentTreeData_SelectedItem()
    {
      return this.$store.state.AgentTreeData_SelectedItem
    }
    
  },
  data: function () {
    return {
      nowuser:'',
      selectedItem: this.$store.state.AgentTreeData_SelectedItem,
      expandAll_bool:false,
      treetoper_width:{
        OnOff:true,
        min:0,
        max:0,
      },
      agent_name:"尚未登入正確使用者",
      treesOnOff:{
        treesOnOff_button:true,
        treesOnOff_svg_1:"M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z",
        treesOnOff_svg_2:"M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z",
      },
      treesbtnaa:{
        treesOnOff_button:true,//為了一致性暫定
        treesbtnaa_svg_1:"M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zM8.5 5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1zM0 11.5A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z",
        treesbtnaa_svg_2:"",   //為了一致性暫定
      },
      treeData:this.$store.getters.AgentTreeData,
      treeDatas: [
        {
          text: '群組1',
          expanded : false,
          selected : false,
          items: [
            {
              text: '群組1子集1',
              expanded : false,
              selected : false,
              items: [
                {
                  text: '群組1子集1子子集1',
                  expanded : false,
                  selected : false,
                  items: []
                },{
                  text: '群組1子集1子子集2',
                  expanded : false,
                  selected : false,
                  items: []
                },
                {
                  text: '群組1子集1子子集3',
                  expanded : false,
                  selected : false,
                  items: []
                },
              ]
            },
            {
              text: '群組1子集2',
              expanded : false,
              selected : false,
              items: []
            },
            {
              text: '群組1子集3',
              expanded : false,
              selected : false,
              items: [
                {
                  text: '群組1子集3子子集1',
                  items: []
                },
              ]
            },
          ],
        },
      ],
    }
  },
  created(){
  },
  mounted(){
      this.treetoper_width.min = (document.getElementById('agent_title').offsetWidth+document.getElementById('treesbtnaa').offsetWidth+document.getElementById('treesOnOff').offsetWidth)/document.getElementsByTagName('body')[0].offsetWidth ;
      this.treetoper_width.max = '0.8'
      this.treetoper_width.OnOff = this.treesOnOff.treesOnOff_button;
      Store.dispatch("AgentListPane_Width", this.treetoper_width);
      //console.log(this.treetoper_width)
      //this.getTreeData();
      console.log(this.treeData)
      this.agent_name = this.$store.getters.loggedInUser;
      //this.treeData = this.expandAll(this.treeData, this.expandAll_bool=!this.expandAll_bool) ;
      //this.selectedItem = this.this.$store.state.ManagementTreeData[0];
      //this.selectedItem.selected = true;

  },
  methods: {
    getTreeData(){
      axios.get(this.$httpPath+'/ManagementInfo/GetManagementTree', {
                params:{
                    AccountName : this.$store.getters.loggedInUser
                }
            }).then((response)=>{
                if(response.status===200)
                {
                    //this.treeData=response.data;
                    //this.selectedItem = this.treeData[0];
                    //this.selectedItem.selected = true;
                }
            })
    },
    onItemChange(event) {
      //console.log(this.selectedItem)
      if (this.$store.getters.AgentTreeData_SelectedItem.selected == true) {
        event.item.selected = true;
        //this.$store.state.ManagementTreeData_SelectedItem.selected = false;
        this.$store.dispatch("AgentTreeData_SelectedItemActions", event.item);
      }
      //console.log(event)
      //event.item.selected = true;
      //this.$store.state.ManagementTreeData_SelectedItem = event.item;
      //console.log(this.selectedItem)
      //console.log(event.item.text)
      /*補選擇*/ 
    },
    expandAll(){
      this.expand(this.$store.getters.AgentTreeData, this.expandAll_bool=!this.expandAll_bool)
    },
    expand(treeData, bool) {
      console.log(treeData)
      treeData.forEach(element => {
        element.expanded = bool;
        if(element.items != null)
        {
          this.expand(element.items, bool);
        }
      });
      /*treeData.map(function(element, index, array){
        Object.assign(element, {
          expanded: bool,
          items:this.expandAll(element.items, bool),
        })
        //console.log(element.text + " : " + bool)
      }, this);
      */
      return treeData         
    },
    onExpandChange(event) {
      console.log(event)
      //console.log(event.item.expanded)
      event.item.expanded = !event.item.expanded;
    },
    
    treesOnOff_click(){
      //console.log(this.treetoper_width)
        if(this.treesOnOff.treesOnOff_button == false){
            //console.log("放大左邊視窗");
            this.treesOnOff.treesOnOff_svg_1="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z";
            this.treesOnOff.treesOnOff_svg_2="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z";
            this.treesOnOff.treesOnOff_button = true;

            this.treetoper_width.min = '0.2' ;
            this.treetoper_width.max = '0.8';
            //console.log("現在視窗大小 : "+ window.innerWidth)
            //console.log("agent_title : "+ document.getElementById('agent_title').offsetWidth)
            //console.log("treesbtnaa : "+ document.getElementById('treesbtnaa').offsetWidth)
            //console.log("treesOnOff : "+ document.getElementById('treesOnOff').offsetWidth)
            //console.log("AgentListPane : "+ document.getElementById('AgentListPane').offsetWidth)
            this.treetoper_width.OnOff = this.treesOnOff.treesOnOff_button;
            Store.dispatch("AgentListPane_Width", this.treetoper_width);
            //console.log(this.$store.state.agentlistpane_width);
        }
        else if(this.treesOnOff.treesOnOff_button == true){
            //console.log("縮小左邊視窗");
            this.treesOnOff.treesOnOff_svg_1="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z";
            this.treesOnOff.treesOnOff_svg_2="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z";
            this.treesOnOff.treesOnOff_button = false;
            
            //console.log("現在視窗大小 : "+ window.innerWidth)
            //console.log("agent_title : "+ document.getElementById('agent_title').offsetWidth)
            //console.log("treesbtnaa : "+ document.getElementById('treesbtnaa').offsetWidth)
            //console.log("treesOnOff : "+ document.getElementById('treesOnOff').offsetWidth)
            //console.log("AgentListPane : "+ document.getElementById('AgentListPane').offsetWidth)

            this.treetoper_width.min = (document.getElementById('agent_title').offsetWidth+document.getElementById('treesbtnaa').offsetWidth+document.getElementById('treesOnOff').offsetWidth+10) / window.innerWidth ;
            this.treetoper_width.max = (document.getElementById('agent_title').offsetWidth+document.getElementById('treesbtnaa').offsetWidth+document.getElementById('treesOnOff').offsetWidth+10) / window.innerWidth ;
            this.treetoper_width.OnOff = this.treesOnOff.treesOnOff_button;
            Store.dispatch("AgentListPane_Width", this.treetoper_width);
            console.log(this.$store.state.agentlistpane_width);
        }
    }, 
  }
};
</script>
<style scoped>
  .body{
    margin: 0px;
    border: 0px;
    display: inline-flex;
    flex-direction: column;
    color: #fff;
    
   }
  .treesOnOff{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top:1px;
    cursor:pointer;
    min-width:18px;
    background-color:#000000;
    border-radius:5px;
    margin-left: 3px;
    margin-right: 3px;

  }
  .treesbtnaa{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top:1px;
    cursor:pointer;
    min-width:18px;
    background-color:#000000;
    border-radius:5px;
    margin-left: 3px;
    margin-right: 3px;

  }
  .agent_title{
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 2px;
    flex:1;
  }
  .treetoper{
    position:relative;
    display: inline-flex;
    white-space: nowrap;
    width: 100%;
    height: auto;
    border-bottom:solid 1px #001c31;
    background-color:#106184;
    background-image:linear-gradient(to bottom,#106184 0,#08385a 100%);
    color:#94dbff;
    font-size:12px;
  }
  .treecenter{
    overflow:auto;
    flex:1;
    border: 0px;
    background-color:#084163;
    background-image:linear-gradient(to right,#084163 0,#105d7b 100%);
    color:#94dbff;
    padding: 5px 5px;
  }
  .treeview{
    height: 100%;
    font-size: 12px;
    color: #94dbff;
  }
  .treeview ul{
    height: 200px;

  }
</style>
