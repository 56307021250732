<template>
    <body id ="Member_List" class="body">
        <Grid
            ref="grid"
            :style="{height:gridhight+'px', width:gridwidth +'px'}"
            :data-items="result"
            :column-virtualization="false"
            :columns = "columns"
            :pageable="true"
            :enableRtl="true"
            :resizable="false" 
            :skip="skip"
            :take="take"
            :total="total"
            @pagechange="pageChangeHandler($event)"
            >

            <template v-slot:head_mAccountName="{props}">
                <div 
                    style="text-align: center; font-weight:bold; font-size:16px" 
                    v-text="props.title">
                </div>
            </template>
            <template v-slot:mAccountName="{props}">
                
                <td  style="width:100%; padding:0px; text-align: center; flex-wrap: nowrap; position:sticky; z-index:2"
                    :style=" result.indexOf(props.dataItem)%2 === 1?'background-color: #F9F9F9':'background-color: #FFFF;'"
                    >
                    <span style="color:#0053ff;" v-text="props.dataItem[props.field]"></span>
                </td>
            </template>
            

            <template v-slot:head_mAgentNickName="{props}">
                <div 
                    style="text-align: center; font-weight:bold; font-size:16px;" 
                    v-text="props.title">
                </div>
            </template>
            <template v-slot:mAgentNickName="{props}">
                <td  style="width:100%; padding:0px; text-align: center; flex-wrap: nowrap;"
                    :style=" result.indexOf(props.dataItem)%2 === 1?'background-color: #F9F9F9':'background-color: #FFFF;'"
                >
                    <span style="color:#0053ff;" v-text="props.dataItem[props.field]"></span>
                </td>
            </template>

            <template v-slot:head_mCredistAmout="{props}">
                <div 
                    style="text-align: center; font-weight:bold; font-size:16px;" 
                    v-text="props.title">
                </div>
            </template>
            <template v-slot:mCredistAmout="{props}">
                <td  style="width:100%; padding:0px; text-align: center; flex-wrap: nowrap;"
                    :style=" result.indexOf(props.dataItem)%2 === 1?'background-color: #F9F9F9':'background-color: #FFFF;'"
                >
                    <span style="color:#0053ff;" v-text="props.dataItem[props.field]"></span>
                    <Button :rounded="'medium'" :size="'small'" v-text="'增減'" @click="CreditsAmount_Button(props.dataItem)"></Button>
                </td>
            </template>

            <template v-slot:head_mLastBalance="{props}">
                <div 
                    style="text-align: center; font-weight:bold; font-size:16px;" 
                    v-text="props.title">
                </div>
            </template>
            <template v-slot:mLastBalance="{props}">
                <!-- <td  style="width:100%; padding:0px; text-align: center; flex-wrap: nowrap;"
                    :style=" result.indexOf(props.dataItem)%2 === 1?'background-color: #F9F9F9':'background-color: #FFFF;'"
                >
                    <span style="color:#0053ff;" v-text="props.dataItem[props.field]"></span>
                    <Button :rounded="'medium'" :size="'small'" v-text="'儲值'" @click="LastBalance_Button(props.dataItem)"></Button>
                </td> -->
                <Button :rounded="'medium'" :size="'small'" v-text="'儲值'" @click="LastBalance_Button(props.dataItem)"></Button>
            </template>
            <template v-slot:head_mStatus="{props}">
                <div 
                    style="text-align: center; font-weight:bold; font-size:16px;" 
                    v-text="props.title">
                </div>
            </template>
            <template v-slot:mStatus="{props}">
                <td  style="width:100%; padding:0px; text-align: center; flex-wrap: nowrap;"
                    :style=" result.indexOf(props.dataItem)%2 === 1?'background-color: #F9F9F9':'background-color: #FFFF;'"
                >
                    <span v-if="props.dataItem[props.field] === '正常'" style="color:#313131;" v-text="props.dataItem[props.field]"></span>
                    <span v-if="props.dataItem[props.field] === '禁新'" style="color:#fb0000;" v-text="props.dataItem[props.field]"></span>
                    <span v-if="props.dataItem[props.field] === '凍結'" style="color:#156dff;" v-text="props.dataItem[props.field]"></span>
                    <span v-if="props.dataItem[props.field] === '停用'" style="color:#b5963e;" v-text="props.dataItem[props.field]"></span>
                    <Button :rounded="'medium'" :size="'small'" v-text="'設'" @click="Status_Setting_Button(props.dataItem)"></Button>

                </td>
            </template>
            <template v-slot:head_mCategory="{props}">
                <div 
                    style="text-align: center; font-weight:bold; font-size:16px;" 
                    v-text="props.title">
                </div>
            </template>
            <template v-slot:mCategory="{props}">
                <td  style="width:100%; padding:0px; text-align: center; flex-wrap: nowrap;"
                    :style=" result.indexOf(props.dataItem)%2 === 1?'background-color: #F9F9F9':'background-color: #FFFF;'"
                >
                    <span style="color:#0053ff;" v-text="props.dataItem[props.field]"></span>
                </td>
            </template>
            <template v-slot:head_mAdd="{props}">
                <div 
                    style="text-align: center; font-weight:bold; font-size:16px;" 
                    v-text="props.title">
                </div>
            </template>
            <template v-slot:mAdd="{props}">
                <td  style="width:100%; padding:0px; text-align: center; flex-wrap: nowrap;"
                    :style=" result.indexOf(props.dataItem)%2 === 1?'background-color: #F9F9F9':'background-color: #FFFF;'"
                >
                    <Button :rounded="'medium'" :size="'small'" v-text="'複製'" @click="Add_Member_Button(props.dataItem)"></Button>
                </td>
            </template>
            <template v-slot:head_mSetting="{props}">
                <div 
                    style="text-align: center; font-weight:bold; font-size:16px;" 
                    v-text="props.title">
                </div>
            </template>
            <template v-slot:mSetting="{props}">
                <td  style="width:100%; padding:0px; text-align: center; flex-wrap: nowrap;"
                    :style=" result.indexOf(props.dataItem)%2 === 1?'background-color: #F9F9F9':'background-color: #FFFF;'"
                >
                    <Button :rounded="'medium'" :size="'small'" v-text="'設定'" @click="Member_Setting_Button(props.dataItem)"></Button>
                    <Button :rounded="'medium'" :size="'small'" v-text="'修改密碼'" @click="Setting_Password_Button(props.dataItem)"></Button>
                </td>
            </template>
            <template v-slot:head_mHistory="{props}">
                <div 
                    style="text-align: center; font-weight:bold; font-size:16px;" 
                    v-text="props.title">
                </div>
            </template>
            <template v-slot:mHistory="{props}">
                <td  style="width:100%; padding:0px; text-align: center; flex-wrap: nowrap;"
                    :style=" result.indexOf(props.dataItem)%2 === 1?'background-color: #F9F9F9':'background-color: #FFFF;'"
                >
                    <Button :rounded="'medium'" :size="'small'" v-text="'損益'" @click="History_Profit_And_Loss_Button(props.dataItem)"></Button>
                    <Button :rounded="'medium'" :size="'small'" v-text="'狀態'" @click="History_Status_Button(props.dataItem)"></Button>

                </td>
            </template>

            <GridToolbar class="gridtoolbar">
                <div class="myfloatleft ">
                    <div>
                        <span style="padding-top:2px;font-size:18px; vertical-align: middle;margin:3px;" v-text="'會員清單'"></span>
                        <Input :style="{ margin:'2px',padding:'0px', overflow: 'hidden',flex: 'nowrap' }" :size="'small'" :icon-name="'k-icon k-i-zoom'" v-model="search_word"></Input>
                        <Button :rounded="'medium'" :size="'small'" v-text="'搜索'" ></Button>   
                        <Button
                            :rounded="'large'"
                            :size="'small'"
                            v-for="button in filter_buttons"
                            :togglable="true"
                            :key="button.id"
                            :selected="filter_select_button.id === button.id"
                            v-text="button.name"
                            @click="Filter_Change(button.id)"
                            >
                        </Button>                             
                    </div>
                    <div style="white-space:nowrap">
                        <div>
                        <checkbox :size="'small'" class="checkbox" :default-checked="false" :label="'在線'" ></checkbox>
                        <checkbox :size="'small'" class="checkbox" :default-checked="false" :label="'未平'" ></checkbox>
                        <checkbox :size="'small'" class="checkbox" :default-checked="false" :label="'禁新'" ></checkbox>
                        <checkbox :size="'small'" class="checkbox" :default-checked="false" :label="'引藏從未下過單會員'" ></checkbox>
                        </div>
                        <div>
                        <checkbox :size="'small'" class="checkbox" :default-checked="false" :label="'正常'" ></checkbox>
                        <checkbox :size="'small'" class="checkbox" :default-checked="false" :label="'下單'" ></checkbox>
                        <checkbox :size="'small'" class="checkbox" :default-checked="false" :label="'凍結'" ></checkbox>
                        </div>
                    </div>
                    <div>
                        <span class="borader" style="font-size:18px" v-text="'篩選出 ' + filter_calculation +' 筆'"></span>
                        <!-- <Button :rounded="'medium'" :size="'small'" v-text="'新增會員'" @click="Add_Member_Button(null)" ></Button> -->
                        <Button :rounded="'medium'" :size="'small'" v-text="'新增會員A'" @click="Add_This_Member_Button()" ></Button>
                    </div>
                </div>
                <div class="myfloatright">
                    <Button :rounded="'medium'" :size="'small'" v-text="'EXCEL'"></Button>
                </div>
            </GridToolbar>
        </Grid>
        <Dialog 
            v-if="member_setting_visible" 
            :width="'830px'"
            :height="'745px'"
            :title="'會員設定'" 
            @close="Member_Setting_Button(null)">
            <MemberSetting :select_row_data = "select_row_data" />
            <DialogActionsBar>
                <div style="display:flex; justify-content:flex-end; margin-bottom:0px">
                    <Button style="width:58px;height:38px;" :rounded="'medium'" v-text="'確認'"></Button>
                    <Button style="width:58px;height:38px;" :rounded="'medium'" v-text="'取消'" @click="Member_Setting_Button(null)"></Button>
                </div>
            </DialogActionsBar>
        </Dialog>
        <Dialog 
            v-if="creditsAmount_visible" 
            class="Dialogs"
            :width="'800px'"
            :height="'500px'"
            :title="'增減額度 : ' + select_row_data['mAccountName']" 
            @close="CreditsAmount_Button(null)">
            <CreditsAmount :select_row_data = "select_row_data" />
            <DialogActionsBar>
                <div style="display:flex; justify-content:flex-end; margin-bottom:0px">
                    <Button style="width:58px;height:38px;" :rounded="'medium'" v-text="'關閉'" @click="CreditsAmount_Button(null)"></Button>
                </div>
            </DialogActionsBar>
        </Dialog>
        <Dialog 
            v-if="lastBalance_visible" 
            :width="'800px'"
            :height="'500px'"
            :title="'儲值 : ' + select_row_data['userName']" 
            @close="LastBalance_Button(null)">
            <LastBalance :select_row_data = "select_row_data" />
        </Dialog>
        <Dialog 
            v-if="setting_status_visible" 
            :width="'auto'"
            :height="'auto'"
            :title="'設定狀態 : ' + select_row_data['mAccountName']" 
            @close="Setting_Status_Button(null)">
            <SettingStatus :select_row_data = "select_row_data"/>
            <DialogActionsBar>
                <div style="display:flex; justify-content:flex-end; margin-bottom:0px">
                    <Button style="width:58px;height:38px;" :rounded="'medium'" v-text="'確認'"></Button>
                    <Button style="width:58px;height:38px;" :rounded="'medium'" v-text="'取消'" @click="Setting_Status_Button(null)"></Button>
                </div>
            </DialogActionsBar>
        </Dialog>
        <Dialog 
            v-if="add_member_visible" 
            :width="'auto'"
            :height="'auto'"
            :title="'新增會員 : ' + select_row_data['mAgentNickName']" 
            @close="Add_Member_Button(null)">
            <AddMember :select_row_data = "select_row_data" v-on:add_data="Add_member()" />
            <DialogActionsBar>
                <div style="display:flex; justify-content:flex-end; margin-bottom:0px">
                    <Button style="width:58px;height:38px;" @click="createVipAccount()" :rounded="'medium'" v-text="'確認'"></Button>
                    <Button style="width:58px;height:38px;" :rounded="'medium'" v-text="'取消'" @click="Add_Member_Button(null)"></Button>
                </div>
            </DialogActionsBar>
        </Dialog>
        <Dialog 
            v-if="add_this_member_visible" 
            :width="'auto'"
            :height="'auto'"
            :title="'新增會員'" 
            @close="Add_This_Member_Button()">
            <AddThisMember :select_row_data = "select_row_data" v-on:AccountManagement="Add_this_member" />
            <DialogActionsBar>
                <div style="display:flex; justify-content:flex-end; margin-bottom:0px">
                    <Button style="width:58px;height:38px;" @click="createThisVipAccount()" :rounded="'medium'" v-text="'確認'"></Button>
                    <Button style="width:58px;height:38px;" :rounded="'medium'" v-text="'取消'" @click="Add_This_Member_Button()"></Button>
                </div>
            </DialogActionsBar>
        </Dialog>
        <Dialog 
            v-if="setting_password_visible"
            :width="'355px'"
            :height="'auto'"
            :title="'修改密碼'" 
            @close="Setting_Password_Button(null)">
            <SettingPassword :select_row_data = "select_row_data" />
            <DialogActionsBar>
                <div style="display:flex; justify-content:flex-end; margin-bottom:0px">
                    <Button style="width:58px;height:38px;" :rounded="'medium'" v-text="'確認'"></Button>
                    <Button style="width:58px;height:38px;" :rounded="'medium'" v-text="'取消'" @click="Setting_Password_Button(null)"></Button>
                </div>
            </DialogActionsBar>
        </Dialog>
        <Dialog 
            v-if="history_profit_and_loss_visible" 
            :width="'80%'"
            :height="'500px'"
            :title="'歷史損益 : '+ select_row_data['mAccountName']" 
            @close="History_Profit_And_Loss_Button(null)">
            <HistoryProfitAndLoss :select_row_data = "select_row_data" />
            <DialogActionsBar>
                <div style="display:flex; justify-content:flex-end; margin-bottom:0px">
                    <Button style="width:58px;height:38px;" :rounded="'medium'" v-text="'關閉'" @click="History_Profit_And_Loss_Button(null)"></Button>
                </div>
            </DialogActionsBar>
        </Dialog>
        <Dialog 
            v-if="history_status_visible" 
            :width="'475px'"
            :height="'500px'"
            :title="'歷史狀態 : ' + select_row_data['mAccountName']" 
            @close="History_Status_Button(null)">
            <HistoryStatus :select_row_data = "select_row_data" />
            <DialogActionsBar>
                <div style="display:flex; justify-content:flex-end; margin-bottom:0px">
                    <Button style="width:58px;height:38px;" :rounded="'medium'" v-text="'關閉'" @click="History_Status_Button(null)"></Button>
                </div>
            </DialogActionsBar>
        </Dialog>
        <Dialog v-if="message_visible" :width="'300px'" :height="'125px'" :title="'訊息'" @close="Message_Button(null)">
            <div style="height:100%; width:100%; display: inline-flex; align-items: center; justify-content: center;">
                <div style="font-size:16px; font-weight: bold;" v-text="message" />
            </div>
        </Dialog>
    </body>
</template>
<script>
import CreditsAmount_Dialog from '@/components/Account_Manage_Group/Dialog/CreditsAmount_Dialog';
import LastBalance_Dialog from '@/components/Account_Manage_Group/Dialog/LastBalance_Dialog';
import History_Status_Dialog  from '@/components/Account_Manage_Group/Dialog/History_Status_Dialog';
import History_Profit_And_Loss_Dialog from '@/components/Account_Manage_Group/Dialog/History_Profit_And_Loss_Dialog';
import Setting_Password_Dialog from '@/components/Account_Manage_Group/Dialog/Setting_Password_Dialog'
import Add_Member_Dialog from'@/components/Account_Manage_Group/Dialog/Add_Member_Dialog'
import Add_This_Member_Dialog from'@/components/Account_Manage_Group/Dialog/Add_This_Member_Dialog'
import Setting_Status_Dialog from'@/components/Account_Manage_Group/Dialog/Setting_Status_Dialog'
import Member_Setting_Dialog from '@/components/Account_Manage_Group/Dialog/Member_Setting_Dialog';

import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
import { Checkbox, Input, RadioGroup, TextArea } from '@progress/kendo-vue-inputs';
import { Button, ButtonGroup } from '@progress/kendo-vue-buttons';
import { Window, Dialog, DialogActionsBar} from '@progress/kendo-vue-dialogs';
import axios from 'axios';
export default {
    name: 'Member_List', 
      components: {
        Grid,
        GridToolbar,
        Button,
        Checkbox,
        Input,
        Dialog,
        DialogActionsBar,
        
        'LastBalance':LastBalance_Dialog,
        'CreditsAmount':CreditsAmount_Dialog,
        'SettingPassword':Setting_Password_Dialog,
        'SettingStatus':Setting_Status_Dialog,
        'AddMember':Add_Member_Dialog,
        'AddThisMember':Add_This_Member_Dialog,
        'HistoryProfitAndLoss':History_Profit_And_Loss_Dialog,
        'HistoryStatus':History_Status_Dialog,
        'MemberSetting':Member_Setting_Dialog,
    },   
    data: function () {
        return {
            message:'',
            select_row_data:[],
            AA:100,
            getData:false,
            ThisAccountManagement: {},
            UserList:{
                guid:'',
                agentGuid:'',
                agent:'',
                userName:'',
                creditsAmount:'',
                lastBalance:'',
                uStatus:'',
                uCategory:'',
                proportion:'',
                rentAmount:'',
                uType:'',
                joinTime:'',
                userEmail:'',
                todayPL:'',
                todayBlance:'',
                commision:'',
                expense:'',
                lots:'',
                cashOut:'',
                deposit:'',
                liquidation:'',
            },
            UserLists:[],
            example_data:[{
                "uUserName":"範例",
                "uAgent":"範例",
                "uCreditsAmount":"範例",
                "uLastBalance":"範例",
                "uStatus":"範例",
                "uCategory":"範例",
            },
            ],
            
            member_setting_visible:false,
            creditsAmount_visible:false,
            lastBalance_visible:false,
            status_setting_visible:false,
            add_member_visible:false,
            add_this_member_visible:false,
            setting_status_visible:false,
            setting_password_visible:false,
            history_profit_and_loss_visible:false,
            history_status_visible:false,
            setting_member_visible:false,
            message_visible:false,

            search_word:"",
            gridhight:"",
            gridwidth:"",
            filter_calculation:0,
            skip: 0,
            take: 10,
            currentPage:1,
            filter_select_button:{},
            add_member:[],

            filter_buttons:[
                {
                    id: "layer",
                    name: "當層會員",
                },
                {
                    id: "extend",
                    name: "延伸會員",
                },
            ],
            columns:[
                // { field: 'mAccountName', cell:'mAccountName', headerCell : 'head_mAccountName', title: '會員帳號', width: '125px', locked: true},
                // { field: 'mAgentNickName', cell:'mAgentNickName', headerCell : 'head_mAgentNickName', title: '代理商', width: '125px', locked: true, },
                // { field: 'mCredistAmout', cell:'mCredistAmout', headerCell : 'head_mCredistAmout', title: '信用額度', width: '125px'},
                { field: 'mLastBalance', cell:'mLastBalance', headerCell : 'head_mLastBalance', title: '昨日餘額', width: '125px'},
                // { field: 'mStatus', cell:'mStatus', headerCell : 'head_mStatus', title: '狀態', width: '80px'},
                // { field: 'mCategory', cell:'mCategory', headerCell : 'head_mCategory', title: '屬性', width: '70px'},
                // { field: 'mAdd', cell:'mAdd', headerCell : 'head_mAdd', title: '新增', width: '50px'},
                // { field: 'mSetting', cell:'mSetting', headerCell : 'head_mSetting', title: '設定', width: '140px'},
                // { field: 'mHistory', cell:'mHistory', headerCell : 'head_mHistory', title: '歷史', width: '100px'},
                { field: 'userAccount', title: '帳號' , width: '200px'},
                { field: 'userName', title: '暱稱' , width: '200px'},
                { field: 'userEmail', title: 'Email' , width: '200px'},
                { field: 'agent', title: '上層代理' , width: '200px'},
                { field: 'lastDefaultPasswd', title: '預設密碼' , width: '200px'},
            ],
            status_setting_radiogroup_checked:"",
            status_setting_radiogroup:[
                {
                    label: "正常",
                    value: 0,
                },
                {
                    label: "禁新",
                    value: 1,
                },
                {
                    label: "凍結",
                    value: 2,
                },
                {
                    label: "停用",
                    value: 3,
                },
            ],


            // 新增子帳號/會員用 Ricky
            addVipInfo:{
                guid:'',
                name:'',
                password:'',
                func:'',
            },
            // 新增子帳號/會員用 Ricky
            // 新增代理用 Ricky
            addAgentInfo:{
                guid:'',
                name:'',
                password:'',
                nickName:'',

            },
            // 新增代理用 Ricky
        }
    },
    computed: {
        result: {
            get: function() {
                console.log(this.$store.getters.MemberListData);
                return this.$store.getters.MemberListData;
            }
        },
        total () {
            return this.$store.getters.MemberListData.totalRows;
        },  
    },
    mounted(){
        //this.refreshGrid();
        const myObserver = new ResizeObserver(entries => {
            this.gridhight=document.getElementById('Member_List').clientHeight;
            this.gridwidth=document.getElementById('Member_List').clientWidth;
            //console.log(this.gridwidth)
        });
        const TabStrip = document.getElementById('Member_List');
        myObserver.observe(TabStrip, {
            box: "content-box",
        });
        this.filter_select_button = this.filter_buttons[0];
        this.GetThisLevelVip();
    },
    methods: {
        GetThisLevelVip() {
            axios.get(this.$httpPath + '/AgentManager/vipAccount/3')
                .then((response) => {
                    if (response.status == 200) {
                        this.$store.state.MemberListData = response.data
                        console.log(this.$store.getters.MemberListData)
                    }
                })
                .catch((error) => {
                    this.message = "發生錯誤"
                    console.log(error)
                }).finally(() => {
                });
        },
        Message_Button() {
            this.message_visible = !this.message_visible;
            this.message = '';
        },
        Add_this_member(AccountManagement){
            // console.log('AA:',AccountManagement)
            this.ThisAccountManagement = AccountManagement
        },
        createThisVipAccount(){
            console.log('BB:',this.ThisAccountManagement)
            this.ThisAccountManagement.isChild = true;
            axios.post(this.$httpPath + '/AgentManager/vipAccount', this.ThisAccountManagement)
                .then((response) => {
                    if (response.status == 200) {
                        if(response.data){
                            this.message = "註冊成功"
                        }
                        else
                        {
                            this.message = "註冊失敗"
                        }
                        this.message_visible = true;
                    }
                })
                .catch((error) => {
                    this.message = "發生錯誤"
                }).finally(() => {
                    this.GetThisLevelVip();
                });
        },
        /*cocount 有錯誤我先修正*/
        createVipAccount(){

        },
        Add_member(data){
            console.log(data)
            /*暫放 新增會員 接收回傳的function*/
        },
        Member_Setting_Button(data){
            this.select_row_data = data;
            this.member_setting_visible = !this.member_setting_visible;
        },
        Status_Setting_Button(data){
            this.select_row_data = data;
            this.setting_status_visible = !this.setting_status_visible;
        },
        CreditsAmount_Button(data){
            this.select_row_data = data;
            this.creditsAmount_visible = !this.creditsAmount_visible;
        },
        LastBalance_Button(data){
            this.select_row_data = data;
            this.lastBalance_visible = !this.lastBalance_visible;
        },
        Setting_Status_Button(data){
            this.select_row_data = data;
            this.setting_status_visible = !this.setting_status_visible;
        },
        Add_Member_Button(data){
            this.select_row_data = data;
            this.add_member_visible = !this.add_member_visible;
        },
        Add_This_Member_Button(){
            this.add_this_member_visible = !this.add_this_member_visible;
        },
        Setting_Password_Button(data){
            this.select_row_data = data;
            console.log(data)
            this.setting_password_visible = !this.setting_password_visible;
        },
        History_Profit_And_Loss_Button(data){
            console.log(data);
            this.select_row_data = data;
            this.history_profit_and_loss_visible = !this.history_profit_and_loss_visible;
        },
        History_Status_Button(data){
            this.select_row_data = data;
            this.history_status_visible = !this.history_status_visible;
        },

        Filter_Change(key_word_id){
            this.filter_select_button = this.filter_buttons.find(b => b.id == key_word_id);

        },
        pageChangeHandler: function(event) {
            this.skip = event.page.skip;
            this.take = event.page.take;
            this.currentPage = (event.page.skip/this.take)+1,
            this.refreshGrid();
        },
        refreshGrid()
        {  
            // axios.get(this.$httpPath+'/AgentManager/secAgent/2/'+this.take+'/'+this.currentPage, {
            //     params:{
                    
            //     }
            // }).then((response)=>{
            //     if(response.status===200)
            //     {
            //         this.UserLists=response.data.data;
            //         this.getData=true
            //         console.log(this.UserLists)
            //     }
            // })
            axios.get(this.$httpPath + '/AgentManager/vipAccount/3?currentPage='+this.currentPage+'&pageSize='+this.take)
                .then((response) => {
                    if (response.status == 200) {
                        this.$store.state.MemberListData = response.data
                        console.log(this.$store.getters.MemberListData)
                    }
                })
                .catch((error) => {
                    this.message = "發生錯誤"
                    console.log(error)
                }).finally(() => {
                });
        },
    }
};
</script>
<style scoped>
.body{
    height:100%;
    width:100%;
    display: inline-flex;
    color:#fff;
    font-family:"微軟正黑體";
    flex-direction: row;
    flex-wrap: nowrap;
}
.gridtoolbar{
    height: auto;
    padding: 0px 5px;
    background-color: #0c4d70;
    color: #fff;
    display: inline-flex;
    flex-direction:row  
}
.myfloatleft{
    flex:1;
    display: inline-flex;
    align-items: center;
}
.myfloatleft div{
    margin: 0px 5px;
}
.borader{
    display: inline-flex;
    background-color: #198754;
    border-radius:4px;
    overflow: hidden;
    justify-content: center;
    color: #fff;
    margin: 3px;
    border: 1px;
    padding: 3px 6px;
    vertical-align: middle;
}
Button{
    height:100%;
    width:auto;
    color: #fff;
    background-color: #17a2b8;
    padding: 2px 5px;
    margin: 3px;
}
.checkbox{
    margin-right: 10px;
}
</style>
