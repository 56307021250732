<template>
    <body id =menuebox>
        <div class="a">a</div>
        <div class="b">b</div>
        <div class="c">c</div>
    </body>
</template>
<script>
export default {
    name: 'Group', 
      components: {
    },   
    data: function () {
        return {
        }
    },
    mounted(){
        
    },
    methods: {

    },
};
</script>
<style scoped>
body{
    height:100%; 
    margin: 0px;
    display: flex;
    flex-direction: column;
}
.a{
    width:100%;
    height: 40px;
    background-color: aquamarine;
}
.b{
    flex:1;
    background-color: rgb(255, 127, 127);
}
.c{
    width:100%;
    height: 40px;
    background-color: rgb(255, 127, 212);
}
</style>
