<template>
    <body id="Charity" class="body">
        <div class="alert alert-info" style="overflow: hidden;" v-for="(charity, index) in charity_list" :key="index">
            <span v-text="charity.title"></span>
            <div></div>
            <a href="charity.href" v-text="charity.href"></a>
        </div>
    </body>
</template>
<script>
export default {
    name: 'Charity', 
      components: {
    },   
    data: function () {
        return {
            charity_list:[
                {
                    title: "世界糧食計畫",
                    href: "http://www.wfp.org/",
                },
                {
                    title: "世界展望會",
                    href: "http://www.worldvision.org",
                },
                {
                    title: "馬來西亞世界展望會",
                    href: "https://www.worldvision.com.my/ch/donations/save-fragile-lives",
                },
                {
                    title: "台灣世界展望會",
                    href: "http://www.worldvision.org.tw/",
                },
                {
                    title: "世界紅十字會總會",
                    href: "http://www.redcross.org/",
                },
                {
                    title: "中華民國紅十字會總會",
                    href: "http://www.redcross.org.tw/",
                },
                {
                    title: "嘉邑行善團",
                    href: "http://chiayidogood.org/",
                },
            ]
        }
    },
    mounted(){
        
    },
    methods: {

    }
};
</script>
<style scoped>
.body{
    height:100%; 
    margin: 0px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 20px 20px;
    overflow: hidden;
}
</style>
