<template>
    <body id = 'Agent_Setting_Dialog'>
        <div class="maintable">
            <div class = "topbar">
                <div class="topelement">
                    <div v-text="'帳戶資訊'"/>
                    <div class="titleelement" v-text="select_row_data['mAccountName']">
                    </div>
                </div>
                <div class="topelement">
                    <div v-text="'商品報價'"/>
                    <div class="titleelement" v-text="select_row_data['mQuote']">
                    </div>
                </div>
            </div>
            <div class="detial">
                <div class="detialelement">
                    <table>
                        <tr>
                            <td v-text="'會員編號'"/>
                            <td>
                                <Input 
                                    :disabled="false"
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;" 
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                        </tr>
                        <tr>
                            <td/>
                            <td>
                                <Button :rounded="'medium'" :size="'small'" v-text="'修改密碼'"></Button>
                            </td>
                        </tr>
                        <tr>
                            <td v-text="'代理名稱'"/>
                            <td>
                                <Input 
                                    :disabled="false"
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;" 
                                    v-text="select_row_data['mAgentName']"/>
                            </td>
                        </tr>
                        <tr>
                            <td v-text="'建立日期'"/>
                            <td>
                                <Input 
                                    :disabled="false"
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;" 
                                    v-text="select_row_data['mJoinTime']"/>
                            </td>
                        </tr>
                        <tr>
                            <td v-text="'最後登入'"/>
                            <td>
                                <Input 
                                    :disabled="false"
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;" 
                                    v-text="select_row_data['mJoinTime']"/>
                            </td>
                        </tr>
                        <tr>
                            <td v-text="'商品報價'"/>
                            <td>
                                <Input 
                                    :disabled="false"
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da; color:rgb(255, 0, 0)" 
                                    v-text="select_row_data['mJoinTime']"/>
                            </td>
                        </tr>
                        
                    </table>
                </div>
                <div class="detialelement">
                    <table>
                        <tr>
                            <td v-text="'信用額度'"/>
                            <td>
                                <Input 
                                    style=" padding:0px 5px; width:110px; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                        </tr>
                        <tr>
                            <td v-text="'極贏額度'"/>
                            <td>
                                <Input 
                                    style=" padding:0px 5px; width:110px; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                        </tr>
                        <tr>
                            <td v-text="'極贏根據'"/>
                            <td>
                                <DropDownList
                                    :style="{ width: '100%' , height:'23px'}" 
                                    :data-items='mextreme_limit_sports'
                                    :default-item="''"/>
                            </td>
                        </tr>
                        <tr>
                            <td v-text="'佔成'"/>
                            <td>
                                <Input 
                                    style=" padding:0px 5px; width:110px; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                        </tr>
                        <tr>
                            <td v-text="'租金'"/>
                            <td>
                                <Input 
                                    style=" padding:0px 5px; width:110px; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                        </tr>
                        <tr>
                            <td style="white-space:pre-wrap;" v-text="'租金(低於1口)'"/>
                            <td>
                                <Input 
                                    style=" padding:0px 5px; width:110px; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                        </tr>
                        <tr>
                            <td v-text="'提領餘額'"/>
                            <td>
                                <DropDownList
                                    :style="{ width: '100%' , height:'23px'}" 
                                    :data-items='mwithdraw_balance_sports'
                                    :default-item="''"/>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="detialelement">
                    <table>
                        <tr>
                            <td v-text="'持倉上限'"/>
                            <td>
                                <Input 
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                        </tr>
                        <tr>
                            <td v-text="'每筆上限'"/>
                            <td>
                                <Input 
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                        </tr>
                        <tr>
                            <td v-text="'留倉上限'"/>
                            <td>
                                <Input 
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                        </tr>
                        <tr>
                            <td v-text="'最小口數'"/>
                            <td>
                                <DropDownList
                                    :style="{ width: '100%' , height:'23px'}" 
                                    :data-items='msmallest_lot_sports'
                                    :default-item="''"/>
                            </td>
                        </tr>
                        <tr>
                            <td v-text="'每口保證金'"/>
                            <td>
                                <Input 
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                        </tr>
                        <tr>
                            <td v-text="'市價秒數'"/>
                            <td>
                                <Input 
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                        </tr>
                        <tr>
                            <td v-text="'限價秒數'"/>
                            <td>
                                <Input 
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                        </tr>
                        <tr>
                            <td v-text="'刪改秒數'"/>
                            <td>
                                <Input 
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="detialelement">
                    <Checkbox
                        v-for="(permissiom, index) in agent_permission"
                        :key="index"
                        :disable="permissiom.disable"
                        v-model="permissiom.value"
                        :label="permissiom.label"
                    />
                </div>
            </div>           
        </div>
        <ButtonGroup class="list">
            <Button 
                v-for="commodity in commodity_list_data"
                style="margin:0px 0.5px; display : inline-flex; align-items: flex-start;"
                :togglable="true"
                :key="commodity.name"
                :selected="commodity_list_data.name === commodity.name"
                @click="(e) => Select_Commodity(commodity.name)"
                >
                
                <div id ="555ss" style="display : inline-flex; align-items:center; flex-direction: column;">
                    <div style="margin:2px; height:10px; width:10px;background-color: rgb(255, 127, 127);"/>
                    <div style="writing-mode: vertical-lr " v-text="commodity.name"/>
                </div>
            </Button>
        </ButtonGroup>
        <div class="maintable">
            <div class = "topbar">
                <div class="topelement">
                    <div v-text="'商品資訊'"/>
                    <div class="titleelement" v-text="select_row_data['mAccountName']">
                    </div>
                </div>
                <div class="topelement">
                    <div v-text="'每點價格'"/>
                    <div class="titleelement" v-text="select_row_data['mQuote']">
                    </div>
                </div>
                <div class="topelement">
                    <div v-text="'最小跳動點'"/>
                    <div class="titleelement" v-text="select_row_data['mQuote']">
                    </div>
                </div>
            </div>
            <div class="detial">
                <div class="detialelement" >
                    <table>
                        <tr>
                            <td v-text="'手續費'"/>
                            <td>
                                <Input 
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                            <td v-text="'≥0'">
                            </td>
                        </tr>
                        <tr>
                            <td v-text="'費用'"/>
                            <td>
                                <Input 
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                            <td v-text="'≥0'">
                            </td>
                        </tr>
                        <tr>
                            <td v-text="'盤後加手續費'"/>
                            <td>
                                <Input 
                                :disabled="true"
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                            <td v-text="'≥0'">
                            </td>
                        </tr>
                        <tr>
                            <td v-text="'盤後手續費'"/>
                            <td>
                                <Input 
                                    :disabled="true"
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                            <td v-text="'≥0'">
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" style="height:25px; justify-content:flex-end; align-items:flex-end; align-content:flex-end; margin:10px 0px;">
                                <span style="white-space: normal; text-align:left; float:left; width:100%; background-color: #fff3cd; border-radius:4px; color:#856404; padding:3px;">
                                   註:盤後手續費 = 手續費 + 費用 + 盤後加手續費
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div  v-text="'手續費'"/>
                                <div style="white-space: nowrap;" v-text="'(低於1口)'"/>
                            </td>
                            <td>
                                <Input 
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                            <td v-text="''">
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div  v-text="'費用'"/>
                                <div style="white-space: nowrap;" v-text="'(低於1口)'"/>
                            </td>
                            <td>
                                <Input 
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                            <td v-text="''">
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div  v-text="'盤後手續費'"/>
                                <div style="white-space: nowrap;" v-text="'(低於1口)'"/>
                            </td>
                            <td>
                                <Input 
                                    :disabled="true"
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                            <td v-text="''">
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" style="height:25px; justify-content:flex-end; align-items:flex-end; align-content:flex-end; margin:10px 0px;">
                                <span style="white-space: normal; text-align:left; float:left; width:100%; background-color: #fff3cd; border-radius:4px; color:#856404; padding:3px;">
                                    註：該商品手續費加費用等於客戶該商品下單手續費
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td v-text="'限價單限制'"/>
                            <td>
                                <Input 
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                            <td/>
                        </tr>
                        <tr>
                            <td v-text="'最小損益點'"/>
                            <td>
                                <Input 
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                            <td/>
                        </tr>
                        <tr>
                            <td v-text="'限價單平倉限制'"/>
                            <td>
                                <Input 
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                            <td/>
                        </tr>
                        <tr>
                            <td colspan="3">
                                <DropDownList
                                    :style="{ width: '100%' , height:'23px'}" 
                                    :data-items='limts_sports'/>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" style="height:25px; justify-content:flex-end; align-items:flex-end; align-content:flex-end; margin:10px 0px;">
                                <span style="white-space: normal; text-align:left; float:left; width:100%; background-color: #fff3cd; border-radius:4px; color:#856404; padding:3px;">
                                    註：限價單平倉限制，需選擇依 市場報價或未平倉單，做為限制
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td v-text="'市價單限制'"/>
                            <td>
                                <Input 
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                            <td/>
                        </tr>
                        <tr>
                            <td colspan="3" style="height:25px; justify-content:flex-end; align-items:flex-end; align-content:flex-end; margin:10px 0px;">
                                <span style="white-space: normal; text-align:left; float:left; width:100%; background-color: #fff3cd; border-radius:4px; color:#856404; padding:3px;">
                                    註：依市價下去算，上下N點才成交
                                </span>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="detialelement" >
                    <table>
                        <tr>
                            <td v-text="'每點價格'"/>
                            <td>
                                <Input 
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="height:25px; justify-content:flex-end; align-items:flex-end; align-content:flex-end; margin:10px 0px;">
                                <span style="text-align:left; float:left; width:100%; background-color: #fff3cd; border-radius:4px; color:#856404; padding:3px;">
                                    有該商品全部單，請勿設定每點價格
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td v-text="'每口保證金'"/>
                            <td>
                                <Input 
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                        </tr>
                        <tr>
                            <td v-text="'每筆最小口數'"/>
                            <td>
                                <Input 
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                        </tr>
                        <tr>
                            <td v-text="'持倉上限'"/>
                            <td>
                                <Input 
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                        </tr>
                        <tr>
                            <td v-text="'開盤風控'"/>
                            <td>
                                <Input 
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="height:25px; justify-content:flex-end; align-items:flex-end; align-content:flex-end; margin:10px 0px;">
                                <span style="text-align:left; float:left; width:100%; background-color: #fff3cd; border-radius:4px; color:#856404; padding:3px;">
                                    未設定開盤風控，則不能留倉
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td v-text="'留倉天數'"/>
                            <td>
                                <Input 
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                        </tr>
                        <tr>
                            <td v-text="'留倉上限'"/>
                            <td>
                                <Input 
                                    style=" padding:0px 5px; width:100%; background-color:#e9ecef; align-items:center; font-size:14px; border-radius:4px; border:1px solid #ced4da;"
                                    v-text="select_row_data['mAccountName']"/>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="detialelement">
                    <table>
                        <tr>
                            <td>
                                <div style="white-space: nowrap;" v-text="'新增的會員狀態'"/>
                                <div v-text="'(預設值)'"/>
                            </td>
                            <td>
                                <DropDownList
                                    :style="{ width: '100%' , height:'23px'}" 
                                    :data-items='status_sports'/>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div  v-text="'群組狀態'"/>
                                <div style="white-space: nowrap;" v-text="'(包含下層)'"/>
                            </td>
                            <td>
                                <DropDownList
                                    :style="{ width: '100%' , height:'23px'}" 
                                    :data-items='status_sports'/>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="height:25px; justify-content:flex-end; align-items:flex-end; align-content:flex-end; margin:10px 0px;">
                                <span style=" white-space: normal; text-align:left; float:left; width:100%; background-color: #fff3cd; border-radius:4px; color:#856404; padding:3px;">
                                    此設定包含下層群組會員，該商品狀態馬上生效
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" >
                                <Checkbox
                                    v-for="(permissiom, index) in other_permission"
                                    :key="index"
                                    :disable="permissiom.disable"
                                    v-model="permissiom.value"
                                    :label="permissiom.label">
                                </Checkbox>
                            </td>
                        </tr>
                        <tr>
                            <td v-text="'禁新時間'"/>
                            <td>
                                <TextArea 
                                    style="margin:3px; margin-left:7px" 
                                    :size="'large'" 
                                    :style="{ width: '100%' }" />
                            </td>
                        </tr>
                        <tr>
                            <td/>
                            <td>
                                <Button :rounded="'medium'" :size="'small'" v-text="'禁新時間設定'"></Button>
                            </td>
                        </tr>
                        <tr>
                            <td/>
                            <td>
                                <Button :rounded="'medium'" :size="'small'" v-text="'下層沿用此設定'"></Button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

    </body>
</template>

<script>

import { Button, ButtonGroup } from '@progress/kendo-vue-buttons';
import { Checkbox, Input, RadioGroup, TextArea } from '@progress/kendo-vue-inputs';
import { DropDownList } from '@progress/kendo-vue-dropdowns';
export default {
    name: 'Agent_Setting_Dialog', 
      components: {
        Checkbox,
        DropDownList,
        Button,
        ButtonGroup,
        TextArea
    },   
    props: {
        select_row_data:{},
    },
    data: function () {
        return {
            selectcommodity:'',
            commodity_list_data:[
                {
                    name:'台指期'
                },
                {
                    name:'台指全'
                },
                {
                    name:'電子期'
                },
                {
                    name:'A50指'
                },
                {
                    name:'德國DAX'
                },
                {
                    name:'BTCUSD'
                },
            ],
            status_sports:["正常","禁新","凍結","停用"],
            limts_sports:["市價報價","未平倉單"],
            other_permission :[
                {
                    label:'倒限單',
                    value:false,
                    name:'mPlace_Order',
                    disabled:false,
                },
                {
                    label:'限價單',
                    value:false,
                    name:'mPlace_Order',
                    disabled:false,
                },
                {
                    label:'下單不確認',
                    value:false,
                    name:'mPlace_Order',
                    disabled:false,
                },
            ],
            mextreme_limit_sports: [ '今日損益', '餘額' ],
            msmallest_lot_sports: [ '開放0.1', '禁止0.1' ],
            mwithdraw_balance_sports: [ '約定額度制', '用戶提交制' ],
            agent_permission : [
                {
                    label:'下單',
                    value:false,
                    name:'mPlace_Order',
                    disabled:false,
                },
                {
                    label:'刪單',
                    value:false,
                    name:'mDelete_Order',
                    disabled:false,
                },
                {
                    label:'改價',
                    value:false,
                    name:'mChange_Order',
                    disabled:false,
                },
                {
                    label:'盤後單',
                    value:false,
                    name:'mAfter_Hours_Order',
                    disabled:false,
                },

                {
                    label:'撮合自設',
                    value:false,
                    name:'mMatching',
                    disabled:false,
                },
                {
                    label:'開盤風控自設',
                    value:false,
                    name:'mOpening_Risk',
                    disabled:false,
                },
                {
                    label:'下單匣C',
                    value:false,
                    name:'mOrder_Box_C',
                    disabled:false,
                },
                {
                    label:'閃電下單匣',
                    value:false,
                    name:'mLightning_Single_Box',
                    disabled:false,
                },
                {
                    label:'限損利',
                    value:false,
                    name:'mLimit_Profit',
                    disabled:false,
                },
                {
                    label:'市損利',
                    value:false,
                    name:'mMarket_Loss_And_Profit',
                    disabled:false,
                },



                {
                    label:'編輯會員(子帳號檢視)',
                    value:false,
                    name:'mEdit_Member',
                    disabled:false,
                },
                {
                    label:'編輯代理',
                    value:false,
                    name:'mEditorial_Agent',
                    disabled:false,
                },
                {
                    label:'同主帳號(功能開啟)',
                    value:false,
                    name:'mMaster_Functionality',
                    disabled:false,
                },
                {
                    label:'新增會員',
                    value:false,
                    name:'mAdd_Member',
                    disabled:false,
                },
                {
                    label:'新增代理',
                    value:false,
                    name:'mAdd_Agent',
                    disabled:false,
                },
                {
                    label:'新增子帳號',
                    value:false,
                    name:'mAdd_Subaccount',
                    disabled:false,
                },
                {
                    label:'編輯費用',
                    value:false,
                    name:'mEditing_Cost',
                    disabled:false,
                },
                {
                    label:'編輯手續費',
                    value:false,
                    name:'mEditing_Tip',
                    disabled:false,
                },
                {
                    label:'顯示名稱電話',
                    value:false,
                    name:'mDisplay_Name_Phone',
                    disabled:false,
                },
                {
                    label:'可設定子帳號(同)',
                    value:false,
                    name:'mSubaccount_Functionality',
                    disabled:false,
                },
            ],
        }
    },
    mounted(){
        const Permissions = Object.keys(this.$store.getters.Select_Agent_Permissions);
        var asd = Permissions.forEach((element, Index) => {
            if( this.agent_permission[Index].name == element)
            {
                if(this.objectsss[element] == '啟用')
                {
                    this.agent_permission[Index].value = true;
                } 
                if(this.objectsss[element] == '停用')
                {
                    this.agent_permission[Index].value = false;
                }
                if(this.objectsss[element] == '不使用')
                {
                    return false;
                }
                    
            }
        });
    },
    methods: {
        Select_Commodity(name){
            this.selectcommodity = this.commodity_list_data.find((c) => c.name === name);
        },
    },
};
</script>
<style scoped>
body{
    
    height:105.272%; 
    margin : -16px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color:#333;
}
.topbar{
    width: 100%;
    height: 32px;
    padding-left: 20px;
    display : inline-flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    background-color: #f5f5f5;
    border-width : 1px;
    border-style : solid;
    border-color : #ddd;
    border-radius: 5px;
}
.topelement{
    margin:0px 10px;
    display : inline-flex;
    align-items: center;
    justify-content: flex-start;
}
.titleelement{
    margin:0px 10px;
    padding:0px 5px;
    color:#721c24;
    background-color:#f8d7da;
    border-radius: 5px;
    font-weight: bold;
}
.detial{
    width: 100%;
    flex-grow:1;
    font-size: 14px;
    height: 100px;
    display : inline-flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    white-space: nowrap;
}
.detialelement{
    padding: 8px 8px 0px 8px;
    height: 100%;
    border-width : 1px;
    border-style : solid;
    border-color : #ddd;
    border-radius: 5px;
    display : inline-flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex:1;
    flex-direction: column;
    overflow: auto;
}
.maintable{
    width: 100%;
    display : inline-flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex:1;
    flex-direction: column;


}
.list{
    display : inline-flex;
    align-items: flex-start;
    height: 80px;
}
table{
    max-width: 100%;
}
table tr td{
    padding: 3px;
    white-space: nowrap;
}
.buttongroup{
    flex:1
}
Button{
    height:100%;
    width:auto;
    color: #fff;
    background-color: #17a2b8;
    padding: 2px 5px;
    margin: 3px;
}
</style>
