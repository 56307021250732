<template>
    <div>
        <div>
          <H4 style="color:purple;">新增代理</H4>
        </div> 
        <form  @submit.prevent="onSubmitAdd">
            <!-- <form> -->
            <div class="form-group">
            <div margin-left="auto" margin-right="auto">   
                <div class="input-group" style="padding-left: 10px;padding-right: 10px;width: 100%;">
                    <div class="input-group-prepend" style="margin-left: 0px;">
                        <span class="input-group-text" style="width: 150px">
                            帳號<br>
                        </span>
                    </div>
                    <input v-model="addAgentInfo.name" class="form-control" type="text" style="margin-right: 0px;padding-left: 10px;" required />
                    <div class="input-group-append"></div>
                </div>          
                <div class="input-group" style="padding-left: 10px;padding-right: 10px;width: 100%;">
                    <div class="input-group-prepend" style="margin-left: 0px;">
                        <span class="input-group-text" style="width: 150px;">
                            密碼<br>
                        </span>
                    </div>
                    <input v-model="addAgentInfo.password" class="form-control" type="text" style="margin-right: 0px;padding-left: 10px;" required>
                    <div class="input-group-append"></div>
                </div>    
            </div>

            <div class="d-xl-flex justify-content-xl-center align-items-xl-center" style="text-align: center;height: 0px;padding-top: 30px;">
                <button type="submit" class="btn btn-primary" style="margin: 5px;" id="send">儲存變更</button>
                <button  type="button" class="btn btn-secondary" style="margin: 5px;" @click="$bvModal.hide('bv-modal-add');cancel();">關閉</button>
            </div>
            
            </div>
        </form>
    </div>
</template>

<!-- <style scoped src="../../assets/register/bootstrap/css/register.bootstrap.min.css"></style>
<style scoped src="../../assets/register/css/register.css"></style>
<script src="../../assets/register/js/register.js"></script> -->
<script>
import axios from 'axios';
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import $ from 'jquery';
import '@progress/kendo-ui'
import '@progress/kendo-theme-default/dist/all.css'
import '@progress/kendo-ui/js/messages/kendo.messages.zh-TW.js'
import { filterBy, orderBy } from '@progress/kendo-data-query';

Vue.use(VueToast);
export default {
    name:"addAgent",
    components: {
    },
    mounted() {   
        this.refreshGrid();
        // this.gridWidth = this.$refs.divWrapper.offsetWidth; 
    },    
    data: function () {
        return {
            addAgentInfo:{
                guid:'',
                name:'',
                password:'',
                nickName:'',

            },
        };
    },
    computed: {

    },
    methods: {
        onSubmitAdd: async function () 
        {
            axios.post(this.$httpPath+'/AgentManager/firstAgent',this.addAgentInfo)
                .then((response) => {
                    if(response.status==200)
                    {
                        Vue.$toast.open({
                        message: '新增完成。',
                        position: 'bottom-right',
                        type: 'success'
                        });                      
                    }   
                    else if(response.status==215)
                    {
                        Vue.$toast.open({
                        message: '帳號已被使用。',
                        position: 'top',
                        type: 'error'
                        });   
                    }
                    else
                    {
                        Vue.$toast.open({
                        message: '新增錯誤。' + response,
                        position: 'top',
                        type: 'error'
                        });   
                    }
                })
                .catch((error) => {
                        Vue.$toast.open({
                        message: '新增錯誤。001',
                        position: 'top',
                        type: 'error'
                        });   
                }).finally(() => {
            }); 
        },
    },
};
</script>