<template>
    <body id="Other_Operate" class="body">
        <div class="other_operate_center">
            <TabStrip 
                id="TabStrip" 
                class="tabstrip" 
                :selected="tabstrip_selected" 
                @select="TabStrip_OnSelect"
                >
                <TabStripTab 
                    id="TabStripTab" 
                    class="tabstriptab" 
                    :title="tabstrip_selected_title[0]" 
                    >
                    <Grid  
                        ref="grid"
                        :style="{height:gridhight +'px', width:gridwidth +'px'}"
                        :data-items="example_data"
                        :column-virtualization="true"
                        :reorderable="true"
                        :columns = "internal_announcement_columns"
                        >
                        <GridToolbar class="gridtoolbar">
                            <Button size="small" v-text="'新增公告'" @click="Add_Announcement"></Button>
                        </GridToolbar>
                    </Grid>
                </TabStripTab>
                <TabStripTab 
                    id="TabStripTab" 
                    class="tabstriptab" 
                    :title="tabstrip_selected_title[1]" 
                    >
                        <div class="illustrate_center">
                            <Button size="large" v-text="'規則說明'" @click="Rules_Description"></Button>
                        </div>
                        <div class="illustrate_center">
                            <Button size="large" v-text="'名詞說明'" @click="Noun_Description"></Button>
                        </div>
                        <div class="illustrate_center">
                            <Button size="large" v-text="'商品交易時間'" @click="Commodity_Trading_Hours"></Button>
                        </div>
                </TabStripTab>
            </TabStrip>
        </div>
    </body>
</template>
<script>
import { TabStrip, TabStripTab} from '@progress/kendo-vue-layout';
import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
import { Button, ButtonGroup } from '@progress/kendo-vue-buttons';
export default {
    name: 'Other_Operate', 
      components: {
        TabStrip,
        TabStripTab,
        Grid,
        GridToolbar,
        Button,
    },
    mounted(){
        const myObserver = new ResizeObserver(entries => {
            this.gridhight=document.getElementById('TabStrip').getElementsByTagName('div')[1].clientHeight-40;
            this.gridwidth=document.getElementById('TabStrip').getElementsByTagName('div')[1].clientWidth-34;
            //console.log(this.gridhight)
            //console.log(this.gridwidth)
        });
        const TabStrip = document.getElementById('TabStrip');
        myObserver.observe(TabStrip, {
            box: "content-box",
        });
    }, 
    data: function () {
        return {
            tabstrip_selected: 0,
            tabstrip_selected_title:["內部公告","說明"],


            gridhight:"",
            gridwidth:"",

            internal_announcement_columns:[
                { field: 'a', title: '設定時間', width: '100%'},
                { field: 'b', title: '公告內容', width: 'auto'},
            ],
            example_data:[{
                "a":"範例",
                "b":"範例",
                "c":"範例",
                "d":"範例",
                "e":"範例",
                "f":"範例",
                "g":"範例",
                "h":"範例",
                "i":"範例",
                "j":"範例",
                "k":"範例",
                "l":"範例",
            },
            ],
        }
    },
    methods: {
        TabStrip_OnSelect(e){
            console.log(e)
            this.tabstrip_selected = e.selected;
        },
        Rules_Description(e){
            /*Do some things*/
        },
        Noun_Description(e){
            /*Do some things*/
        },
        Commodity_Trading_Hours(e){
            /*Do some things*/
        },
        Add_Announcement(e){
            /*Do some things*/
        },
    }
};
</script>
<style scoped>
.body{
    height:100%; 
    margin: 0px;
    display: inline-flex;
    flex-direction: column;
}
.other_operate_center{
    padding: 10px;
    flex:1;
}
.gridtoolbar{
    background-color: rgb(12, 77, 112);
    padding: 3px 5px;
}
.tabstrip{
    height: 100%;
    width: 100%;
    padding: 10px 10px 10px 10px;
    background-color: #fff;
    color: #fff;
}
.tabstriptab{
    height: 100%;
    width: 100%;
    display: inline-flex;
    flex-direction: column ;
    flex-wrap:wrap;
}
.illustrate_center{
    margin:10px 10px 10px 0px
}
Button{
    height:100%;
    width:auto;
    color: #fff;
    background-color: #17a2b8;
    padding: 2px 5px;
}
</style>
