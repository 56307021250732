<template>
    <body id = 'Setting_Password_Dialog'>
        <div style="display:flex;height:35px; width:100%; margin:5px 0px; align-items:center; justify-content:space-between; ">
            <span v-text="'帳號'"/>
            <div
                style="display:flex; padding:0px 10px; background-color:#e9ecef; align-items:center; border-radius:4px; border:1px solid #ced4da;"
                :style="{ width: '245px', height:'33px'}"
                v-text="select_row_data['mAccountName']"/>
        </div>
        <div style="display:flex;height:35px; width:100%; margin:5px 0px;align-items:center; justify-content:space-between;">
            <span v-text="'新密碼'"/>
            <Input style="border:1px solid #ced4da;" :style="{ width: '245px', height:'33px'}"></Input>
        </div>
        <div style="display:flex;height:35px; width:100%; margin:5px 0px;align-items:center; justify-content:space-between;">
            <span v-text="'確認密碼'"/>
            <Input style="border:1px solid #ced4da;" :style="{ width: '245px', height:'33px'}"></Input>
        </div>
        <div>
            <span style="background-color: #fff3cd; border-radius:4px; color:#856404; padding:3px">
                密碼由6~20個英文字母(區分大小寫)或數字組成
            </span>
        </div>
    </body>
</template>
<script>
import { Checkbox, Input, RadioGroup, TextArea } from '@progress/kendo-vue-inputs';

export default {
    name: 'Setting_Password_Dialog', 
      components: {
        Input,
    }, 
    props: {
        select_row_data:{},
    },
    data: function () {
        return {
            
        }
    },
    mounted(){
        
    },
    methods: {
        
    },
};
</script>
<style scoped>
body{
    height:100%; 
    margin : -16px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    padding:16px
}
.gridtoolbar{
    height: 80px;
    width:100%;
    padding: 0px 0px;
    border: 0px;
    background-color: #0c4d70;
    color: #fff;
    display: inline-flex;
    
}
.bar{
    flex :1;
    width:100%;
    display: inline-flex;
    flex-direction: column;
}
.center{
    flex :1;
    width:100%;
    padding: 0px 0px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
}
.DatePicker
{
    margin:3px; 
    font-size:12px; 
    width:120px;
}
.DatePicker > span
{
    margin:0px; 
    
}
Button{
    height:100%;
    width:auto;
    color: #fff;
    background-color: #17a2b8;
    padding: 2px 5px;
    margin: 3px;
}
</style>
