<template>
    <body id = 'Add_Subaccount_Dialog'>
        <div style="display:flex;height:35px; width:100%; margin:5px 0px;align-items:center; justify-content:space-between;">
            <div style="justify-content:space-start;" v-text="'子帳號'"></div>
            <div style="display:flex; justify-content:space-end;">
                <div
                    style="display:flex; padding:0px 10px; background-color:#e9ecef; align-items:center; font-weight:bold; font-size:16px; border-radius:4px 0px 0px 4px; color:blue; border:1px solid #ced4da;"
                    :style="{ width: '75px', height:'33px'}"
                    v-text="'T'"/>
                <Input 
                    style="width:150px; height:33px;font-size:16px; border-radius:0px 4px 4px 0px; border:1px solid #ced4da; margin:0px 5px 0px 0px"
                    v-model="add_subaccount.name"
                    :placeholder="'請輸入數字或英文'"/>
                <Button style="width:76px;height:30px;font-size: 16px;" :rounded="'medium'" v-text="'檢查帳號'"></Button>
            </div>
        </div>
        <div style="display:flex;height:35px; width:100%; margin:5px 0px;align-items:center; justify-content:space-between;">
            <div style="justify-content:space-start;" v-text="'密碼'"></div>
            <div style="justify-content:space-end;">
                <Input 
                    style="width:225px; height:33px; align-content:flex-end; border:1px solid #ced4da; margin:0px 5px"
                    v-model="add_subaccount.password"/>
                <Button style="width:76px;height:30px;font-size: 16px;" :rounded="'medium'" v-text="'推薦帳號'"></Button>
            </div>
        </div>
    </body>
</template>
<script>
import { Checkbox, Input, RadioGroup, TextArea } from '@progress/kendo-vue-inputs';
import { Button, ButtonGroup } from '@progress/kendo-vue-buttons';
export default {
    name: 'Add_Subaccount_Dialog', 
      components: {
        Button,
        Input,

    },  
    props: {
        select_row_data:{},
    }, 
    watch:{
        add_subaccount(){
            this.$emit('add_subaccount', this.add_subaccount)
        },
    },
    data: function () {
        return {
            add_subaccount:{
                mCurrent_AccountName:'',
                mLower_AccountName:'',
                mLower_AccountPassword:'',
                mLower_AccountNickname:'',
                mSubaccount:1,
            },
            
        }
    },
    mounted(){
        
    },
    methods: {
       
    },
};
</script>
<style scoped>
body{
    height:100%; 
    margin : -16px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    padding:15px
}
.gridtoolbar{
    height: 80px;
    width:100%;
    padding: 0px 0px;
    border: 0px;
    background-color: #0c4d70;
    color: #fff;
    display: inline-flex;
    
}
.bar{
    flex :1;
    width:100%;
    display: inline-flex;
    flex-direction: column;
}
.center{
    flex :1;
    width:100%;
    padding: 0px 0px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
}
.DatePicker
{
    margin:3px; 
    font-size:12px; 
    width:120px;
}
.DatePicker > span
{
    margin:0px; 
    
}
Button{
    height:100%;
    width:auto;
    color: #fff;
    background-color: #17a2b8;
    padding: 2px 5px;
    margin: 3px;
}
</style>
